import RoleGuard from 'commons/guards/roleGuard';
import Icons from 'components/icons/Icons';
import {DataNavbar} from 'models/navbar';
import {Link, useLocation} from 'react-router-dom';
import {theme} from 'theme/theme.provider';

interface Props {
  iconsRouteAdmin: DataNavbar[];
}

const Navbar = ({iconsRouteAdmin}: Props) => {
  const {pathname} = useLocation();

  const IconText = ({data, index}: {data: DataNavbar; index: number}) => {
    const isSelected = pathname === `/private/${data.route}`;
    const classText = `text-center font-monseratt font-normal text-xs ${
      isSelected && 'text-primary'
    }`;
    const iconColor = isSelected ? theme.palette.primary.main : '';

    return (
      <div key={index} className="flex flex-col justify-center items-center">
        <Link
          to={data.route}
          data-testid={index}
          className="flex flex-col space-y-1 items-center hover:opacity-75"
        >
          <Icons
            active={isSelected}
            nameIcon={data.Icon1}
            size={7}
            color={iconColor}
          />
          <p className={classText}>{data.label}</p>
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="NavBar flex z-50 shadow-3-4 h-screen w-24 px-2 py-3 bg-white drop-shadow-md">
        <div className="mt-32">
          <div className="w-full space-y-6">
            {iconsRouteAdmin.map((data, index) =>
              data?.roles ? (
                <RoleGuard key={index} {...data.roles}>
                  <IconText data={data} index={index} />
                </RoleGuard>
              ) : (
                <IconText key={index} data={data} index={index} />
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
