import {FC} from 'react';
import {IconButton, Typography} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useNavigate} from 'react-router-dom';
import './ViewTitle.scss';

interface ViewTitleProps {
  disableBackButton?: boolean;
}

const ViewTitle: FC<ViewTitleProps> = ({children, disableBackButton}) => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <Typography className="ViewTitle" variant="h4">
      {!disableBackButton && (
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
      )}
      {children}
    </Typography>
  );
};

export default ViewTitle;
