import {useMutation} from '@apollo/client';
import {ATTACH_EVIDENCE} from 'graphql/absences/Mutations';
import {fileToBase64} from 'commons/files';
import {GraphQLError} from 'graphql';

export interface FileWrapper {
  file: File;
  evidenceType: string;
}

type success = (requestId: string) => void;
type ErrorHandler = (error: Error | GraphQLError) => void;

const useAttachFile = () => {
  const [mutate] = useMutation(ATTACH_EVIDENCE);
  const uploadFiles = (
    files: FileWrapper[],
    leaveRequestId: string,
    success: success,
    errorHandler?: ErrorHandler
  ) => {
    return new Promise((resolve, reject) => {
      const item = files.pop();
      if (!item) {
        success(leaveRequestId);
        resolve(leaveRequestId);
      } else {
        fileToBase64(item.file, true).then(content => {
          const data = {
            leaveRequestId,
            evidenceType: item.evidenceType,
            imageValue: content,
            filename: item.file.name,
          };
          mutate({variables: data})
            .then(() => {
              uploadFiles(files, leaveRequestId, resolve, reject);
            })
            .catch(error => {
              errorHandler && errorHandler(error);
              reject(error);
            });
        });
      }
    });
  };

  return {uploadFiles};
};

export default useAttachFile;
