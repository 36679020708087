import {FC, ReactNode, useContext, useEffect} from 'react';
import {alertContext} from '../contextApi/context/error';
import {Alert} from '@mui/material';

interface AlertContainerProps {
  children: ReactNode;
}

const short = 3000;
const long = 6000;
export const AlertContainer: FC<AlertContainerProps> = ({children}) => {
  const {
    state: {config, showing},
    dispatch,
  } = useContext(alertContext);

  useEffect(() => {
    if (config?.duration && !config.cancelable) {
      let duration;
      if (config.duration === 'short') {
        duration = short;
      } else if (config?.duration === 'long') {
        duration = long;
      } else {
        duration = config.duration;
      }
      setTimeout(() => {
        config?.callback && config.callback();
        dispatch({type: 'dismiss', payload: null});
      }, duration);
    }
  }, [config]);
  return (
    <>
      {showing && (
        <Alert className="absolute w-4/5" severity={config?.level || undefined}>
          {config?.message}
        </Alert>
      )}
      {children}
    </>
  );
};
