import {FC} from 'react';
import {
  DocumentNode,
  FetchResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import FormController from 'controllers/FormController';
import {FormControllerProps} from 'controllers/FormController/FormController';

export type GraphqlMutation = DocumentNode;

export interface GqlFormControllerProps
  extends Omit<FormControllerProps, 'onSubmit'> {
  mutation: GraphqlMutation;
  variablesMapper: (
    values: GQLValue,
    args?: Map<string, GQLValue>
  ) => OperationVariables;
  onSubmit?: (
    mutation: Promise<FetchResult<GQLValue>>,
    variables?: Map<string, GQLValue>
  ) => void;
}

const GqlFormController: FC<GqlFormControllerProps> = ({
  mutation,
  variablesMapper,
  onSubmit,
  ...other
}) => {
  const [mutateFunction, {loading}] = useMutation(mutation);
  const handleOnSubmit = (formData: GQLValue, args: GQLValue) => {
    const variables = (variablesMapper
      ? variablesMapper(formData, args as unknown as Map<string, GQLValue>)
      : formData) as unknown as OperationVariables;
    const mutation = mutateFunction({
      variables,
    });
    const data = {
      formData,
      variables,
      args,
    } as unknown as Map<string, GQLValue>;
    onSubmit && onSubmit(mutation, data);
  };

  return (
    <div className="w-full flex flex-col flex-wrap pt-4">
      <FormController
        onSubmit={handleOnSubmit}
        isLoading={loading}
        {...other}
      />
    </div>
  );
};
export default GqlFormController;
