import {ChangeEvent, useEffect, useState} from 'react';
import TabView from 'components/tabs/TabsTable';
import {useLazyQuery} from '@apollo/client';
import {GET_LISTWINNERS, GET_CATEGORIES, GET_QS} from 'graphql/admin/Querys';
import Loading from 'components/loader/Loader';
import TableRH from 'components/table/TableRh';
import {GET_CURRENT_DINAMYCS, GET_EXPIRATION} from 'graphql/palowan/Querys';
import {ExpiratioI} from 'models/login';
interface Q {
  year: string;
  qs: [
    {
      id: string;
      q: string;
    }
  ];
}

interface Qs {
  quarters: [Q];
}

const Winners = () => {
  const [exeCategories, {data: dataCategory}] = useLazyQuery(GET_CATEGORIES);
  const [exeListWinners, {data: dataWinner}] = useLazyQuery(GET_LISTWINNERS);
  const [exeListQs] = useLazyQuery(GET_QS);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [execCurrentDinamycs, {data}] = useLazyQuery(GET_CURRENT_DINAMYCS);
  const [execGetExpiration] = useLazyQuery(GET_EXPIRATION);
  const [expirationDay, setexpirationDay] = useState<ExpiratioI>({
    finalDate: '',
    number: '',
  });

  const [selectedQuarterId, setSelectedQuarterId] = useState(0);
  const [numQs, setNumQs] = useState([{id: '', q: ''}]);

  const [categoryId, setCategoryId] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [anioFilter, setanioFilter] = useState<Qs>();
  const [year, setyear] = useState('');

  const hanldleAño = (e: ChangeEvent<HTMLSelectElement>) => {
    const q = anioFilter?.quarters.filter(
      item => item.year === e.target.value
    )[0];
    const year = q!.year!;
    const qs = q!.qs!;
    setyear(year);
    // eslint-disable-next-line
    setNumQs(qs.slice().sort((a: any, b: any) => a.q - b.q));
    setexpirationDay({
      finalDate: '',
      number: q!.qs[0].id,
    });
    setSelectedQuarterId(+q!.qs[0].id);
  };
  const hanldleQ = (e: ChangeEvent<HTMLSelectElement>) => {
    setexpirationDay({
      finalDate: '',
      number: e.target.value,
    });
    setSelectedQuarterId(+e.target.value);
  };

  useEffect(() => {
    setyear(new Date().getFullYear().toString());
    const myemail = JSON.parse(localStorage.getItem('email')!)?.data;
    exeCategories();
    exeListQs().then(response => {
      setanioFilter(response.data.QsList);
      const q = response.data.QsList.quarters.filter(
        (item: Q) => item.year === new Date().getFullYear().toString()
      )[0];
      const qs = q!.qs!;
      // eslint-disable-next-line
      setNumQs(qs.slice().sort((a: any, b: any) => a.q - b.q));
    });
    execCurrentDinamycs({
      variables: {limit: 100, page: 1, email: myemail},
    }).then(data => {
      setexpirationDay({
        finalDate: '',
        number: data.data?.DinamycPalowanCurrent.activities[0].quarterId,
      });
      setSelectedQuarterId(
        +data.data?.DinamycPalowanCurrent.activities[0].quarterId
      );
      execGetExpiration({
        variables: {
          year: new Date().getFullYear(),
          FilterId: parseInt(
            data.data?.DinamycPalowanCurrent.activities[0].quarterId
          ),
        },
      });
    });
  }, [dataCategory]);
  useEffect(() => {
    exeListWinners({
      variables: {
        quarterId: selectedQuarterId,
        categoryId: categoryId,
        limit: 10,
        page: pageNumber,
      },
    });
  }, [selectedQuarterId, categoryId, pageNumber]);
  const viewComponent = (id: number) => {
    setCategoryId(dataCategory?.FindCategoriesAward?.categoriesAward[id].id);
  };
  const handlePaginations = (page: string) => {
    setPageNumber(+page);
  };

  return (
    <>
      <div
        className={
          'w-full flex md:justify-center flex-wrap md:z-100 pt-14 md:pt-60'
        }
      >
        <div>
          <p
            className={
              'm-0 text-4xl font-monseratt font-normal text-black md:date mt-4 md:pt-0 ml-4'
            }
          >
            Ganadorxs
          </p>
        </div>
        <div className="w-full md:w-1/2 pt-4 flex justify-end items-center pr-5 md:date">
          {numQs && (
            <select
              name="content"
              className="block w-auto cursor-pointer py-2.5 px-0 text-lg text-black font-monseratt font-normal bg-transparent border-0 focus:outline-none focus:ring-0 focus:border-gray-200 "
              id="selContent"
              data-testid="select-q"
              value={expirationDay?.number}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => hanldleQ(e)}
            >
              {numQs.map((item, index) => (
                <option key={index} value={item.id}>
                  Q {item.q}
                </option>
              ))}
            </select>
          )}
          {anioFilter && (
            <select
              name="content"
              className="block w-auto cursor-pointer py-2.5 px-0 text-lg text-black font-monseratt font-normal bg-transparent border-0 focus:outline-none focus:ring-0 focus:border-gray-200 "
              value={year}
              id="selContent"
              data-testid="select-year"
              onChange={(e: ChangeEvent<HTMLSelectElement>) => hanldleAño(e)}
            >
              {anioFilter.quarters.map((item, index) => (
                <option key={index} value={item.year}>
                  {item.year}
                </option>
              ))}
            </select>
          )}
        </div>
      </div>
      <>
        <div className="md:pt-3 w-10/12 md:w-full mx-auto md:-mt-32">
          <TabView
            tabs={dataCategory?.FindCategoriesAward?.categoriesAward}
            onclickTab={viewComponent}
            data-testid="ViewComponent"
          />
        </div>
        {dataWinner ? (
          <TableRH
            handlePaginations={handlePaginations}
            list={dataWinner.FindListWinners.winners}
            page={dataWinner.FindListWinners.pagination}
            selectedQuarterId={selectedQuarterId}
            categoryId={categoryId}
            pageNumber={pageNumber}
          />
        ) : (
          <Loading loaderStyles="" />
        )}
      </>
    </>
  );
};
export default Winners;
