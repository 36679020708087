import React from 'react';
import './button.css';
import {
  Button as MUIButton,
  ButtonProps as MUIButtonProps,
} from '@mui/material';
export interface ButtonProps {
  /**
   * Button text
   */
  text?: string;
  disabled?: boolean;
  /*
  Button style
  */
  style?:
    | 'primaryOutline'
    | 'primarySolid'
    | 'primaryTransparent'
    | 'primarySolidShawdow'
    | 'yellowSolidShawdow';
  /**
   * Optional click handler
   */
  onClickButton?: () => void;
}
export const Button: React.FC<ButtonProps & MUIButtonProps> = ({
  text,
  style,
  disabled = false,
  onClickButton,
  ...props
}) => {
  return (
    <MUIButton
      disabled={disabled}
      onClick={onClickButton}
      variant={style === 'primaryOutline' ? 'outlined' : 'contained'}
      {...props}
    >
      {text}
    </MUIButton>
  );
};

export default Button;
