import {IMG_DEFAULT} from 'commons/img/imgBase64';
import {maxSize, seeMore} from 'commons/seeMore';
import {DinamicCard} from 'models/dinamics';

interface Props {
  cardDinamyc?: DinamicCard;
  nameButton: string;
  onclickParticipate: () => void;
  onclickSeeMore?: () => void;
}
export const existImg = (img: string) => {
  if (img.length < 0) return IMG_DEFAULT;

  const newImg = changeImgLarge(img);

  return newImg;
};

const changeImgLarge = (img: string): string => {
  const value = img.split('/');
  const nameImg = value[5];
  value.pop();
  switch (nameImg) {
    case 'ambassador.png':
      return `${value.join('/')}/ambassador_large.png`;
    case 'costumer.png':
      return `${value.join('/')}/costumer_large.png`;
    case 'day_to_day.png':
      return `${value.join('/')}/day_to_day_large.png`;
    case 'dinamicas_internas.png':
      return `${value.join('/')}/dinamicas_internas_large.png`;
    case 'events.png':
      return `${value.join('/')}/events_large.png`;
    case 'reclutamiento.png':
      return `${value.join('/')}/reclutamiento_large.png`;
    case 'teamplayer.png':
      return `${value.join('/')}/teamplayer_large.png`;
    default:
      return img;
  }
};
const CardDinamicPalowan = ({
  cardDinamyc,
  nameButton,
  onclickParticipate,
  onclickSeeMore,
}: Props) => {
  const styleDinamic = (dinamica: string) => {
    return dinamica === 'Dinámica' ? 'bg-blue-300' : ' bg-yellow-500';
  };
  const typeDinamic = (data: string): string => {
    switch (data) {
      case '1':
        return 'Reto';
      case '2':
        return 'Dinámica';

      default:
        return 'Dinámica';
    }
  };

  return (
    <>
      <div className=" bg-white w-89 h-98 rounded-md shadow-md ">
        <div className="w-full h-48 flex justify-center relative rounded-lg ">
          <div className=" w-full absolute top-3/4">
            <div
              className={
                styleDinamic(typeDinamic(cardDinamyc!.typeActivity)) +
                ' w-20 h-5 rounded-full ml-2 flex   items-center justify-center mt-1'
              }
            >
              <p className=" text-xs font-medium font-monseratt	px-1">
                {typeDinamic(cardDinamyc!.typeActivity)}
              </p>
            </div>
          </div>

          <img
            className=" w-full max-h-full bg-cover bg-center "
            src={existImg(cardDinamyc!.urlImage!)}
            alt="imgCard"
          />
        </div>
        <div className=" w-full h-56">
          <div className=" w-full py-4 px-4 ">
            <p className="text-base mb-0.5 font-monseratt text-1xl font-black">
              {cardDinamyc?.name}
            </p>
            <p className=" text-base mb-0.5 font-semibold text-gray-500 font-monseratt">
              {/* Secondary text */}
            </p>
          </div>
          <div className=" w-full h-16 px-4  py-1">
            <p
              className="text-base mb-0.5  font-semibold text-gray-500 font-monseratt"
              onClick={onclickParticipate}
            >
              {seeMore(cardDinamyc!.description!, 56)}
              {maxSize(cardDinamyc!.description!, 56) && (
                <strong
                  data-testid="SeeMore"
                  onClick={onclickSeeMore}
                  className=" text-tab-color-green cursor-pointer  hover:opacity-30"
                >
                  ver mas
                </strong>
              )}
            </p>
          </div>
          <div className=" w-full mt-4 ">
            <a
              onClick={onclickParticipate}
              data-testid="Participate"
              className={
                'font-monseratt text-base cursor-pointer border-transparent p-4 text-tab-color-green font-bold'
              }
            >
              {nameButton}
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDinamicPalowan;
