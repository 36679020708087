/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import './StickyHeadTable.scss';

export interface ColumnProps {
  id: string;
  label: string;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
  render?: (value: any, row: any) => any;
}

export interface StickyHeadTableProps {
  columns: ColumnProps[];
  rows: any[] | undefined;
  onPageChange: Function;
  placeholder?: string | JSX.Element;
  isLoading?: boolean;
  totalElements?: number;
  rowProps?: TableRowProps;
  hidePagination?: boolean;
  actions?: JSX.Element | JSX.Element[];
}

const StickyHeadTable: React.FC<StickyHeadTableProps> = props => {
  const initialPageSize = 10;
  const {
    columns,
    placeholder,
    onPageChange,
    isLoading,
    totalElements,
    hidePagination,
    actions,
  } = props;
  const rows = isLoading ? [] : props.rows || [];
  const [pageInfo, setPageInfo] = React.useState({
    page: 0,
    pageSize: initialPageSize,
  });
  // eslint-disable-next-line
  const handleChangePage = (event: any, newPage: any) => {
    setPageInfo({
      ...pageInfo,
      page: newPage,
    });
  };
  // eslint-disable-next-line
  const handleChangeRowsPerPage = (event: any) => {
    setPageInfo({
      page: 0,
      pageSize: event.target.value,
    });
  };

  React.useEffect(() => {
    onPageChange(pageInfo);
    // eslint-disable-next-line
  }, [pageInfo]);

  const getPlaceHolder = () => {
    if (typeof placeholder === 'string') {
      return <Typography variant="body1">{placeholder}</Typography>;
    } else {
      return placeholder;
    }
  };
  return (
    <Paper
      data-testid="StickyHeadTable"
      sx={{width: '100%', overflow: 'hidden'}}
      className="StickyHeadTable"
    >
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: ColumnProps, index: number) => (
                <TableCell key={index} align={column.align}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              // eslint-disable-next-line
              .map((row: any, index: number) => {
                const rowProps = {
                  ...props.rowProps,
                  onClick: () =>
                    props.rowProps?.onClick && props.rowProps?.onClick(row),
                };
                return (
                  <TableRow
                    hover
                    className={`${props.rowProps?.onClick && 'cursor-pointer'}`}
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    {...rowProps}
                  >
                    {
                      // eslint-disable-next-line
                      columns.map((column: any, columnIndex: number) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={columnIndex} align={column.align}>
                            {column.render ? column.render(value, row) : value}
                          </TableCell>
                        );
                      })
                    }
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!!rows.length && !hidePagination && (
        <TablePagination
          rowsPerPageOptions={[initialPageSize, 25, 100]}
          component="div"
          count={totalElements || rows.length}
          rowsPerPage={pageInfo.pageSize}
          page={pageInfo.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {!isLoading && !rows.length && (
        <div className="placeholder">{getPlaceHolder()}</div>
      )}
      {isLoading && (
        <div className="placeholder bg-white">
          <CircularProgress />
        </div>
      )}
      {!!actions && (
        <div className="actions flex justify-end py-2 px-4">{actions}</div>
      )}
    </Paper>
  );
};

export default StickyHeadTable;
