import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import {GET_LEAVE_REQUEST_BY_APPROVAL} from 'graphql/absences/Querys';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_IN_PROCESS,
  LEAVE_STATUS_PENDING,
  LEAVE_STATUS_REJECTED,
  LeaveStatus,
} from 'models/leaveRequest';
import BlankCardWithTabs from 'components/BlankCardWithTabs/BlankCardWithTabs';
import {DocumentNode} from '@apollo/client';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import Status from 'components/Status/Status';
import {LeavesRoutes, PrivateRoutes} from 'navigation/routes';
import RoleGuard from 'commons/guards/roleGuard';

interface PendingApprovalsProps {
  approvalId: string;
  statusLeave?: LeaveStatus[];
  statusApproval?: LeaveStatus[];
  onlyInOrder: boolean;
  sort?: Map<string, string>;
  isAdmin?: boolean;
}

export const PendingApprovalsTable: FC = () => {
  const {id} = useParams<{id: string}>();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const columns: ColumnProps[] = [
    {
      id: 'userName',
      label: t('pendingApprovalsTable.name'),
    },
    {
      id: 'dates',
      label: t('pendingApprovalsTable.dates'),
    },
    {
      id: 'createdAt',
      label: t('pendingApprovalsTable.createdAt'),
    },
    {
      id: 'typeOfLeave',
      label: t('pendingApprovalsTable.typeOfLeave'),
      render: value => {
        return value.description || '';
      },
    },
    {
      id: 'daysOff',
      label: t('pendingApprovalsTable.numberOfDays'),
    },
    {
      id: 'status',
      label: t('pendingApprovalsTable.status'),
      render: value => {
        return <Status label={value} />;
      },
    },
    {
      id: 'assignationType',
      label: t('pendingApprovalsTable.assignation'),
    },
  ];
  // eslint-disable-next-line
  const mapData = (data: any) => ({
    dates: `${data.startDate} - ${data.endDate}`,
    ...data,
  });

  const pendingApprovalsView = (
    document: DocumentNode,
    placeholder: string,
    withArgs: PendingApprovalsProps
  ) => {
    return (
      <GqlTableController
        rowProps={{
          // eslint-disable-next-line
          onClick: (values: any) =>
            navigate(
              `/${PrivateRoutes.PRIVATE}/leaves/${LeavesRoutes.REQUEST_DETAIL}/${values.id}`
            ),
        }}
        columns={columns}
        placeholder={placeholder}
        mapData={mapData}
        queryConfiguration={{
          query: document,
          rootField: 'leaveRequestsByApproval',
          queryVariables: (page, size) => ({
            ...withArgs,
            pageParams: {
              page: page || 1,
              size,
            },
          }),
        }}
      />
    );
  };
  return (
    <div className="PalowansOnBreakTable w-full flex flex-col flex-wrap pt-12">
      <ViewTitle>Solicitudes de palowans</ViewTitle>
      <RoleGuard
        getRole
        render={({isAdmin}) => (
          <BlankCardWithTabs
            className="h-full mt-4"
            tabs={[
              {
                label: 'Solicitudes a aprobar',
                view: pendingApprovalsView(
                  GET_LEAVE_REQUEST_BY_APPROVAL,
                  'No hay solicitudes pendientes',
                  {
                    approvalId: id || '',
                    statusLeave: [
                      LEAVE_STATUS_PENDING,
                      LEAVE_STATUS_IN_PROCESS,
                    ],
                    statusApproval: [LEAVE_STATUS_PENDING],
                    onlyInOrder: true,
                    isAdmin: false,
                  }
                ),
              },
              {
                label: 'Historial de solicitudes',
                view: pendingApprovalsView(
                  GET_LEAVE_REQUEST_BY_APPROVAL,
                  'No hay datos disponibles',
                  {
                    approvalId: id || '',
                    statusApproval: [
                      LEAVE_STATUS_APPROVED,
                      LEAVE_STATUS_REJECTED,
                      LEAVE_STATUS_PENDING,
                    ],
                    statusLeave: [
                      LEAVE_STATUS_APPROVED,
                      LEAVE_STATUS_REJECTED,
                      LEAVE_STATUS_IN_PROCESS,
                      LEAVE_STATUS_PENDING,
                    ],
                    onlyInOrder: false,
                    isAdmin: isAdmin,
                  }
                ),
              },
            ]}
          />
        )}
      />
    </div>
  );
};
