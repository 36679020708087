import {FC} from 'react';
import {Chip, ChipProps} from '@mui/material';
import {OverridableStringUnion} from '@mui/types';
import {useTranslation} from 'react-i18next';
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_CANCELLED,
  LEAVE_STATUS_DELETED,
  LEAVE_STATUS_IN_PROCESS,
  LEAVE_STATUS_REJECTED,
  LEAVE_STATUS_APPROVAL_CANCELLED,
  leaveStatusLabels,
} from 'models/leaveRequest';
import './Status.scss';

interface StatusProps extends ChipProps {
  value?: string;
}

const Status: FC<StatusProps> = ({label, ...other}) => {
  const {t} = useTranslation();
  let color: OverridableStringUnion<
    'error' | 'info' | 'success' | 'warning' | 'default'
  >;

  switch (label) {
    case LEAVE_STATUS_APPROVED:
      color = 'success';
      break;
    case LEAVE_STATUS_CANCELLED:
      color = 'default';
      break;
    case LEAVE_STATUS_APPROVAL_CANCELLED:
      color = 'default';
      break;
    case LEAVE_STATUS_REJECTED:
      color = 'error';
      break;
    case LEAVE_STATUS_IN_PROCESS:
      color = 'info';
      break;
    case LEAVE_STATUS_DELETED:
      color = 'default';
      break;
    default:
      color = 'warning';
  }

  return (
    <Chip
      data-testid="Status"
      className="Status"
      variant="outlined"
      label={t(leaveStatusLabels(String(label)))}
      color={color}
      {...other}
    />
  );
};

export default Status;
