export interface Evidence {
  evidenceType: EvidenceType;
  file?: File;
  url?: string;
}

export enum EvidenceType {
  CUSTOMER_EVIDENCE = 'customer',
  MANAGER_EVIDENCE = 'manager',
  MEDICAL_PRESCRIPTION = 'medical_prescription',
  CUSTOMER_CANCELLATION = 'customer_cancellation',
  MANAGER_CANCELLATION = 'manager_cancellation',
}

export function getEvidenceDescription(evidenceType?: EvidenceType): string {
  switch (evidenceType) {
    case EvidenceType.CUSTOMER_EVIDENCE:
      return 'leaves.customer_evidence';
    case EvidenceType.MANAGER_EVIDENCE:
      return 'leaves.manager_evidence';
    case EvidenceType.MEDICAL_PRESCRIPTION:
      return 'leaves.medical_evidence';
    default:
      return '';
  }
}
