import {Navigate, Route, Routes} from 'react-router-dom';
import {PrivateRoutes, PublicRoutes} from 'navigation/routes';
import Login from 'views/login/login';

const MainStack = () => (
  <Routes>
    <Route path="/" element={<Navigate to={PrivateRoutes.PRIVATE} />} />
    <Route path={PublicRoutes.LOGIN} element={<Login />}></Route>
  </Routes>
);

export default MainStack;
