import {gql} from '@apollo/client';

export const CREATE_DINAMYCS = gql`
  mutation Mutation(
    $description: String!
    $finalDate: String!
    $mimeType: String!
    $nameimg: String!
    $receipt: String!
    $initialDate: String!
    $name: String!
    $palopoints: Float!
    $quarterId: Float!
    $typeActivity: Float!
  ) {
    CreateDinamycs(
      description: $description
      finalDate: $finalDate
      mime_type: $mimeType
      nameimg: $nameimg
      receipt: $receipt
      initialDate: $initialDate
      name: $name
      palopoints: $palopoints
      quarterId: $quarterId
      typeActivity: $typeActivity
    ) {
      message
    }
  }
`;
export const UPDATE_DINAMYCS = gql`
  mutation Mutation(
    $description: String!
    $finalDate: String!
    $mimeType: String!
    $nameimg: String!
    $receipt: String!
    $initialDate: String!
    $name: String!
    $palopoints: Float!
    $quarterId: Float!
    $typeActivity: Float!
    $activityId: Float!
  ) {
    UpdateDinamycs(
      description: $description
      finalDate: $finalDate
      mime_type: $mimeType
      nameimg: $nameimg
      receipt: $receipt
      initialDate: $initialDate
      name: $name
      palopoints: $palopoints
      quarterId: $quarterId
      typeActivity: $typeActivity
      activityID: $activityId
    ) {
      message
    }
  }
`;
export const VALIDATE_EVIDENCE = gql`
  mutation Mutation(
    $approved: Boolean!
    $evidenceId: Float!
    $reason: String!
  ) {
    ValidateEvidence(
      approved: $approved
      evidenceID: $evidenceId
      reason: $reason
    ) {
      message
    }
  }
`;
