import {authContext} from 'contextApi/context/auth';
import {useContext, useState} from 'react';
import {useGraphqlQuery} from './useGraphqlQuery';
import {GET_USER_BY_EMAIL} from 'graphql/absences/Querys';
import {UserDetail} from 'models/user-detail';
import {alertContext} from 'contextApi/context/error';
import {AUTHCONST} from '../commons/auth/authConst';

// eslint-disable-next-line
function isUserDetail(obj: any): obj is UserDetail {
  return obj && obj.email && obj.firstName && obj.id;
}

const useUser = () => {
  const initialSate: UserDetail = {};
  const [isLoading, setLoading] = useState<boolean | null>(null);
  const {
    email,
    userDetails,
    dispatch: dispatchAction,
  } = useContext(authContext);
  const query = useGraphqlQuery(initialSate, GET_USER_BY_EMAIL, 'user');
  const {dispatch} = useContext(alertContext);

  const fetchUser = async (
    _email: string = email?.data || ''
  ): Promise<UserDetail | null> => {
    setLoading(true);
    let data: UserDetail | null = null;
    try {
      if (!_email || _email === '') {
        throw new Error('Email is required');
      }
      const response = await query.fetchData({email: _email});
      if (isUserDetail(response)) {
        data = {...response};
        dispatchAction({type: AUTHCONST.GET_USER, payload: {payload: data}});
      } else {
        throw new Error('Invalid response');
      }
    } catch (error) {
      dispatch({
        type: 'show',
        payload: {
          level: 'error',
          message: 'Error fetching user details',
        },
      });
    }
    setLoading(false);
    return data;
  };

  return {userDetails, fetchUser, isLoading};
};

export default useUser;
