import {createContext, Dispatch, FC, useReducer} from 'react';
import {
  AdminAction,
  adminReducer,
  AdminState,
  CountryConfiguration,
} from '../reducers/admin.reducer';

export interface AdminContext {
  state: AdminState;
  dispatch: Dispatch<AdminAction>;
  fetch: () => Promise<CountryConfiguration>;
  updateConfiguration: () => void;
}

const initialSate: AdminState = {
  configuration: {},
  isLoading: false,
};

export const adminContext = createContext<AdminContext>({
  state: initialSate,
  dispatch: () => {},
  fetch: (): Promise<CountryConfiguration> => {
    return Promise.resolve({});
  },
  updateConfiguration: () => {},
});

const AdminProvider: FC = ({children}) => {
  const [state, dispatch] = useReducer(adminReducer, initialSate);
  const fetch = async (): Promise<CountryConfiguration> => {
    // TODO: replace with server data
    const json = JSON.parse(
      `[
    {"id": 1, "country": "MEX", "publicHolidays": {"2021": [{"date": "2021-01-01", "name": "New Year's Day"}], "2024":[{"date": "2024-01-01", "name": "New Year's Day"}]}, "vacationRules": {"maternity": 12, "paternity": 5, "vacationRanges": [{"vacationDays": 10, "yearThreshold": 1}]}, "vacationExpirationRules": {"value": 1, "expirationType": "year"}},
    {"id": 2, "country": "COL", "publicHolidays": {"2021": [{"date": "2021-01-01", "name": "New Year's Day"}]}, "vacationRules": {"maternity": 12, "paternity": 5, "vacationRanges": [{"vacationDays": 10, "yearThreshold": 1}]}, "vacationExpirationRules": {"value": 1, "expirationType": "year"}}
    ]`
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any[];
    const data: CountryConfiguration = {};
    json.forEach(item => {
      data[item.country] = item;
    });
    return data;
  };
  const updateConfiguration = async () => {
    // TODO: implement me. At this point the state should be updated with the data from the views
  };

  return (
    <adminContext.Provider
      value={{state, dispatch, fetch, updateConfiguration}}
    >
      {children}
    </adminContext.Provider>
  );
};

export default AdminProvider;
