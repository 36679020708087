import RoleGuard from 'commons/guards/roleGuard';
import Layout from 'components/Layout/Layout';
import {Route, Routes} from 'react-router-dom';
import {QuaterProvider} from 'contextApi//context/quaters';
import AuthGuard from 'commons/guards/authGuard';
import ManagementStack from 'navigation/ManagementStack';
import PalowanStack from 'navigation/PalowanStack';
import LeavesStack from 'navigation/LeavesStack';

const Private = () => {
  return (
    <Routes>
      <Route element={<AuthGuard />}>
        <Route
          path="/*"
          element={
            <>
              <Layout>
                <QuaterProvider>
                  {/* Fix this -> <RoutesWithNotFound> */}
                  <>
                    <RoleGuard useRedirect />
                    <Routes>
                      <Route
                        path="management/*"
                        element={<ManagementStack />}
                      />
                      <Route path="palowan/*" element={<PalowanStack />} />
                      <Route path="leaves/*" element={<LeavesStack />} />
                    </Routes>
                  </>
                  {/* Fix this -> </RoutesWithNotFound> */}
                </QuaterProvider>
              </Layout>
            </>
          }
        />
      </Route>
    </Routes>
  );
};

export default Private;
