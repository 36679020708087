/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC} from 'react';
import Modal, {ModalProps} from 'components/Modal/Modal';
import './ImagePreviewModal.scss';

interface ImagePreviewModalProps extends Omit<ModalProps, 'children'> {
  src: string;
  alt: string;
}

const ImagePreviewModal: FC<ImagePreviewModalProps> = ({
  title,
  description,
  onClose,
  src,
  alt,
  ...other
}) => {
  return (
    <Modal
      className="ImagePreviewModal"
      title={title}
      description={description}
      onClose={onClose}
      {...other}
    >
      <img src={src} alt={alt} />
    </Modal>
  );
};

export default ImagePreviewModal;
