import {gql} from '@apollo/client';

export const CREATE_EVIDENCE = gql`
  mutation Mutation(
    $email: String!
    $idActivity: Float!
    $mimeType: String!
    $name: String!
    $receipt: String!
  ) {
    ParticipateEvidence(
      email: $email
      idActivity: $idActivity
      mime_type: $mimeType
      name: $name
      receipt: $receipt
    ) {
      message
    }
  }
`;
export const CREATE_COMMENT = gql`
  mutation Mutation($activityId: Float!, $comment: String!, $email: String!) {
    CreateComments(activityID: $activityId, comment: $comment, email: $email) {
      message
    }
  }
`;
export const UPDATE_COMMENT = gql`
  mutation Mutation($commentId: Float!, $comment: String!) {
    UpdateComment(commentID: $commentId, comment: $comment) {
      message
    }
  }
`;
export const UPDATE_STATUS_AWARD = gql`
  mutation Mutation($awardStatusId: Float!, $status: String!) {
    UpdateStatusAward(awardStatusId: $awardStatusId, status: $status) {
      message
    }
  }
`;
