/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext} from 'react';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import {GET_LEAVE_HISTORY} from 'graphql/absences/Querys';
import {useTranslation} from 'react-i18next';
import {authContext} from 'contextApi/context/auth';
import {useNavigate} from 'react-router-dom';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import {LeaveRequest} from 'models/leaveRequest';
import {LeavesRoutes, PrivateRoutes} from 'navigation/routes';
import './MyLeavesTable.scss';
import dayjs from 'dayjs';
import Status from 'components/Status/Status';

const MyLeavesTable: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {userDetails} = useContext(authContext);
  const columns: ColumnProps[] = [
    {
      id: 'dates_of_leave',
      label: t('leaves.LeaveRequestsTable.dates_of_leave'),
      render: (_: string, row) =>
        `${dayjs(row.startDate).format('DD/MM/YYYY')} - ${dayjs(
          row.endDate
        ).format('DD/MM/YYYY')}`,
    },
    {
      id: 'request_date',
      label: t('leaves.LeaveRequestsTable.request_date'),
      render: (value: string) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      id: 'type_of_absence',
      label: t('leaves.LeaveRequestsTable.type_of_absence'),
    },
    {
      id: 'days_of_leave',
      label: t('leaves.LeaveRequestsTable.days_of_leave'),
    },
    {
      id: 'status',
      label: t('leaves.LeaveRequestsTable.status'),
      render: (value: any) => <Status label={value} />,
    },
  ];

  const mapData = (data: LeaveRequest) => {
    return {
      ...data,
      dates_of_leave: `${data.startDate} - ${data.endDate}`,
      request_date: data.createdAt,
      type_of_absence: data.typeOfLeave?.description,
      days_of_leave: data.daysOff,
    };
  };

  return (
    <div className="MyLeavesTable w-full flex flex-col flex-wrap pt-12">
      <ViewTitle>{t('leaves.MyLeavesTable.title')}</ViewTitle>
      <GqlTableController
        columns={columns}
        placeholder={t('common.empty_list')}
        mapData={mapData}
        rowProps={{
          onClick: (values: any) =>
            navigate(
              `/${PrivateRoutes.PRIVATE}/leaves/${LeavesRoutes.REQUEST_DETAIL}/${values.id}`
            ),
        }}
        queryConfiguration={{
          query: GET_LEAVE_HISTORY,
          rootField: 'leaveHistory',
          queryVariables: (page, size) => ({
            userId: userDetails?.data?.id,
            pageParams: {
              page,
              size,
            },
          }),
        }}
      />
    </div>
  );
};

export default MyLeavesTable;
