/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import StickyHeadTable, {
  ColumnProps,
} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import ModalController from 'controllers/ModalController';
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateVacationDayParamModal from 'viewComponents/UpdateVacationDayParamModal/UpdateVacationDayParamModal';
import './HolidaysParamsTable.scss';
import TableController from 'controllers/TableController';
import {adminContext} from 'contextApi/context/admin';
import {PublicHoliday} from 'models/configuration';
import {parseDate} from 'commons/dates';

const HolidaysParamsTable: React.FC = () => {
  const {t} = useTranslation();
  const [publicHolidays, setPublicHolidays] = useState<any>(undefined);
  const {
    state: {configuration, currentCountry},
  } = useContext(adminContext);

  useEffect(() => {
    const countryConfig = configuration[currentCountry || ''];
    if (countryConfig) {
      let list: PublicHoliday[] = [];
      Object.entries(countryConfig.publicHolidays)
        .sort((a, b) => (b > a ? 1 : -1))
        // eslint-disable-next-line
        .forEach((key, v) => {
          list = [...list, ...key[1]];
        });
      setPublicHolidays({data: list});
    }
  }, [currentCountry]);

  const renderEditController = (paramId: string, values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <EditIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <UpdateVacationDayParamModal
          defaultValue={values}
          paramId={paramId}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const columns: ColumnProps[] = [
    {
      id: 'name',
      label: t('leaves.HolidaysParamsTable.name'),
    },
    {
      id: 'date',
      label: t('leaves.HolidaysParamsTable.day'),
    },
    {
      id: 'year',
      label: t('leaves.HolidaysParamsTable.year'),
      render: (value, row) => {
        const date = parseDate(row.date || '');
        return date.getFullYear();
      },
    },
    {
      id: 'id',
      label: t('leaves.HolidaysParamsTable.actions'),
      align: 'right',
      render: (id: string, values: any) => renderEditController(id, values),
    },
  ];

  return (
    <div className="HolidaysParamsTable w-full flex flex-col flex-wrap pt-12">
      {publicHolidays && (
        <TableController
          isLoading={false}
          // eslint-disable-next-line
          dataProvider={(page, pageSize) =>
            Promise.resolve({data: publicHolidays, totalElements: 0})
          }
        >
          {({tableData, onPageChange}) => (
            <StickyHeadTable
              columns={columns}
              placeholder={t('common.empty_list')}
              hidePagination
              isLoading={false}
              rows={tableData?.data}
              onPageChange={onPageChange}
              totalElements={tableData?.totalElements}
            />
          )}
        </TableController>
      )}
    </div>
  );
};

export default HolidaysParamsTable;
