import {Grid, Typography} from '@mui/material';
import CreateLeaveRequestForm from 'viewComponents/CreateLeaveRequestForm';
import {useTranslation} from 'react-i18next';
import LeavesBalance from 'viewComponents/LeavesBalance/LeavesBalance';
import LeaveRequestsTable from 'viewComponents/LeaveRequestsTable/LeaveRequestsTable';
import AditionalLeavesBalance from 'viewComponents/AditionalLeavesBalance/AditionalLeavesBalance';

export const MyLeaves = () => {
  const {t} = useTranslation();
  return (
    <div className="w-full flex flex-col flex-wrap pt-12">
      <Typography variant="h4">{t('leaves.MyLeaves.title')}</Typography>
      <Typography variant="body1">
        {t('leaves.MyLeaves.description')}
      </Typography>
      <Grid container spacing={4} sx={{marginTop: '1rem !important'}}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Left Section */}
            <Grid item xs={7}>
              <CreateLeaveRequestForm />
            </Grid>
            {/* Right Section */}
            <Grid item xs={5}>
              <LeavesBalance />
              <AditionalLeavesBalance className="mt-6" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <LeaveRequestsTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default MyLeaves;
