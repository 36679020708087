/* eslint-disable @typescript-eslint/no-explicit-any */
import {useLazyQuery, useMutation} from '@apollo/client';
import cx from 'classnames';
import {formatGetTime, formatGetTimeCeroDay} from 'commons/formatDate';
import {OptionDinamica, Options} from 'commons/utils';
import Button from 'components/button/Button';
import Loading from 'components/loader/Loader';
import {CREATE_DINAMYCS, UPDATE_DINAMYCS} from 'graphql/admin/Mutation';
import {GET_DINAMYCS, GET_UPDATE_DINAMYC} from 'graphql/admin/Querys';
import {ChangeEvent, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';

export interface FormEditPalowanProps {
  /**
   * title form
   */
  title?: string;
  /*
  sub title form
  */
  subTitle?: string;
  /*
  sub title form
  */
  idDinamyc?: string;
  /**Editar  */
  edit?: boolean;
  max?: string;
  min?: string;

  onClickClose: () => void;
}
export const ModalDinamyc: React.FC<FormEditPalowanProps> = ({
  title,
  subTitle,
  idDinamyc,
  edit,
  max = '',
  min = '',
  onClickClose,
}) => {
  const {QuaterId, QuaterYear} = useParams();
  const numberQuater = parseInt(QuaterId!);
  const numberQuaterYear = parseInt(QuaterYear!);
  const [mimeType, setmimeType] = useState<string>('');
  const [imgView, setimgView] = useState<string>();
  const [nameImg, setnameImg] = useState('');
  const [OpenButton, setOpenButton] = useState(false);
  const [updateDinamycs] = useMutation(UPDATE_DINAMYCS, {
    refetchQueries: [
      {
        query: GET_DINAMYCS,
        variables: {
          year: numberQuaterYear,
          Id: numberQuater,
          numberQuater: numberQuater,
        },
      },
    ],
  });
  const [createDinamycs] = useMutation(CREATE_DINAMYCS, {
    refetchQueries: [
      {
        query: GET_DINAMYCS,
        variables: {
          year: numberQuaterYear,
          Id: numberQuater,
          numberQuater: numberQuater,
        },
      },
    ],
  });
  const [execListData, {data}] = useLazyQuery(GET_UPDATE_DINAMYC);
  const [disabled, setdisabled] = useState<boolean>(false);
  const [loading, setloading] = useState<boolean>(false);

  const contTop = cx({
    'w-full bg-white px-8 py-2 rounded-lg pt-4': true,
  });

  const contTitle = cx({
    'w-full flex items-start justify-between py-4 md:pt-0 md:mb-2 ': true,
  });

  const btnClose = cx({
    'bg-transparent': true,
  });
  useEffect(() => {
    if (idDinamyc) {
      const parseId = parseInt(idDinamyc);
      execListData({
        variables: {id: parseId},
      }).then(data => {
        const dinamycs = data.data.FindDynamicById.activity;
        setimgView(dinamycs.urlImage);
        setValue('NameDinamyc', dinamycs.name);
        setValue('DescriptionDinamyc', dinamycs.description);
        setValue('TypeDinamyc', dinamycs.typeActivity);
        setValue('NumPaloPoints', dinamycs.palopoints);
        setValue('DateIni', newFormatt(dinamycs.initialDate));
        setValue('DateEnd', newFormatt(dinamycs?.finalDate));
        setOpenButton(true);
      });
    }
  }, []);

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    getValues,
  } = useForm();

  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];

    const base64: string = await converBase64(file);
    setmimeType(event.target.files![0].type);
    setOpenButton(true);
    setimgView(base64);
    setnameImg(event.target.files![0].name);
  };

  const converBase64 = (file: any) => {
    return new Promise<string>(resolve => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result!.toString());
      };
    });
  };
  const onclickDeleteUpload = () => {
    setimgView('');
    setnameImg('');
    setOpenButton(false);
  };
  const newFormatt = (data: string): string => {
    return data.split('/').reverse().join('-');
  };
  const formatDate = (date: string) => {
    return date.split('-').reverse().join('/');
  };
  const onSubmit = async (data: any) => {
    setloading(true);
    setdisabled(true);
    const fechafin = formatDate(data.DateEnd);
    const fechaini = formatDate(data.DateIni);
    const parseNumber = Number(data.NumPaloPoints);
    const parseTypeDinamyc = Number(data.TypeDinamyc);
    if (!idDinamyc) {
      createDinamycs({
        variables: {
          description: data.DescriptionDinamyc,
          finalDate: fechafin,
          mimeType: mimeType,
          nameimg: nameImg,
          receipt: imgView,
          initialDate: fechaini,
          name: data.NameDinamyc,
          palopoints: parseNumber,
          quarterId: numberQuater,
          typeActivity: parseTypeDinamyc,
        },
      }).then(() => finishSubmit());
    } else {
      const parseId = parseInt(idDinamyc);
      updateDinamycs({
        variables: {
          description: data.DescriptionDinamyc,
          finalDate: fechafin,
          mimeType: imgView!.length > 500 ? mimeType : '',
          nameimg: imgView!.length > 500 ? nameImg : '',
          receipt: imgView!.length > 500 ? imgView : '',
          initialDate: fechaini,
          name: data.NameDinamyc,
          palopoints: parseNumber,
          quarterId: numberQuater,
          typeActivity: parseTypeDinamyc,
          activityId: parseId,
        },
      }).then(() => finishSubmit());
    }
  };
  const finishSubmit = () => {
    setloading(false);
    onClickClose();
  };
  const validateFechaFin = (date: string) => {
    const enIni = formatGetTime(getValues('DateIni'));
    const enDate = formatGetTime(date);

    return enIni > enDate
      ? 'La fecha de cierre no puede ser menor a la fecha de inicio'
      : true;
  };
  const validateFechaIni = (date: string) => {
    const formatDate = date.split('-').join('/');
    const dateIni = formatGetTime(formatDate);
    const setDate = formatGetTimeCeroDay();

    return dateIni >= setDate
      ? true
      : 'La fecha de inicio no puede ser menor al día actual';
  };
  return (
    <>
      <div className="flex justify-center items-center w-full h-screen">
        {loading ? (
          <div className="w-full absolute h-full" style={{zIndex: 999}}>
            <Loading loaderStyles="bg-gray-300" />
          </div>
        ) : null}
        <div
          className="absolute flex z-10 w-full h-screen bg-black bg-opacity-30"
          onClick={onClickClose}
        ></div>
        <div className=" w-150 h-110 bg-white rounded-lg z-50">
          <div className={contTop}>
            <div className={contTitle}>
              <p className=" text-base font-monseratt font-medium">
                {title} <br />
                <span className=" text-sm font-monseratt font-light">
                  {subTitle}
                </span>
              </p>
              <button onClick={onClickClose} className={btnClose}>
                X
              </button>
            </div>
            <div className=" w-full h-64 md:h-128 mt-2  overflow-auto  ">
              <label className=" flex mb-2 text-sm font-medium font-monseratt  text-gray-500">
                Nombre de la dinámica<p className="text-red-400">*</p>
              </label>
              <input
                type="text"
                {...register('NameDinamyc', {required: true})}
                className=" bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Ingresa el nombre"
              />
              {errors.NameDinamyc && (
                <span className=" text-red-400 text-sm font-monseratt ">
                  El Campo Nombre es requerido
                </span>
              )}
              <label className=" flex mb-2 text-sm font-medium font-monseratt  text-gray-500 pt-3 md:pt-0">
                Descripción de la dinámica <p className="text-red-400">*</p>
              </label>
              <textarea
                {...register('DescriptionDinamyc', {required: true})}
                className=" bg-white border h-28 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Ingresa la descripción"
              />
              {errors.DescriptionDinamyc && (
                <span className=" text-red-400 text-sm font-monseratt ">
                  La descripciòn es requerido
                </span>
              )}
              <div className=" w-full md:flex justify-between mt-2">
                <div className=" w-full md:w-1/3">
                  <label className=" flex mb-2 text-sm font-medium font-monseratt w-full  text-gray-500">
                    Tipo de dinámica<p className="text-red-400">*</p>
                  </label>
                  <select
                    disabled={edit}
                    id="countries"
                    {...register('TypeDinamyc', {required: true})}
                    className=" bg-white border font-monseratt font-normal border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value={''}>Selecciona</option>
                    {edit
                      ? Options.map(value => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))
                      : OptionDinamica.map(value => (
                          <option key={value.id} value={value.id}>
                            {value.name}
                          </option>
                        ))}
                  </select>
                  {errors.TypeDinamyc && (
                    <span className=" text-red-400 text-sm font-monseratt ">
                      El Tipo de la dinamica es requerido
                    </span>
                  )}
                </div>
                <div className="w-full md:w-1/3 pt-3 md:pt-0">
                  <label className=" flex mb-2 text-sm font-medium font-monseratt  text-gray-500">
                    Número de PALOpoints<p className="text-red-400">*</p>
                  </label>
                  <input
                    type="number"
                    disabled={
                      data &&
                      edit &&
                      data.FindDynamicById.activity.typeActivity.toString() ===
                        '2'
                        ? false
                        : edit === undefined
                        ? false
                        : true
                    }
                    {...register('NumPaloPoints', {
                      required: true,
                      max: 999,
                      min: 1,
                    })}
                    className=" bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Ingresa número"
                  />
                  {errors.NumPaloPoints?.type === 'max' && (
                    <span className=" text-red-400 text-sm font-monseratt ">
                      El máximo de PALOPoints es de 999.
                    </span>
                  )}
                  {errors.NumPaloPoints?.type === 'required' && (
                    <span className=" text-red-400 text-sm font-monseratt ">
                      El campo es requerido
                    </span>
                  )}
                  {errors.NumPaloPoints?.type === 'min' && (
                    <span className=" text-red-400 text-sm font-monseratt ">
                      El numero minimo es de 1.
                    </span>
                  )}
                </div>
              </div>
              <div className=" w-full md:flex justify-between mt-2">
                <div className=" w-full md:w-1/3">
                  <label className=" flex mb-2 text-sm font-medium font-monseratt  text-gray-500">
                    Fecha de lanzamiento<p className="text-red-400">*</p>
                  </label>
                  <input
                    type="date"
                    {...register('DateIni', {
                      required: 'La fecha de inicio es requerida',
                      validate: validateFechaIni,
                    })}
                    className=" bg-white border border-gray-300 text-gray-600 uppercase text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Héctor Hernández Herrera"
                    min={min}
                    max={max}
                  />
                  {errors.DateIni && (
                    <span className=" text-red-400 text-sm font-monseratt ">
                      {errors.DateIni.message}
                    </span>
                  )}
                </div>
                <div className=" w-full md:w-1/3">
                  <label className=" flex mb-2 text-sm font-medium font-monseratt  text-gray-500 pt-3 md:pt-0">
                    Fecha de cierre<p className="text-red-400">*</p>
                  </label>
                  <input
                    type="date"
                    {...register('DateEnd', {
                      required: 'La fecha es requerida',
                      validate: validateFechaFin,
                    })}
                    className=" bg-white border border-gray-300 text-gray-600 uppercase text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Héctor Hernández Herrera"
                    min={min}
                    max={max}
                  />
                  {errors.DateEnd && (
                    <span className=" text-red-400 text-sm font-monseratt ">
                      {errors.DateEnd.message}
                    </span>
                  )}
                </div>
              </div>
              <div className=" w-full mt-6 md:flex   ">
                <div className=" w-full md:w-2/4">
                  <label className=" flex text-gray-500 text-sm font-monseratt font-light">
                    Selecciona la imagen que deseas asociar a esta nueva
                    dinámica.
                    <p className="text-red-400">*</p>
                  </label>
                  <label className=" flex text-gray-500 text-sm font-monseratt font-light">
                    Dimensión recomendada: 679 px x 679 px
                  </label>
                  <div className=" w-full my-3">
                    <span className=" text-black text-sm font-monseratt font-normal">
                      {nameImg}
                    </span>
                  </div>
                  {!OpenButton ? (
                    <div className=" relative hover:cursor-pointer w-56 h-8">
                      <label className=" w-56 h-8  absolute hover:cursor-pointer px-6 rounded-lg flex justify-center items-center text-sm text-white bg-green-400">
                        <p className=" text-sm hover:cursor-pointer text-white">
                          Seleccionar imagen
                        </p>
                      </label>
                      <input
                        {...register('testAgregarImg', {
                          required: 'La imagen es requerida',
                          validate: {
                            existFile: files =>
                              files[0]?.size < 0 || 'Se requiere Archivo',
                          },
                        })}
                        type="file"
                        data-testid="file-input"
                        accept="image/png, image/jpeg"
                        className="opacity-0 cursor-pointer w-56 h-8"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          uploadFile(e)
                        }
                      />
                      {errors.testAgregarImg && (
                        <span className=" text-red-400 text-sm font-monseratt">
                          {errors.testAgregarImg.message}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="">
                      <div className=" relative hover:cursor-pointer w-56 h-8">
                        <label className=" w-56 h-8  absolute hover:cursor-pointer px-6 rounded-lg flex justify-center items-center text-sm text-white bg-green-400">
                          <p className=" text-sm hover:cursor-pointer font-monseratt font-normal text-white">
                            Cambiar imagen
                          </p>
                        </label>
                        <input
                          type="file"
                          className="opacity-0 cursor-pointer w-56 h-8"
                          accept="image/png, image/jpeg"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            uploadFile(e)
                          }
                        />
                      </div>

                      <div className=" my-3 w-56 h-8">
                        <Button
                          style="primaryOutline"
                          onClickButton={onclickDeleteUpload}
                          text="Eliminar imagen"
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className=" flex justify-center items-center w-2/4">
                  {imgView && (
                    <div className=" w-80 h-52">
                      <img
                        src={imgView}
                        className="object-contain w-full h-full"
                        alt="img_Dimamyc"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" w-full h-16 flex justify-end items-center bg-gray-300 rounded-b-lg">
            <div className=" w-36 h-9 hover:opacity-30 ">
              <Button
                onClickButton={onClickClose}
                style="primaryTransparent"
                text="Cerrar"
              />
            </div>
            <div className=" w-36 h-9 mx-4 hover:opacity-75">
              <Button
                disabled={disabled}
                onClickButton={handleSubmit(onSubmit)}
                style="primarySolid"
                text="Guardar"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalDinamyc;
