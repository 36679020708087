import {FC} from 'react';
import BlankCard from 'components/BlankCard/BlankCard';
import {Avatar, Button, Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {GET_DAYS_TO_EXPIRE} from 'graphql/absences/Querys';
import {LeaveRequest} from 'models/leaveRequest';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {useTranslation} from 'react-i18next';
import './PalowansWithDaysToExpireCard.scss';
import {UserWithDaysToExpire} from 'models/userWithDaysToExpire';

const PalowansWithDaysToExpireCard: FC = () => {
  const {t} = useTranslation();
  const queryParams = {
    pageParams: {
      page: 1,
      size: 5,
    },
  };

  const renderAvatar = (index: number, value = '', src = '') => (
    <Grid item xs={2} key={index}>
      <Avatar className="m-auto" src={src}>
        {value}
      </Avatar>
    </Grid>
  );

  return (
    <GqlQueryController
      initialState={{} as Partial<LeaveRequest>}
      QUERY={GET_DAYS_TO_EXPIRE}
      variables={queryParams}
      rootField="daysToExpireList"
    >
      {({data}) => {
        const palowansWithDaysToExpire =
          (data?.rows as UserWithDaysToExpire[]) || [];
        return (
          <BlankCard
            className="PalowansWithDaysToExpireCard"
            title={t('leaves.PalowansWithDaysToExpireCard.title')}
            action={
              <Link to="/private/leaves/expiration">
                <Button variant="text">
                  {t('leaves.PalowansWithDaysToExpireCard.action')}
                </Button>
              </Link>
            }
          >
            <div className="avatar-wrapper">
              <Grid container className="my-8 flex justify-center">
                {palowansWithDaysToExpire.map(
                  (item: UserWithDaysToExpire, index: number) =>
                    renderAvatar(index, item.firstName.toUpperCase().charAt(0))
                )}
                {data?.total > queryParams.pageParams.size &&
                  renderAvatar(
                    0,
                    `+${data?.total - palowansWithDaysToExpire.length}`
                  )}
              </Grid>
            </div>
            <Typography variant="caption">
              {t('leaves.PalowansWithDaysToExpireCard.description')}
            </Typography>
          </BlankCard>
        );
      }}
    </GqlQueryController>
  );
};

export default PalowansWithDaysToExpireCard;
