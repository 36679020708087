import withConfiguration, {isConfigured} from 'services/configurationService';
import './Metric.css';

//eslint-disable-next-line
const Metric: React.FC<any> = ({powerBiUrl}) => {
  return (
    <>
      <div className="w-full flex md:justify-center flex-wrap md:z-100 mb-4">
        <p
          className={
            'm-0 text-4xl font-monseratt font-normal text-black md:date mt-4 md:pt-0 ml-4'
          }
        >
          Métricas
        </p>
      </div>
      <>
        <div className="flex justify-center ml-[35px]">
          <iframe
            title="dashboard_palopoints_prod"
            width="1140"
            height="541.25"
            src={powerBiUrl}
            allowFullScreen={true}
            style={{pointerEvents: 'all'}}
          />
        </div>
      </>
    </>
  );
};

export default withConfiguration(isConfigured)(Metric);
