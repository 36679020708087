/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC, useContext} from 'react';
import Modal from 'components/Modal/Modal';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import {Button, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {CANCEL_APPROVED_LEAVE_REQUEST} from 'graphql/absences/Mutations';
import {authContext} from 'contextApi/context/auth';
import {GET_CATALOGS} from 'graphql/absences/Querys';
import {Catalog} from 'models/catalog';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {LeaveRequest} from 'models/leaveRequest';
import withFormViewModel from 'viewComponents/CreateLeaveRequestForm/formViewModel';
import {useLogger} from 'hooks/useLogger';
import './LeaveRequestApprovedCancellationModal.scss';

interface LeaveRequestApprovedCancellationModalProps
  extends Omit<ModalProps, 'children' | 'onSubmit'> {
  leaveRequestId: string | undefined;
  leaveData: LeaveRequest;
  children?: Children;
}

const LeaveRequestApprovedCancellationModal: FC<
  LeaveRequestApprovedCancellationModalProps
> = ({onClose, leaveRequestId, leaveData, ...other}) => {
  const logger = useLogger(LeaveRequestApprovedCancellationModal.name);
  const {userDetails} = useContext(authContext);
  const {t} = useTranslation();
  const handleOnSubmit = (mutationPromise: any, data: any) => {
    logger.debug('on submit', data);
    mutationPromise.finally(() => {
      window.location.reload();
    });
  };
  return (
    <GqlQueryController
      QUERY={GET_CATALOGS}
      variables={{type: 'CHANGE_REASON'}}
      initialState={[] as Catalog[]}
      rootField="catalogs"
    >
      {({data: cancellationTypes}) => (
        <Modal
          title={t('leaves.LeaveRequestApprovedCancellationModal.title')}
          description={t(
            'leaves.LeaveRequestApprovedCancellationModal.description'
          )}
          onClose={onClose}
          {...other}
        >
          <GqlFormController
            mutation={CANCEL_APPROVED_LEAVE_REQUEST}
            variablesMapper={(values: any) => ({
              cancelApprovalRequest: {
                cancelReason: Number(values.cancellation_reason),
                canceledUserId: userDetails?.data?.id,
                cancellationDetails: values.cancellation_details,
                id: leaveRequestId,
              },
            })}
            onSubmit={handleOnSubmit}
            className="flex flex-col"
            itemProps={{xs: 12}}
            controls={[
              {
                name: 'typeOfLeave',
                type: 'label',
                props: {
                  label: t('leaves.LeaveRequestUpdateModal.typeOfLeave'),
                  value: leaveData?.typeOfLeave?.description,
                },
              },
              {
                name: 'cancellation_reason',
                type: 'select',
                options: {
                  required: true,
                },
                props: {
                  label: 'Motivo de cancelación',
                  placeholder: 'Selecciona una opción',
                  className: 'mt-2',
                  noSelection: t('common.choose_an_option'),
                  children: (cancellationTypes || []).map((option: any) => ({
                    key: option.id,
                    description: option.description,
                  })),
                },
              },
              {
                name: 'cancellation_details',
                props: {
                  label: 'Ingresa más detalles acerca de la cancelación',
                  className: 'mt-2',
                  multiline: true,
                  maxRows: 4,
                },
              },
            ]}
          >
            {({onSubmit}: any) => (
              <div>
                <Button
                  variant="outlined"
                  onClick={event => onClose && onClose(event, 'backdropClick')}
                >
                  {t('leaves.LeaveRequestApprovedCancellationModal.exit')}
                </Button>
                <Button onClick={onSubmit()} variant="contained">
                  {t('leaves.LeaveRequestApprovedCancellationModal.submit')}
                </Button>
              </div>
            )}
          </GqlFormController>
        </Modal>
      )}
    </GqlQueryController>
  );
};

export default withFormViewModel()(LeaveRequestApprovedCancellationModal);
