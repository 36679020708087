import {FC, SyntheticEvent, useState} from 'react';
import {Box, Tab, Tabs} from '@mui/material';

import './TabView.scss';

export interface TabProps {
  label: string;
  view: JSX.Element;
  onChange?: (index: number) => void;
}
export interface TabViewProps {
  tabs: TabProps[];
}

interface TabPanelProps {
  tabIndex: number;
  selectedTabIndex: number;
}

const TabPanel: FC<TabPanelProps> = props => {
  const {children, selectedTabIndex, tabIndex, ...other} = props;

  return (
    <div role="tabpanel" hidden={selectedTabIndex !== tabIndex} {...other}>
      {selectedTabIndex === tabIndex && <Box>{children}</Box>}
    </div>
  );
};

const TabView: FC<TabViewProps> = ({tabs}) => {
  const [value, setSelectedTab] = useState(0);

  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    newValue: number
  ) => {
    setSelectedTab(newValue);
    tabs[newValue].onChange?.(newValue);
  };

  return (
    <Box className="TabView">
      <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
        <Tabs value={value} onChange={handleChange}>
          {(tabs || []).map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {(tabs || []).map((tab, index) => (
        <TabPanel key={index} selectedTabIndex={value} tabIndex={index}>
          {tab.view}
        </TabPanel>
      ))}
    </Box>
  );
};

export default TabView;
