/**
 * Convert a file to base64
 * @param file  File to convert
 * @param compact If true, the base64 string will be compacted, without content type and base64 prefix
 */
export const fileToBase64 = (file: File, compact: boolean): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (compact) {
        resolve(reader.result?.toString().split(',')[1] || '');
        return;
      }
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};
