import {Route, Routes} from 'react-router-dom';
import HomePalowan from 'views/palopoints/palowan/home';
import LeavesStack from 'navigation/LeavesStack';
import Winners from 'views/palopoints/admin/Winners';
import Metrics from 'views/palopoints/admin/Metric';
import AdminUser from 'views/palopoints/admin/AdminUsers';
import Dynamics from 'views/palopoints/admin/Dynamics';
import HomeQuarters from 'views/palopoints/admin/Home';

const PalowanStack = () => {
  return (
    <Routes>
      <Route path="palopoints" element={<HomePalowan />} />
      <Route path="palopoints/winners" element={<Winners />} />
      <Route path="palopoints/metrics" element={<Metrics />} />
      <Route path="leaves/*" element={<LeavesStack />} />
      <Route path={'palopoints/admin'} element={<HomeQuarters />} />
      <Route
        path="palopoints/admin/:QuaterYear/:QuaterId"
        element={<Dynamics />}
      ></Route>
      <Route path={'palopoints/admin/users'} element={<AdminUser />} />
    </Routes>
  );
};

export default PalowanStack;
