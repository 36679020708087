import {Grid, Typography} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {formatDate} from 'commons/dates';
import {authContext} from 'contextApi/context/auth';
import RoleGuard from 'commons/guards/roleGuard';
import BlankCardWithTabs from 'components/BlankCardWithTabs/BlankCardWithTabs';
import BlankCard from 'components/BlankCard/BlankCard';
import Button from 'components/button/Button';
import BreakTimeCard from 'viewComponents/BreakTimeCard/BreakTimeCard';
import UsersOnLeaveList from 'viewComponents/UsersOnLeaveList/UsersOnLeaveList';
import PaloPointsShortCutCard from 'viewComponents/PaloPointsShortCutCard/PaloPointsShortCutCard';
import HolidaysCard from 'viewComponents/HolidaysCard/HolidaysCard';
import PendingApprovalsList from 'viewComponents/PendingApprovalsList/PendingApprovalsList';
import './Dashboard.scss';
import PalowansWithDaysToExpireCard from 'viewComponents/PalowansWithDaysToExpireCard/PalowansWithDaysToExpireCard';

const Dashboard: React.FC = () => {
  const {userDetails, state} = useContext(authContext);
  const [name, setName] = useState('');
  const currentDay = formatDate(new Date(), 'eeee, d MMMM yyyy');

  useEffect(() => {
    if (userDetails?.data?.firstName) {
      setName(
        `${userDetails?.data?.firstName || ''} ${
          userDetails?.data?.lastName || ''
        }`
      );
    }
  }, [userDetails, state]);

  return (
    <div className="Dashboard w-full flex flex-col flex-wrap pt-12">
      <Typography variant="body1">{currentDay}</Typography>
      <Typography variant="h3">Buenas tardes, {name.split(' ')[0]}</Typography>
      <Grid
        justifyContent="flex-end"
        direction="row"
        container
        sx={{marginBottom: 2}}
      >
        <Grid item>
          <Link to="/private/palowan/leaves/my-leaves" className="text-white">
            <Button text="Solicitar días" />
          </Link>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid item xs={7}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <BreakTimeCard userId={userDetails?.data?.id || ''} />
            </Grid>
            <Grid item xs={6}>
              <HolidaysCard />
            </Grid>
            <Grid item xs={6}>
              <PaloPointsShortCutCard />
            </Grid>
            <RoleGuard renderForAdmin>
              <Grid item xs={12}>
                <PalowansWithDaysToExpireCard />
              </Grid>
            </RoleGuard>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <RoleGuard renderForSupervisor>
            <BlankCardWithTabs
              className="h-full"
              title="Gestión de descansos de palowans"
              tabs={[
                {
                  label: 'Solicitudes a aprobar',
                  view: (
                    <PendingApprovalsList
                      userId={userDetails?.data?.id || ''}
                    />
                  ),
                },
                {
                  label: 'Palowans en descanso',
                  view: <UsersOnLeaveList />,
                },
              ]}
            />
          </RoleGuard>
          <RoleGuard hideForSupervisor renderForPalowan renderForAdmin>
            <BlankCard title="Palowans en descanso">
              <UsersOnLeaveList />
            </BlankCard>
          </RoleGuard>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
