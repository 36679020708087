import React, {ChangeEvent, FC, useEffect, useState} from 'react';
import {IconButton, InputBase, Paper, InputBaseProps} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

export type Size = 'small' | 'medium' | 'large' | 'full';

export interface SearchBarProps
  extends Omit<InputBaseProps, 'onChange' | 'size'> {
  placeholder?: string;
  size?: Size;
  applyShadow?: boolean;
  onSearch?: (query: string) => void;
}

const sizeMap = {
  small: 200,
  medium: 400,
  large: 600,
  full: '100%',
};

type PaperStyle = {
  width: number | string;
  boxShadow?: string;
};

let waitTimeout: NodeJS.Timeout;

const SearchBar: FC<SearchBarProps> = ({
  placeholder,
  size = 'medium',
  onSearch,
  applyShadow = true,
  ...props
}) => {
  const [value, setValue] = useState('');
  const paperStyle: PaperStyle = {width: sizeMap[size]};
  if (!applyShadow) {
    paperStyle['boxShadow'] = 'inherit';
  }
  const [isCloseVisible, setCloseVisible] = useState(false);
  const search = () => {
    onSearch && onSearch(value);
  };

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
  };

  const onClose = () => {
    setValue('');
  };

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.code === 'Enter') {
      search();
    }
  };

  useEffect(() => {
    clearTimeout(waitTimeout);
    setCloseVisible(value !== '');
    waitTimeout = setTimeout(() => {
      search();
    }, 2000);
  }, [value]);

  return (
    <Paper
      sx={{
        p: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        height: 56,
        border: '1px solid #ced4da',
        borderRadius: '12px',
        ...paperStyle,
      }}
    >
      <IconButton sx={{p: '10px'}} aria-label="menu">
        <SearchIcon />
      </IconButton>
      <InputBase
        value={value}
        {...props}
        onChange={handleOnChange}
        onKeyUp={onKeyUp}
        sx={{ml: 1, flex: 1}}
        placeholder={placeholder || ''}
        inputProps={{'aria-label': 'search'}}
      />
      {isCloseVisible && (
        <IconButton
          data-testid="clearButton"
          sx={{p: '10px'}}
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon color="primary" />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchBar;
