import {buildDateFormat} from 'commons/formatDate';
import {NAME_ICONS} from 'commons/icons/IconConst';
import Icons from 'components/icons/Icons';
import {Data} from 'models/dinamics';
import {ReactComponent as Return} from 'assets/IconSvgReturn.svg';

interface Props {
  CloseClick: () => void;
  data: Data;
}

export const Detail: React.FC<Props> = ({CloseClick, data}) => {
  return (
    <>
      {data && (
        <>
          <div className="flex justify-start items-center h-10">
            <div onClick={CloseClick} className=" cursor-pointer px-2">
              <Return />
            </div>
            <a className=" px-3 text-black font-monseratt over font-normal text-3xl">
              {data.FindDinamycByIdPalowan.activity.name}
            </a>
          </div>
          <div className=" w-full my-12 h-10 mt-9 flex justify-between items-center  ">
            <div className=" ml-7 w-1/2 h-10">
              <p className=" text-xs text-orange-modal font-monseratt font-normal">
                Fecha límite de participación:
              </p>
              <p className=" text-sm text-orange-modal font-monseratt font-bold">
                {buildDateFormat(
                  data.FindDinamycByIdPalowan.activity?.finalDate
                )}
              </p>
            </div>
            <div className="w-1/2 h-10 flex justify-end">
              <p className=" font-monseratt font-semibold flex justify-center items-center text-orange-modal text-2xl">
                {data.FindDinamycByIdPalowan.activity.palopoints}
                <Icons nameIcon={NAME_ICONS.PALOPOINT} active={true} />
              </p>
            </div>
          </div>
          <div className=" w-full my-11 ">
            <div className=" flex ml-7 font-monseratt text-base font-bold text-tab-color-green">
              <Icons nameIcon={NAME_ICONS.PALOWANICON} />
              <p className=" mx-3">
                {`Actualmente están participando ${data.FindDinamycByIdPalowan.activity.cantParticipants}  Palowans`}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Detail;
