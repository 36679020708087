/* eslint-disable @typescript-eslint/no-explicit-any */
import {useLazyQuery, useMutation} from '@apollo/client';
import {NAME_ICONS} from 'commons/icons/IconConst';
import Icons from 'components/icons/Icons';
import {ChangeEvent, useEffect, useState} from 'react';
import Loading from 'components/loader/Loader';
import {existImg} from 'components/card/CardDinamicasPalowan';
import {CREATE_EVIDENCE} from 'graphql/palowan/Mutation';
import {GET_CURRENT_DINAMYCS, GET_DINAMYC_ID} from 'graphql/palowan/Querys';
import Detail from 'components/detail/Detail';
import {Comments} from 'components/comments/Comments';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import {AUTHCONST} from 'commons/auth/authConst';
import ContainerModal from 'components/container/ContainerModal';
import ModalInfo from './ModalInfo';

export interface FormEditPalowanProps {
  idDinamyc: string;
  CloseModalClick: () => void;
}
export const ModalParticipatePalowan: React.FC<FormEditPalowanProps> = ({
  idDinamyc,
  CloseModalClick,
}) => {
  const email = getLocalStorageUserState(AUTHCONST.EMAIL)
    ? getLocalStorageUserState(AUTHCONST.EMAIL)
    : {data: ''};
  const [execCurrentDinamycs, {data}] = useLazyQuery(GET_DINAMYC_ID);
  const [execParticipate] = useMutation(CREATE_EVIDENCE, {
    refetchQueries: [
      {
        query: GET_CURRENT_DINAMYCS,
        variables: {limit: 100, page: 1, email: email.data},
      },
    ],
  });
  const [nameImg, setnameImg] = useState<string>('');
  const [mimeType, setmimeType] = useState<string>('');
  const [viewModalInfo, setviewModalInfo] = useState(false);
  const [loading, setloading] = useState<boolean>(false);
  useEffect(() => {
    const parseId = parseInt(idDinamyc);
    execCurrentDinamycs({
      variables: {idDinamyc: parseId, email: email.data},
    });
  }, []);
  const participateDone = () => {
    setloading(false);
    setviewModalInfo(true);
  };
  const sendParticipate = (base: string) => {
    setloading(true);
    if (email.data) {
      execParticipate({
        variables: {
          email: email.data,
          idActivity: parseInt(idDinamyc),
          mimeType: mimeType,
          name: nameImg,
          receipt: base,
        },
      })
        .then(() => participateDone())
        .catch(() => setloading(false));
    }
  };
  const uploadFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    setnameImg(event.target.files![0].name);
    setmimeType(event.target.files![0].type);

    const base64: string = await converBase64(file);
    sendParticipate(base64);
  };

  const converBase64 = (file: any) => {
    return new Promise<string>(resolve => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result!.toString());
      };
    });
  };

  const onClickCloseInfo = () => {
    setviewModalInfo(false);
    CloseModalClick();
  };

  const ComponentModalInfo = (
    <ContainerModal>
      <>
        <ModalInfo
          title="Tu participación fue registrada exitosamente."
          onClickClose={onClickCloseInfo}
        />
      </>
    </ContainerModal>
  );

  return (
    <>
      <div className="absolute z-50 w-full h-screen flex justify-end">
        <div
          className="w-full z-100  md:w-3/5 h-full bg-black bg-opacity-30"
          onClick={CloseModalClick}
        ></div>
        <div className="w-full md:w-2/5 h-full py-6 px-10 bg-white">
          {data ? (
            <div className=" w-full h-full overflow-auto pt-11">
              <Detail CloseClick={CloseModalClick} data={data} />
              <div className=" w-full flex justify-center items-center">
                <div className=" mt-5 w-120 flex justify-center ">
                  <img
                    className=" w-full  "
                    src={existImg(
                      data.FindDinamycByIdPalowan.activity.urlImage
                    )}
                    alt="ImgDummy"
                  />
                </div>
              </div>
              {nameImg.length > 0 && (
                <div className=" w-full mt-9 h-10  flex justify-between items-center  ">
                  <div className=" ml-7 w-1/2 h-10">
                    <p className=" text-base text-gray-400 font-monseratt font-semibold">
                      {nameImg}
                    </p>
                  </div>
                  <div className="w-1/2 h-10 flex justify-end">
                    <p className=" font-monseratt font-semibold flex justify-center items-center text-orange-modal text-xs">
                      <Icons nameIcon={NAME_ICONS.STATUSPENDING} />
                      Pendiente
                    </p>
                  </div>
                </div>
              )}
              <div className=" w-full flex  justify-center h-28 items-center bg-transparent">
                <div className=" relative  hover:cursor-pointer w-56 h-8">
                  <label className=" w-56 h-8 shadow-3-3 absolute hover:cursor-pointer px-6 rounded-lg flex justify-center items-center text-sm text-white bg-green-400">
                    <p className=" text-sm hover:cursor-pointer text-white">
                      {nameImg.length > 0 ? 'ENVIAR EVIDENCIA' : 'PARTICIPAR'}
                    </p>
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    className="opacity-0 cursor-pointer w-56 h-8"
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      uploadFile(e)
                    }
                    data-testid="upInput"
                  />
                </div>
              </div>
              <div className=" w-full font-monseratt text-black font-medium text-base  mt-4 px-10">
                <p>Descripción</p>
              </div>
              <div className=" w-full font-monseratt text-black font-light text-base mt-3 h-40 pl-10">
                <p data-testid="descp">
                  {data.FindDinamycByIdPalowan.activity.description}
                </p>
              </div>
              <div>
                <Comments idDinamycUser={idDinamyc} onClick={CloseModalClick} />
              </div>
            </div>
          ) : (
            <Loading loaderStyles="bg-gray-300" />
          )}
        </div>
        {loading ? <Loading loaderStyles="absolute bg-gray-300" /> : null}
      </div>
      {viewModalInfo ? ComponentModalInfo : null}
    </>
  );
};
export default ModalParticipatePalowan;
