import {FC} from 'react';
import {ReactComponent as LeafsImage} from 'assets/leafs.svg';
import BlankCard from 'components/BlankCard/BlankCard';
import {Button, Typography} from '@mui/material';
import {LeavesRoutes} from '../../navigation/routes';
import {Link} from 'react-router-dom';
import Panels from 'components/Panels/Panels';
import Status from 'components/Status/Status';
import {GET_NEXT_LEAVE} from 'graphql/absences/Querys';
import {LeaveRequest} from 'models/leaveRequest';
import {formatDate} from 'commons/dates';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import './BreakTimeCard.scss';

interface BreakTimeCardProps {
  userId: string | undefined;
}

const BreakTimeCard: FC<BreakTimeCardProps> = ({userId}) => {
  const {t} = useTranslation();
  return (
    <GqlQueryController
      initialState={{} as Partial<LeaveRequest>}
      QUERY={GET_NEXT_LEAVE}
      variables={{
        userId: userId,
        fromDate: formatDate(new Date()),
      }}
      rootField="nextLeave"
    >
      {({data: absence}) => {
        const hasData = !!absence?.id;

        return (
          <BlankCard
            className="BreakTimeCard"
            title={
              <div className="flex justify-between">
                {hasData ? (
                  <>
                    <label>
                      {t('leaves.BreakTimeCard.leavePreview.title')}
                    </label>
                    <Status label={absence?.status} />
                  </>
                ) : (
                  <label>{t('leaves.BreakTimeCard.emptyState.title')}</label>
                )}
              </div>
            }
            action={
              hasData ? (
                <Link
                  to={`/private/palowan/leaves/request/detail/${absence?.id}`}
                >
                  <Button variant="text">
                    {t('leaves.BreakTimeCard.leavePreview.action')}
                  </Button>
                </Link>
              ) : (
                <Link to={`/private/palowan/leaves/${LeavesRoutes.MY_LEAVES}`}>
                  <Button variant="text">
                    {t('leaves.BreakTimeCard.emptyState.action')}
                  </Button>
                </Link>
              )
            }
          >
            {hasData ? (
              <Panels
                items={[
                  <div className="flex flex-col justify-center items-center">
                    <Typography variant="h2">{absence?.daysOff}</Typography>
                    <Typography variant="body1">
                      {t('leaves.BreakTimeCard.leavePreview.requestedDays')}
                    </Typography>
                  </div>,
                  <>
                    <Typography variant="caption">
                      {t('leaves.BreakTimeCard.leavePreview.startDate')}
                    </Typography>
                    <Typography variant="body1">
                      {dayjs(absence?.startDate).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography variant="caption">
                      {t('leaves.BreakTimeCard.leavePreview.endDate')}
                    </Typography>
                    <Typography variant="body1">
                      {dayjs(absence?.endDate).format('DD/MM/YYYY')}
                    </Typography>
                  </>,
                ]}
              />
            ) : (
              <div className="flex flex-col justify-center items-center mb-6">
                <LeafsImage className="mb-4" />
                <Typography variant="body1">
                  {t('leaves.BreakTimeCard.emptyState.message')}
                </Typography>
                <Typography variant="caption">
                  {t('leaves.BreakTimeCard.emptyState.caption')}
                </Typography>
              </div>
            )}
          </BlankCard>
        );
      }}
    </GqlQueryController>
  );
};

export default BreakTimeCard;
