/* eslint-disable @typescript-eslint/no-unused-vars*/
import React, {useState} from 'react';
import {Grid, IconButton, Typography} from '@mui/material';
import SearchBar from 'components/SearchBar/SearchBar';
import Button from 'components/button/Button';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import {GET_ADDITIONAL_LEAVES} from 'graphql/absences/Querys';
import ModalController from 'controllers/ModalController';
import AdditionalLeaveCreateModal from 'viewComponents/AdditiionalLeaveCreateModal/AdditionalLeaveCreateModal';
import DeleteIcon from '@mui/icons-material/Delete';
import AdditionalLeaveDeleteModal from 'viewComponents/AdditionalLeaveDeleteModal/AdditionalLeaveDeleteModal';
import dayjs from 'dayjs';

const AdditionalLeaves = () => {
  const [username, setUsername] = useState('');

  const columns: ColumnProps[] = [
    {
      id: 'name',
      label: 'Nombre',
    },
    {
      id: 'expirationDate',
      label: 'Fecha de vencimiento',
      render: (value: string) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      id: 'days',
      label: 'Número de días',
    },
    {
      id: 'activationDate',
      label: 'Fecha de activación',
      render: (value: string) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      id: 'type',
      label: 'Motivo',
    },
    {
      id: 'description',
      label: 'Comentarios',
    },
    {
      id: 'id',
      label: '',
      render: (id: string, {userId}) => (
        <ModalController
          trigger={({onToggleModal}) => (
            <IconButton onClick={onToggleModal}>
              <DeleteIcon />
            </IconButton>
          )}
          modal={({isModalOpen, onToggleModal}) => (
            <AdditionalLeaveDeleteModal
              additionalLeaveId={id}
              userId={userId}
              open={isModalOpen}
              onClose={onToggleModal}
            />
          )}
        />
      ),
    },
  ];
  return (
    <div className="w-full flex flex-col flex-wrap pt-12">
      <Typography variant="h5">Additional leave</Typography>
      <Grid justifyContent="center" direction="column" container>
        <Grid
          className="mt-8"
          container
          direction="row"
          justifyContent="space-between"
        >
          <SearchBar placeholder="Buscar" onSearch={setUsername} />
          <ModalController
            trigger={({onToggleModal}) => (
              <Button text="Asignar Additional leave" onClick={onToggleModal} />
            )}
            modal={({isModalOpen, onToggleModal}) => (
              <AdditionalLeaveCreateModal
                onClose={onToggleModal}
                open={isModalOpen}
              />
            )}
          />
        </Grid>
        <Grid className="mt-4">
          <GqlTableController
            columns={columns}
            placeholder={'No hay datos'}
            updateTrigger={username}
            queryConfiguration={{
              query: GET_ADDITIONAL_LEAVES,
              rootField: 'additionalLeaves',
              queryVariables: (page: number, pageSize: number) => {
                return {
                  sort: {},
                  username,
                  pageParams: {
                    page,
                    size: pageSize,
                  },
                };
              },
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default AdditionalLeaves;
