import {COLORS} from 'commons/color/ColorConst';
import Icons from 'components/icons/Icons';

interface Props {
  nameColor: string;
  nameButton: string;
  nameIcon: string;
  onclick: () => void;
}

const ButtonIconValidate = ({
  nameColor,
  nameButton,
  nameIcon,
  onclick,
}: Props) => {
  const isColor = (bgcolor: string) => {
    switch (bgcolor) {
      case COLORS.GREEN:
        return 'bg-tab-color-green';
      case COLORS.RED:
        return 'bg-red-500';
      case COLORS.YELLOW:
        return 'bg-tab-color-green';

      default:
        return;
    }
  };
  return (
    <>
      <button
        onClick={onclick}
        className={
          isColor(nameColor) +
          ' w-32  h-9 flex justify-center items-center rounded-md'
        }
      >
        <div>
          <Icons nameIcon={nameIcon} />
        </div>
        <div className="text-white m-2 font-monseratt font-normal text-sm">
          {nameButton}
        </div>
      </button>
    </>
  );
};

export default ButtonIconValidate;
