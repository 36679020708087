import {FC, useEffect} from 'react';
import {useState} from 'react';
import {useLogger} from '../../hooks/useLogger';
import placeholder from 'assets/image-placeholder.png';
import ImagePreviewModal from 'viewComponents/ImagePreviewModal/ImagePreviewModal';
import {GET_EVIDENCE_URL} from 'graphql/absences/Querys';
import {useGraphqlQuery} from 'hooks/useGraphqlQuery';

interface GqlImagePreviewModalProps {
  isModalOpen: boolean;
  onToggleModal: () => void;
  title: string;
  imageReference: string;
}

type FileResponse = {
  filename?: string;
};

export const GqlImagePreviewModal: FC<GqlImagePreviewModalProps> = ({
  title,
  isModalOpen,
  onToggleModal,
  imageReference,
}) => {
  const [imageSrc, setImageSrc] = useState<string>(placeholder);
  const logger = useLogger('GqlImagePreviewModal');
  const state: FileResponse = {};

  const {fetchData} = useGraphqlQuery(
    state,
    GET_EVIDENCE_URL,
    'urlSignedEvidence'
  );

  const fetchImage = async (url: string) => {
    const result: FileResponse = (await fetchData({
      filename: url,
    })) as FileResponse;
    return result.toString() || placeholder;
  };

  useEffect(() => {
    logger.debug('load data');
    if (isModalOpen) {
      fetchImage(imageReference).then(data => {
        logger.debug('data loaded', data);
        setImageSrc(data);
      });
    }
  }, [isModalOpen]);

  return (
    <ImagePreviewModal
      title={title}
      open={isModalOpen}
      onClose={onToggleModal}
      src={imageSrc}
      alt={title}
    />
  );
};
