interface Props {
  title: string;
  icon: JSX.Element;
  date: string;
  fixed: boolean;
  onclickNavigate?: () => void;
}
const TitleQuater = ({title, icon, date, fixed, onclickNavigate}: Props) => {
  const isFixed = (fix: boolean): string => {
    return fix ? 'fixed ' : '';
  };
  return (
    <div className={isFixed(fixed) + ' container h-10'}>
      <div
        data-testid="titleQuater"
        className=" px-2 md:px-8 hover:opacity-50 cursor-pointer grid grid-cols-10 items-center"
        onClick={onclickNavigate}
      >
        <div className="ml-2 md:hidden">{icon}</div>
        <div className=" col-span-9 flex items-center">
          <span className="hidden md:inline">{icon}</span>
          <p className=" text-black font-monseratt font-normal md:text-2xl md:pl-4">
            {title + ' |'}
            <span className=" text-black font-monseratt md:text-1xl px-3 pt-2 font-extrabold">
              {date}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default TitleQuater;
