import {expirationDays} from 'commons/formatDate';

interface Props {
  finalDate: string;
  number: string;
}
interface Data {
  data: Props;
}

const ExpirationDay = ({data}: Data) => {
  const anio = new Date().getFullYear();
  return (
    <div className="bg-green-opacity flex items-center h-12 mb-8 rounded-sm w-full">
      <p className="font-monseratt text-tab-color-green font-bold  text-base ml-9">
        {`Faltan ${expirationDays(data?.finalDate)} días para finalizar el Q${
          data.number
        } del ${anio}`}
      </p>
    </div>
  );
};

export default ExpirationDay;
