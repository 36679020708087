import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
const api = ['AIzaSyDEECtlJ_', 'pmYVKhkkUZAKye4rfjx_', 'F1VGU'];
const firebaseConfig = {
  apiKey: api.join(''),
  authDomain: 'palo-points.firebaseapp.com',
  projectId: 'palo-points',
  storageBucket: 'palo-points.appspot.com',
  messagingSenderId: '819347905994',
  appId: '1:819347905994:web:d197204f094ad6bdd18333',
};

export const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
