/* eslint-disable @typescript-eslint/no-explicit-any */
import {Grid, TextFieldProps} from '@mui/material';
import {DateRangePickerProps} from 'components/DateRangePicker/DateRangePicker';
import DropDownInput from 'components/DropDownInput';
import {DropDownItemProps} from 'components/DropDownInput/DropDownInput';
import OutlinedInput from 'components/OutlinedInput';
import UploadPicker from 'components/UploadPicker';
import HolidaysDatePickerController from 'controllers/HolidaysDatePickerController';
import {FC, useEffect} from 'react';
import {FieldValues, RegisterOptions, useForm} from 'react-hook-form';
import LoaderWithBackdrop from 'components/LoaderWithBackdrop';
import FormLabel from 'components/FormLabel/FormLabel';
import './FormController.scss';
import SearchBar, {SearchBarProps} from '../../components/SearchBar/SearchBar';
import Checkbox from 'components/Checkbox';
import {CheckboxProps} from 'components/Checkbox/Checkbox';
import LeaveDateRangePickerController from 'controllers/LeaveDateRangePickerController';
import {
  FormRangeDateWithHalfDay,
  LeaveDateRangePickerProps,
} from 'controllers/LeaveDateRangePickerController/LeaveDateRangePickerController';

interface FormControllerChildProps {
  onSubmit: Function;
  reset: any;
}

export type ControlType =
  | 'file'
  | 'date'
  | 'select'
  | 'label'
  | 'text'
  | 'search'
  | 'checkbox'
  | 'leaveDateRangePicker';

interface ControlTestProps {
  'data-testid': string;
}

export interface FormControllerControlProps {
  name: string;
  type?: ControlType;
  props?:
    | ControlTestProps
    | TextFieldProps
    | DropDownItemProps
    | DateRangePickerProps
    | SearchBarProps
    | CheckboxProps
    | LeaveDateRangePickerProps;
  options?: RegisterOptions;
}

export interface FormControllerProps {
  controls: Array<FormControllerControlProps | FormControllerControlProps[]>;
  children: (props: FormControllerChildProps) => JSX.Element;
  itemProps?: any;
  onSubmit: Function;
  className?: string;
  watch?: (data: any) => void;
  isLoading?: boolean;
  defaultValues?: FieldValues;
}

const FormController: FC<FormControllerProps> = props => {
  const {controls, children, itemProps, isLoading, defaultValues} = props;

  const {
    watch,
    register,
    handleSubmit,
    formState: {errors},
    reset,
    setValue,
  } = useForm();

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach(key => {
        setValue(key, defaultValues[key]);
      });
    }
  }, [defaultValues]);

  watch(values => {
    props.watch && props.watch(values);
  });

  const gridContainerProps = {
    spacing: 2,
  };

  const onSubmit = (args: Map<string, any>) =>
    handleSubmit((values: any) => {
      props.onSubmit(values, args);
    });

  const getComponentType = (control: FormControllerControlProps) => {
    switch (control.type) {
      case 'file':
        return {
          component: UploadPicker,
          defaultProps: {
            onSelectFile: (file: any) => {
              register(control.name, control.options).onChange({
                target: {
                  name: control.name,
                  value: file,
                },
              });
            },
            key: 1,
            title: (control.props as DropDownItemProps)?.label,
          },
        };
      case 'date':
        return {
          component: HolidaysDatePickerController,
          defaultProps: {
            onChangeDates: (startDate: Date | null, endDate: Date | null) => {
              register(control.name, control.options).onChange({
                target: {
                  name: control.name,
                  value: {startDate, endDate},
                },
              });
            },
            defaultValue: defaultValues?.[control.name],
          },
        };
      case 'select':
        return {
          component: DropDownInput,
          defaultProps: {
            ...register(control.name, control.options),
            defaultValue: defaultValues?.[control.name],
            error: !!errors[control.name],
          },
        };
      case 'label':
        return {
          component: FormLabel,
        };
      case 'search':
        return {
          component: SearchBar,
          defaultProps: {
            onChange: (text: string) => {
              register(control.name, control.options).onChange({
                target: {
                  name: control.name,
                  value: text,
                },
              });
            },
          },
        };
      case 'checkbox':
        return {
          component: Checkbox,
          defaultProps: {
            CheckboxProps: {
              ...register(control.name, control.options),
            },
          },
        };
      case 'leaveDateRangePicker':
        return {
          component: LeaveDateRangePickerController,
          defaultProps: {
            onChange: (leaveRangeDate: FormRangeDateWithHalfDay) => {
              register(control.name, control.options).onChange({
                target: {
                  name: control.name,
                  value: leaveRangeDate,
                },
              });
            },
            defaultValue: defaultValues?.[control.name],
          },
        };
      default:
        return {
          component: OutlinedInput,
          defaultProps: {
            InputProps: {
              ...register(control.name, control.options),
              ...(control.props as TextFieldProps)?.InputProps,
            },
            error: !!errors[control.name],
            helperText: errors[control.name]?.message,
            className: 'w-full',
          },
        };
    }
  };

  const getControl = (control: FormControllerControlProps) => {
    const controlType = getComponentType(control);
    const Control: any = controlType.component;

    const controlProps = {
      ...control.props,
      ...controlType.defaultProps,
    };

    return <Control {...controlProps} />;
  };

  const getFormGrid = (
    item: FormControllerControlProps | FormControllerControlProps[],
    index: number,
    isRow?: boolean
  ) => {
    if (Array.isArray(item)) {
      return (
        <Grid container {...gridContainerProps} key={index}>
          {item.map((control, subIndex) =>
            getFormGrid(control, subIndex, true)
          )}
        </Grid>
      );
    } else if (isRow) {
      return (
        <Grid item key={index}>
          {getControl(item)}
        </Grid>
      );
    }
    return (
      <Grid key={index} item xs={6} {...itemProps}>
        {getControl(item)}
      </Grid>
    );
  };

  const renderFormControls = () =>
    controls.map((control, controlIndex) => getFormGrid(control, controlIndex));

  return (
    <form
      className={`FormController ${props.className}`}
      data-testid="FormController"
    >
      <Grid container {...gridContainerProps}>
        {renderFormControls()}
      </Grid>
      <div className="actions flex justify-end">
        {children({
          onSubmit,
          reset,
        })}
      </div>
      <LoaderWithBackdrop isLoading={!!isLoading} />
    </form>
  );
};

export default FormController;
