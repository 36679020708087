/* eslint-disable @typescript-eslint/no-explicit-any */
import log, {RootLogger} from 'loglevel';

const remote = require('loglevel-plugin-remote');

let instance: Logger | null = null;

class Logger {
  private log?: RootLogger;

  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  init() {
    if (!this.log) {
      const customJSON = (log: any) => ({
        msg: log.message,
        level: log.level.label,
        stacktrace: log.stacktrace,
      });
      remote.apply(log, {format: customJSON, url: ''});
      log.enableAll();
      this.log = log;
    }
  }

  trace(): void {}

  debug(): void {}

  info(): void {}

  warn(): void {}

  error(): void {}
}

export default new Logger();
