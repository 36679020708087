import {FC} from 'react';
import {Backdrop, CircularProgress, CircularProgressProps} from '@mui/material';
import './LoaderWithBackdrop.scss';

interface LoaderWithBackdropProps extends CircularProgressProps {
  isLoading: boolean;
}

const LoaderWithBackdrop: FC<LoaderWithBackdropProps> = ({
  isLoading,
  ...other
}) => {
  return (
    <Backdrop
      data-testid="LoaderWithBackdrop"
      sx={{zIndex: theme => theme.zIndex.drawer + 1}}
      open={isLoading}
    >
      <CircularProgress color="primary" {...other} />
    </Backdrop>
  );
};

export default LoaderWithBackdrop;
