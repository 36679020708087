import {FC, useRef} from 'react';
import {Modal as MuiModal, ModalProps as MuiModalProps} from '@mui/material';
import BlankCard from 'components/BlankCard/BlankCard';
import './Modal.scss';

export interface ModalProps extends MuiModalProps {
  title: string;
  description?: string;
  small?: boolean;
}

const Modal: FC<ModalProps> = ({
  className,
  children,
  title,
  description,
  small,
  open,
  ...other
}) => {
  const cardRef = useRef(null);
  return (
    children && (
      <MuiModal
        open={open}
        className={`${className} ${small && 'small'} Modal`}
        {...other}
      >
        <BlankCard ref={cardRef} title={title} description={description}>
          {open && children}
        </BlankCard>
      </MuiModal>
    )
  );
};

export default Modal;
