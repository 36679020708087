/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useState} from 'react';
import {Catalog} from 'models/catalog';
import {Box, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Button from 'components/button/Button';
import BlankCard from 'components/BlankCard/BlankCard';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import {FormControllerControlProps} from 'controllers/FormController/FormController';
import {AssignationType} from 'models/assignationType';
import withFormViewModel from './formViewModel';
import {CREATE_LEAVE_REQUEST} from 'graphql/absences/Mutations';
import LeaveRequestEvidenceController from 'controllers/LeaveRequestEvidenceController';
import {useNavigate} from 'react-router-dom';
import {
  ADDITIONAL_LEAVE,
  INABILITY,
  LeaveRequestType,
} from 'models/leaveRequestType';

export interface CreateLeaveRequestFormProps {
  leaveTypes: Catalog[];
  variablesMapper: (values: any) => any;
  onSubmit: (mutation: any, data: any) => Promise<any>;
}

const CreateLeaveRequestForm: React.FC<CreateLeaveRequestFormProps> = ({
  leaveTypes,
  onSubmit: formViewModelOnSubmitHandler,
  variablesMapper,
}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const [leaveType, setLeaveType] = useState<LeaveRequestType | undefined>();
  const [subLeaveTypes, setSubLeaveTypes] = useState<Catalog[]>();

  const watchForLeaveRequestType = (leaveRequest: any) => {
    const selectedLeaveType = leaveTypes.find(
      (type: any) => type.id === leaveRequest.leaveTypeId
    );
    setLeaveType(selectedLeaveType?.name as LeaveRequestType);
    const children = selectedLeaveType?.children;
    setSubLeaveTypes(children);
  };

  const handleOnSubmit = (mutationPromise: any, data: any) => {
    formViewModelOnSubmitHandler(mutationPromise, data).then(
      ({data: {createLeaveRequest}}) => {
        navigate(`/private/leaves/request/detail/${createLeaveRequest.id}`);
      }
    );
  };

  const controls: Array<
    FormControllerControlProps | FormControllerControlProps[]
  > = [
    {
      name: 'leaveTypeId',
      type: 'select',
      options: {
        required: true,
      },
      props: {
        label: t('leaves.type'),
        placeholder: t('common.choose_an_option'),
        className: 'mt-2',
        noSelection: t('common.choose_an_option'),
        children: leaveTypes.map((leaveType: any) => ({
          key: leaveType.id,
          description: leaveType.description,
        })),
      },
    },
    {
      name: 'subLeaveTypeId',
      type: 'select',
      options: {
        required: !!subLeaveTypes?.length,
      },
      props: {
        label: t('leaves.subType'),
        placeholder: t('common.choose_an_option'),
        className: 'mt-2',
        noSelection: t('common.choose_an_option'),
        disabled: !subLeaveTypes?.length,
        children: (subLeaveTypes || []).map((subLeaveType: any) => ({
          key: subLeaveType.id,
          description: subLeaveType.description,
        })),
      },
    },
    {
      name: 'leaveRangeDate',
      type: 'leaveDateRangePicker',
      options: {
        required: true,
      },
      props: {
        labels: {
          startDateLabel: t('leaves.MyLeaves.inputLabels.startDateLabel'),
          endDateLabel: t('leaves.MyLeaves.inputLabels.endDateLabel'),
          startCheckLabel: t('leaves.MyLeaves.inputLabels.startCheckLabel'),
          endCheckLabel: t('leaves.MyLeaves.inputLabels.endCheckLabel'),
          optionalStartCheckLabel: t(
            'leaves.MyLeaves.inputLabels.optionalStartCheckLabel'
          ),
          optionalEndCheckLabel: t(
            'leaves.MyLeaves.inputLabels.optionalEndCheckLabel'
          ),
        },
        withChecks: !(leaveType === ADDITIONAL_LEAVE),
      },
    },
  ];

  return (
    <BlankCard>
      <Stack spacing={2}>
        <Typography variant="h5">{t('leaves.new_request')}</Typography>
        <LeaveRequestEvidenceController
          assignationType={AssignationType.PROJECT}
          leaveType={leaveType}
        >
          {({evidenceFormControls}) => (
            <GqlFormController
              mutation={CREATE_LEAVE_REQUEST}
              variablesMapper={variablesMapper}
              className="flex flex-col"
              onSubmit={handleOnSubmit}
              watch={watchForLeaveRequestType}
              itemProps={{xs: 12}}
              controls={controls.concat(evidenceFormControls)}
            >
              {({onSubmit}: any) => (
                <Box
                  className="mt-4"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}
                >
                  <Button
                    name="save_draft"
                    onClick={() => {
                      onSubmit({
                        isDraft: true,
                        useSubLeaveType: leaveType === INABILITY,
                      })();
                    }}
                    style="primaryOutline"
                    text={t('leaves.save_draft')}
                    sx={{marginRight: '8px !important'}}
                  />
                  <Button
                    onClick={() => {
                      onSubmit({
                        isDraft: false,
                        useSubLeaveType: leaveType === INABILITY,
                      })();
                    }}
                    text={t('leaves.send_request')}
                  />
                </Box>
              )}
            </GqlFormController>
          )}
        </LeaveRequestEvidenceController>
      </Stack>
    </BlankCard>
  );
};

export default withFormViewModel()(CreateLeaveRequestForm);
