import {FC} from 'react';
import {ModalProps} from 'components/Modal/Modal';
import {useTranslation} from 'react-i18next';
import {LEAVE_STATUS_PENDING, LeaveRequest} from 'models/leaveRequest';
import './LeaveRequestInProcessModal.scss';
import LeaveUpdateStatusModal from 'viewComponents/LeaveUpdateStatusModal/LeaveUpdateStatusModal';

interface LeaveRequestInProcessModalProps
  extends Omit<ModalProps, 'children' | 'title' | 'description'> {
  children?: Children;
  leaveData?: LeaveRequest;
}

const LeaveRequestInProcessModal: FC<LeaveRequestInProcessModalProps> = ({
  leaveData,
  ...other
}) => {
  const {t} = useTranslation();
  const leaveRequestUpdate = {
    id: leaveData?.id || '',
    status: LEAVE_STATUS_PENDING,
  };

  return (
    <LeaveUpdateStatusModal
      leaveRequestId={leaveRequestUpdate.id}
      leaveRequestStatus={leaveRequestUpdate.status}
      title={t('leaves.LeaveUpdateStatusModal.title')}
      description={t('leaves.LeaveUpdateStatusModal.description')}
      {...other}
    />
  );
};

export default LeaveRequestInProcessModal;
