import {FC} from 'react';
import FormController, {
  FormControllerControlProps,
} from 'controllers/FormController/FormController';
import {GET_STATE_REPORT} from 'graphql/absences/Querys';
import {useTranslation} from 'react-i18next';
import {Box} from '@mui/material';
import Button from 'components/button/Button';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';

interface ReportsVariableProps {
  businessUnit: string;
  lastName: string;
  supervisor: string;
}

const Reports: FC = () => {
  const {t} = useTranslation();

  const controls: Array<
    FormControllerControlProps | FormControllerControlProps[]
  > = [
    {
      name: 'bussinessUnit',
      props: {
        placeholder: t('leaves.Reports.bussinessUnit'),
        label: t('leaves.Reports.bussinessUnit'),
      },
    },
    {
      name: 'lastName',
      props: {
        placeholder: t('leaves.Reports.lastName'),
        label: t('leaves.Reports.lastName'),
      },
    },
    {
      name: 'supervisor',
      props: {
        placeholder: t('leaves.Reports.supervisor'),
        label: t('leaves.Reports.supervisor'),
      },
    },
  ];

  return (
    <div className="mt-4">
      <GqlQueryController
        initialState={''}
        QUERY={GET_STATE_REPORT}
        rootField="currentStateReport"
        onSuccess={(stateReportUrl: string) => {
          if (stateReportUrl) {
            window.open(stateReportUrl, '_blank')?.focus();
          }
        }}
      >
        {({setQueryVars: setStateReportVariables}) => (
          <FormController
            className="flex flex-col"
            onSubmit={(variables: ReportsVariableProps) =>
              setStateReportVariables({filter: variables})
            }
            itemProps={{xs: 12}}
            controls={controls}
          >
            {({onSubmit}) => (
              <Box
                className="mt-4"
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'end',
                }}
              >
                <Button
                  onClick={() => {
                    onSubmit()();
                  }}
                  text={t('leaves.Reports.submit')}
                />
              </Box>
            )}
          </FormController>
        )}
      </GqlQueryController>
    </div>
  );
};

export default Reports;
