import React from 'react';
import './itemListWithImage.css';
import cx from 'classnames';

export interface ItemListWithImageProps {
  /**
   * Image URL
   */
  urlImage?: string;
  /**
   * name text
   */
  name?: string;
  /**
   * description
   */
  description?: string;
  /**
   * info text
   */
  info?: string;
  /**
   * numero de participantes
   */
  participants?: string;
}
export const ItemListWithImage: React.FC<ItemListWithImageProps> = ({
  urlImage,
  name,
  description,
  info,
  participants,
  ...props
}) => {
  const contItemListWithImage = cx({
    'w-full justify-center py-1 mb-3 px-2 text-xs border-b-2': true,
  });
  const containerImage = cx({
    'w-12/12 md:w-4/12 bg-transparent mx-auto': true,
  });
  const containerBGImage = cx({
    'h-20 md:h-24 w-12/12 rounded-lg': true,
  });
  const contTxt = cx({
    'w-6/12 text-xs text-black font-normal pt-1': true,
  });
  const contTxt1 = cx({
    'w-full font-monseratt text-sm text-black font-normal': true,
  });
  const contTxt2 = cx({
    'w-6/12 md:w-8/12 font-monseratt text-xs text-black font-normal hidden md:inline':
      true,
  });

  return (
    <div {...props} className={contItemListWithImage}>
      <div className={contTxt1}>{name}</div>
      <div className="flex py-1">
        <div className={containerImage}>
          <img
            data-testid="image"
            src={urlImage}
            alt=""
            className={containerBGImage}
          />
        </div>
        <div className="md:w-full pl-2">
          <div className={contTxt2}>{description}</div>
        </div>
      </div>
      <div className="flex pl-2 py-1">
        <div className={contTxt}>
          <span className="font-bold">PALOpoints: </span> {info}
        </div>
        <div className={contTxt}>
          <span className="font-bold">Participantes: </span> {participants}
        </div>
      </div>
    </div>
  );
};
export default ItemListWithImage;
