/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext} from 'react';
import {DocumentNode, OperationVariables, useLazyQuery} from '@apollo/client';
import {useLogger} from './useLogger';
import {alertContext} from '../contextApi/context/error';

export const useGraphqlQuery = <
  TData,
  TVariables extends OperationVariables | undefined
>(
  initialState: TData,
  queryName: DocumentNode,
  rootKey: string
) => {
  const [data, setData] = React.useState<TData>(initialState);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any[]>([]);
  const [execOperation] = useLazyQuery(queryName, {
    fetchPolicy: 'cache-and-network',
  });
  const {dispatch} = useContext(alertContext);
  const log = useLogger(useGraphqlQuery.name);

  const fetchData = async (
    withVars: TVariables,
    mapper?: (data: any) => TData
  ) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      execOperation({variables: withVars})
        .then((res: any) => {
          let _data;
          if (res.data && res.data[rootKey]) {
            log.debug('data', res.data[rootKey]);
            if (mapper) {
              _data = mapper(res.data[rootKey]);
            } else {
              _data = res.data[rootKey];
              setData(_data);
            }
          } else {
            _data = initialState;
            setData(_data);
          }
          resolve(_data);
        })
        .catch(errors => {
          log.error('error', errors);
          setErrors(errors);
          const message = errors.message || 'Generic error';
          dispatch({
            type: 'show',
            payload: {
              message: message,
              level: 'error',
              duration: 'short',
              cancelable: true,
            },
          });
          setData(initialState);
          reject(errors);
        })
        .finally(() => setIsLoading(false));
    });
  };
  return {data, fetchData, errors, isLoading};
};
