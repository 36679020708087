import ButtonTabs from 'components/buttons/ButtonTabs';
import CardDinamicas from 'components/card/CardDinamicas';
import ContainerModal from 'components/container/ContainerModal';
import ModalLateral from 'components/modals/ModalLateral';
import TitleQuater from 'components/titlequater/TitleQuater';
import {Key, useEffect, useState} from 'react';
import {ReactComponent as Return} from 'assets/IconSvgReturn.svg';
import ModalValidateImage from 'components/modals/ModalValidateImage';
import {useNavigate, useParams} from 'react-router-dom';
import {useLazyQuery} from '@apollo/client';
import {buildDateFormat, formatDates} from 'commons/formatDate';
import ModalDinamyc from 'components/modals/ModalDinamyc';
import {AdminRoutes, PrivateRoutes} from 'navigation/routes';
import ModalRejected from 'components/modals/ModalRejected';
import {GET_DINAMYCS} from 'graphql/admin/Querys';
import {CompletedActivity} from 'models/dinamics';
import {ParticipantsI} from 'models/comments';
import Loading from 'components/loader/Loader';

export const Dynamics = () => {
  const {QuaterId, QuaterYear} = useParams();

  const [execDimamycs, {data}] = useLazyQuery(GET_DINAMYCS);
  const [viewModal, setviewModal] = useState(false);
  const [viewModalRejected, setviewModalRejected] = useState(false);
  const [dataValidate, setdataValidate] = useState<ParticipantsI>();
  const [viewModalImgValidate, setviewModalImgValidate] = useState(false);
  const [viewModalNewDinamyc, setviewModalNewDinamyc] = useState(false);
  const [viewModalUpdateDinamyc, setviewModalUpdateDinamyc] = useState(false);
  const [idDinamyc, setidDinamyc] = useState<string>('');
  const [idEvidence, setidEvidence] = useState<number>();
  const navigate = useNavigate();
  const [ListFinish, setListFinish] = useState<Array<CompletedActivity>>([]);
  const yearCurrent = new Date().getFullYear();
  const numQuaterYearCurrent = parseInt(QuaterYear!);
  const [quaterFinishDate, setQuaterFinishDate] = useState<number>();
  const [quaterMinDate, setQuaterMinDate] = useState<string>();
  const [quaterMaxDate, setQuaterMaxDate] = useState<string>();

  useEffect(() => {
    if (data) {
      const maxDate = buildDateFormat(
        data.QuaterListFilter[0]?.finalDate
      ).split('/');
      const minDate = buildDateFormat(
        data.QuaterListFilter[0].initialDate
      ).split('/');
      setQuaterMaxDate(
        `${maxDate[2]}-${
          maxDate[1].length === 1 ? '0' + maxDate[1] : maxDate[1]
        }-${maxDate[0].length === 1 ? '0' + maxDate[1] : maxDate[0]}`
      );
      setQuaterMinDate(
        `${minDate[2]}-${
          minDate[1].length === 1 ? '0' + minDate[1] : minDate[1]
        }-${minDate[0].length === 1 ? '0' + minDate[1] : minDate[0]}`
      );
      setQuaterFinishDate(
        new Date(`${maxDate[2]}-${maxDate[1]}-${maxDate[0]}`).getTime()
      );
    }
  }, [data]);

  useEffect(() => {
    if (QuaterId) {
      const numberQuater = parseInt(QuaterId);
      const numberQuaterYear = parseInt(QuaterYear!);
      execDimamycs({
        variables: {
          year: numberQuaterYear,
          Id: numberQuater,
          numberQuater: numberQuater,
        },
      }).then(data => {
        const finishData = data.data.FindDynamic.completedActivities;
        setListFinish(finishData);
      });
    }
  }, [QuaterId]);

  const onclickAdd = () => {
    setviewModalNewDinamyc(true);
  };
  const onClickCloseNewDinamyc = () => {
    setviewModalNewDinamyc(false);
  };
  const onClickCloseUpdateDinamyc = () => {
    setviewModalUpdateDinamyc(false);
  };
  const handleClickUpdate = (id: string) => {
    setidDinamyc(id);
    setviewModalUpdateDinamyc(
      numQuaterYearCurrent >= yearCurrent ? true : false
    );
  };
  const handleClickView = (id: string) => {
    setidDinamyc(id);
    setviewModal(true);
  };
  const handleCloseModalClick = () => {
    setviewModal(false);
  };
  const onclickRechazo = (id: string) => {
    const parse = parseInt(id);
    setidEvidence(parse);
    setviewModalImgValidate(false);
    setviewModalRejected(true);
  };
  const onclickValidado = () => {
    setviewModalImgValidate(false);
  };
  const onClickCloseReject = () => {
    setviewModalRejected(false);
  };
  const onclickModalImg = (info: ParticipantsI) => {
    setdataValidate(info);
    setviewModalImgValidate(true);
  };

  const ComponentModalLateral = (
    <ContainerModal>
      <>
        <ModalLateral
          idDinamyc={idDinamyc}
          onclickModalImg={onclickModalImg}
          CloseModalClick={handleCloseModalClick}
        />
      </>
    </ContainerModal>
  );
  const ComponentValidateImg = (
    <ContainerModal>
      <>
        <ModalValidateImage
          data={dataValidate!}
          idDinamyc={idDinamyc}
          onclickRechazo={onclickRechazo}
          oncloseModal={onclickValidado}
          maxDate={quaterFinishDate}
        />
      </>
    </ContainerModal>
  );
  const handleNavigate = () => {
    navigate(`/${PrivateRoutes.PRIVATE}/${AdminRoutes.QUARTERS}`, {
      replace: true,
    });
  };

  const ComponentModalNewDinamyc = (
    <ContainerModal>
      <>
        <ModalDinamyc
          title="Nueva dinámica"
          subTitle="Para crear una nueva dinámica es necesario completar la información obligatoria."
          onClickClose={onClickCloseNewDinamyc}
          min={quaterMinDate}
          max={quaterMaxDate}
        />
      </>
    </ContainerModal>
  );
  const ComponentModalUpdateDinamyc = (
    <ContainerModal>
      <>
        <ModalDinamyc
          title="Modificar información"
          subTitle="Edita la información que consideres necesaria."
          idDinamyc={idDinamyc}
          onClickClose={onClickCloseUpdateDinamyc}
          edit={true}
          min={quaterMinDate}
          max={quaterMaxDate}
        />
      </>
    </ContainerModal>
  );
  const ComponentModalRejected = (
    <ContainerModal>
      <>
        <ModalRejected
          title="Motivo por el cuál fue rechazada la evidencia"
          idDinamyc={idDinamyc}
          evidenceId={idEvidence!}
          onClickClose={onClickCloseReject}
        />
      </>
    </ContainerModal>
  );

  const NoActivities = (
    <div
      className=" bg-white w-full h-36 rounded-md flex justify-center items-center shadow-md text-center px-3 text-xs mt-10 md:mt-0
      "
    >
      <p>Por el momento no hay dinámicas o retos en esta sección.</p>
    </div>
  );

  const isThereActivities = (activities: CompletedActivity[]) => {
    return activities?.length > 0
      ? activities.map(
          (item: CompletedActivity, index: Key | null | undefined) => {
            return (
              <CardDinamicas
                yearQ={numQuaterYearCurrent}
                updateButtonOnclick={() => handleClickUpdate(item.id)}
                viewDinamycOnclick={() => handleClickView(item.id)}
                cardDinamyc={item}
                key={index}
                editeable={
                  quaterFinishDate && quaterFinishDate >= new Date().getTime()
                    ? true
                    : false
                }
              />
            );
          }
        )
      : NoActivities;
  };
  const upcomingActivitiesComplete = isThereActivities(ListFinish);

  const currentActivities = isThereActivities(
    data?.FindDynamic.currentActivities
  );

  const upcomingActivities = isThereActivities(
    data?.FindDynamic.upcomingActivities
  );

  const completedActivities = isThereActivities(
    data?.FindDynamic.completedActivities
  );

  return (
    <>
      {viewModalNewDinamyc ? ComponentModalNewDinamyc : null}
      {viewModalUpdateDinamyc ? ComponentModalUpdateDinamyc : null}
      {viewModal ? ComponentModalLateral : null}
      {viewModalImgValidate ? ComponentValidateImg : null}
      {viewModalRejected ? ComponentModalRejected : null}
      {data ? (
        <>
          <div>
            <TitleQuater
              title={`Quarter ${data.QuaterListFilter[0].number}`}
              icon={<Return />}
              onclickNavigate={handleNavigate}
              date={formatDates(
                data.QuaterListFilter[0]?.initialDate,
                data.QuaterListFilter[0]?.finalDate
              )}
              fixed={false}
            />
          </div>
          <div className="  w-full flex flex-wrap justify-end md:justify-end pt-4 md:py-8 pr-4">
            {quaterFinishDate && quaterFinishDate >= new Date().getTime() && (
              <ButtonTabs title="Crear nueva" onclick={onclickAdd} />
            )}
          </div>
          {numQuaterYearCurrent >= yearCurrent ? (
            <div className=" container">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-1">
                <div>
                  <p className="mb-4 pt-4 md:pt-0  ml-4 font-monseratt text-black text-base font-bold">
                    Próximas
                  </p>
                  <div className="ml-2 flex flex-col px-3 w-90 h-64 md:h-128">
                    <div className=" overflow-auto  w-85 h-full ">
                      {upcomingActivities}
                    </div>
                  </div>
                </div>
                <div className="bg-zinc-50 md:bg-transparent">
                  <p className="mb-4 px-3 pt-4 md:pt-0 x font-monseratt text-1xl text-base font-black">
                    En curso
                  </p>
                  <div className=" flex flex-col px-3 border-gray-400 border-l md:border-r w-90 h-64 md:h-128">
                    <div className=" overflow-auto w-85 max-h-full ">
                      {currentActivities}
                    </div>
                  </div>
                </div>
                <div>
                  <p className="mb-4 px-3 pt-4 md:pt-0  font-monseratt text-base text-1xl font-extrabold">
                    Finalizadas
                  </p>
                  <div className="flex px-3 flex-col w-90 h-64 md:h-128">
                    <div className=" overflow-auto  w-85 h-full ">
                      {completedActivities}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="w-full flex justify-start md:justify-start pt-4 md:py-8 pr-4">
                <p className="mb-4 px-6 md:px-3 pt-4 md:pt-0  font-monseratt text-base text-1xl font-extrabold">
                  Finalizadas
                </p>
              </div>
              <div className=" container h-128 ">
                <div className="grid md:grid-cols-2  lg:grid-cols-3 gap-x-14 gap-y-3 h-128 overflow-auto justify-center ">
                  {upcomingActivitiesComplete}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Loading loaderStyles="" />
      )}
    </>
  );
};

export default Dynamics;
