import {FC} from 'react';
import {Typography, TypographyProps} from '@mui/material';
import './FormLabel.scss';

interface FormLabelProps extends TypographyProps {
  label?: string;
  value: string | number | null;
}

const FormLabel: FC<FormLabelProps> = ({label, value, ...other}) => {
  return (
    <Typography variant="body2" className="FormLabel" {...other}>
      {!!label && (
        <Typography display="inline" variant="subtitle2">
          {label}:
        </Typography>
      )}
      <span className={`${!!label && 'ml-2'}`}>{value}</span>
    </Typography>
  );
};

export default FormLabel;
