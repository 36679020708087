/* eslint-disable prettier/prettier */
import {FC} from 'react';
import {Grid, Box, BoxProps} from '@mui/material';
import './Panels.scss';

interface PanelsProps {
  items: JSX.Element[];
  panelProps?: BoxProps;
}

const Panels: FC<PanelsProps> = ({items, panelProps, ...other}) => {
  return (
    <Grid className="Panels" container {...other}>
      {items?.map((item, index) => (
        <Grid key={index} item xs={6}>
          <Box {...panelProps}>
            {item}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default Panels;
