/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext} from 'react';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import {LeaveRequest} from 'models/leaveRequest';
import {GET_LEAVE_HISTORY} from 'graphql/absences/Querys';
import {authContext} from 'contextApi/context/auth';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import Status from 'components/Status/Status';
import {Button, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {LeavesRoutes, PrivateRoutes} from 'navigation/routes';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const LeaveRequestsTable: React.FC = () => {
  const {t} = useTranslation();
  const {userDetails} = useContext(authContext);
  const navigate = useNavigate();
  const columns: ColumnProps[] = [
    {
      id: 'dates_of_leave',
      label: t('leaves.LeaveRequestsTable.dates_of_leave'),
      render: (_: string, row) =>
        `${dayjs(row.startDate).format('DD/MM/YYYY')} - ${dayjs(
          row.endDate
        ).format('DD/MM/YYYY')}`,
    },
    {
      id: 'request_date',
      label: t('leaves.LeaveRequestsTable.request_date'),
      render: (value: string) => dayjs(value).format('DD/MM/YYYY'),
    },
    {
      id: 'type_of_absence',
      label: t('leaves.LeaveRequestsTable.type_of_absence'),
    },
    {
      id: 'days_of_leave',
      label: t('leaves.LeaveRequestsTable.days_of_leave'),
    },
    {
      id: 'status',
      label: t('leaves.LeaveRequestsTable.status'),
      render: (value: any) => <Status label={value} />,
    },
  ];

  const mapData = (data: LeaveRequest) => {
    return {
      ...data,
      dates_of_leave: `${data.startDate} - ${data.endDate}`,
      request_date: data.createdAt,
      type_of_absence: data.typeOfLeave?.description,
      days_of_leave: data.daysOff,
    };
  };
  return (
    <>
      <Typography variant="h5">Historial</Typography>
      <GqlTableController
        columns={columns}
        placeholder={t('common.empty_list')}
        hidePagination
        pageSize={6}
        mapData={mapData}
        actions={
          <Link to="/private/leaves/list">
            <Button variant="text" endIcon={<ArrowForwardIcon />}>
              {t('leaves.LeaveRequestsTable.see_all')}
            </Button>
          </Link>
        }
        rowProps={{
          onClick: (values: any) =>
            navigate(
              `/${PrivateRoutes.PRIVATE}/leaves/${LeavesRoutes.REQUEST_DETAIL}/${values.id}`
            ),
        }}
        queryConfiguration={{
          query: GET_LEAVE_HISTORY,
          rootField: 'leaveHistory',
          queryVariables: (page, size) => ({
            userId: userDetails?.data?.id,
            pageParams: {
              page,
              size,
            },
          }),
        }}
      />
    </>
  );
};

export default LeaveRequestsTable;
