import {FC} from 'react';
import List from 'components/List/List';
import {Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {LeavesRoutes} from 'navigation/routes';
import {GET_USERS_ON_LEAVE} from 'graphql/absences/Querys';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import './UsersOnLeaveList.scss';
import EmptyState from '../../components/EmptyState/EmptyState';

interface Row {
  name?: string;
  startDate?: string;
  endDate?: string;
  daysOff?: number;
  assignationType?: string;
}

interface PalowwansOnBreakModel {
  total?: number;
  pageSize?: number;
  currentPage?: number;
  rows?: Row[];
}

const UsersOnLeaveList: FC = () => {
  const placeHolder = (): JSX.Element => {
    return (
      <div className="mt-4">
        <EmptyState message="Hoy no hay ningún Palowan en descanso" />
      </div>
    );
  };

  return (
    <GqlQueryController
      initialState={{} as PalowwansOnBreakModel}
      QUERY={GET_USERS_ON_LEAVE}
      variables={{pageParams: {page: 1, size: 5}}}
      rootField="usersOnLeave"
    >
      {({data: palowansOnBreak}) => (
        <div className="flex flex-col justify-between">
          <List
            placeholder={placeHolder()}
            data-testid="UsersOnLeaveList"
            items={(palowansOnBreak.rows || []).map((item: Row) => ({
              primaryText: item.name || '',
              secondaryText: `${item.startDate} - ${item.endDate}`,
            }))}
          />
          <div className="actions flex justify-end">
            <Link to={`/private/palowan/leaves/${LeavesRoutes.ON_BREAK}`}>
              <Button role="button" variant="text">
                Ver todos
              </Button>
            </Link>
          </div>
        </div>
      )}
    </GqlQueryController>
  );
};

export default UsersOnLeaveList;
