import {createContext, useContext, useState} from 'react';

interface UserContextValue {
  numQuater: string | null;
  setNumQuater: React.Dispatch<React.SetStateAction<string | null>>;
}

export const quaterContext = createContext<UserContextValue>({
  numQuater: null,
  setNumQuater: () => {},
});
interface Props {
  children: JSX.Element;
}

export function QuaterProvider({children}: Props) {
  const [numQuater, setNumQuater] = useState<string | null>(null);

  return (
    <quaterContext.Provider
      value={{
        numQuater,
        setNumQuater,
      }}
    >
      {children}
    </quaterContext.Provider>
  );
}

export const useQuater = () => {
  const context = useContext(quaterContext);
  return context;
};
