/* eslint-disable @typescript-eslint/no-explicit-any */
import {Catalog} from 'models/catalog';
import {FC, useContext} from 'react';
import {GET_CATALOGS} from 'graphql/absences/Querys';
import {authContext} from 'contextApi/context/auth';
import {formatDate} from 'commons/dates';
import {useNavigate} from 'react-router-dom';
import {LeavesRoutes} from 'navigation/routes';
import {useLogger} from 'hooks/useLogger';
import {alertContext} from 'contextApi/context/error';
import useAttachFile, {FileWrapper} from 'hooks/useAttachFile';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {END_AT_MORNING, START_AT_EVENING} from 'models/halfDay';

interface GraphqlError {
  message: string;
  code: string;
  extensions?: GraphqlErrorExtension;
}

interface GraphqlErrorExtension {
  code: string;
  message: string;
  serviceNames: string;
}

interface GraphqlErrors {
  graphQLErrors?: GraphqlError[];
  networkError?: Error | string;
  message?: string;
  name?: string;
}

function isGraphqlError(obj: any): obj is GraphqlErrors {
  return obj && obj.graphQLErrors;
}

function isFile(obj: any): obj is File {
  return (
    obj &&
    obj.lastModified &&
    obj.lastModifiedDate &&
    obj.name &&
    obj.size &&
    obj.type
  );
}

export const withFormViewModel = () => (Component: FC<any>) => (props: any) => {
  const log = useLogger(withFormViewModel.name);
  const {uploadFiles} = useAttachFile();
  const {userDetails} = useContext(authContext);
  const navigate = useNavigate();
  const {dispatch} = useContext(alertContext);

  const getFiles = (formData: any): FileWrapper[] => {
    return Object.entries(formData)
      .filter(([, value]) => isFile(value))
      .map(
        ([key, value]) =>
          ({
            file: value,
            evidenceType: key,
          } as unknown as FileWrapper)
      );
  };

  const variablesMapper = (formData: any, {isDraft, useSubLeaveType}: any) => {
    const scheduleOptions: string[] = [];
    formData.leaveRangeDate.startCheck &&
      scheduleOptions.push(START_AT_EVENING);
    formData.leaveRangeDate.endCheck && scheduleOptions.push(END_AT_MORNING);

    const request = {
      leaveRequest: {
        userId: userDetails?.data?.id,
        startDate: formatDate(formData.leaveRangeDate.startDate),
        endDate: formatDate(formData.leaveRangeDate.endDate),
        typeOfLeaveId: Number(
          useSubLeaveType ? formData.subLeaveTypeId : formData.leaveTypeId
        ),
        isDraft: isDraft,
        scheduleOptions,
      },
    };
    return request;
  };

  const submit = (mutationPromise: any, data: any) => {
    const files: FileWrapper[] = getFiles(data.formData);
    return new Promise((resolve, reject) => {
      mutationPromise
        .then((response: any) => {
          const leaveRequest =
            response.data.createLeaveRequest ||
            response.data.updateLeaveRequestDraft ||
            response.data.updateLeaveRequestStatus;
          uploadFiles(files, leaveRequest.id, onComplete, handleError)
            .then(() => {
              console.log('successsss');
              resolve(response);
            })
            .catch(reject);
        })
        .catch(handleError);
    });
  };

  const handleError = (error: any) => {
    if (isGraphqlError(error)) {
      const messages = error.graphQLErrors
        ?.map(err => {
          log.debug('message', err);
          return err.extensions?.message || err.message;
        })
        ?.join('\n');
      log.error('Graphql error', messages);
      dispatch({
        type: 'show',
        payload: {
          message: messages || 'Algo salió mal',
          level: 'error',
        },
      });
    } else {
      log.error('Generic error', error);
    }
  };

  const onComplete = (requestId: string) => {
    log.debug('Request created', requestId);
    dispatch({
      type: 'show',
      payload: {
        level: 'success',
        message: 'Solicitud creada con éxito',
        duration: 'short',
        callback: () => {
          log.debug('on callback');
          navigate(`../${LeavesRoutes.REQUEST_DETAIL}/${requestId}`);
        },
      },
    });
  };

  return (
    <GqlQueryController
      QUERY={GET_CATALOGS}
      variables={{type: 'LEAVE_TYPE'}}
      initialState={[] as Catalog[]}
      rootField="catalogs"
    >
      {({data: leaveTypes}) => (
        <Component
          leaveTypes={leaveTypes}
          variablesMapper={variablesMapper}
          onSubmit={submit}
          {...props}
        />
      )}
    </GqlQueryController>
  );
};

export default withFormViewModel;
