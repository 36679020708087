import cx from 'classnames';
import Button from 'components/button/Button';

export interface formEditPalowanProps {
  /**
   * title form
   */
  title?: string;
  /*
  sub title form
  */
  subTitle?: string;

  viewModal?: string;

  onClick?: () => void;
}
export const FormEditPalowan: React.FC<formEditPalowanProps> = ({
  title,
  subTitle,
  viewModal,
  onClick,
  ...props
}) => {
  /* const contModal = cx({
    'absolute z-50 w-full h-screen bg-black bg-opacity-30 flex items-center justify-center':
      true,
  });
  const contModalViewHidden = cx({'hidden w-full': true}); */
  const modal = cx({
    'w-3/5 bg-white rounded-lg border-gray-300 border': true,
  });
  const contTop = cx({
    'w-full bg-white px-8 py-8 rounded-lg': true,
  });
  const contBottom = cx({
    'w-full bg-gray-300 px-8 py-4 rounded-b-lg flex items-center justify-end':
      true,
  });
  const contTitle = cx({
    'w-full flex items-start justify-between mb-2': true,
  });
  const titleClass = cx({
    'text-lg': true,
  });
  const subTitleClass = cx({
    'text-base': true,
  });
  const btnClose = cx({
    'bg-transparent': true,
  });
  const contDoubleInput = cx({
    'w-full flex items-center justify-center': true,
  });
  const contSide = cx({
    'w-2/4': true,
  });
  const pRight = cx({
    'pr-4': true,
  });

  return (
    <div {...props} className={`${viewModal}`}>
      <div className={modal}>
        <div className={contTop}>
          <div className={contTitle}>
            <p className={titleClass}>
              {title} <br /> <span className={subTitleClass}>{subTitle}</span>
            </p>
            <button onClick={onClick} className={btnClose}>
              X
            </button>
          </div>

          <div className={contDoubleInput}>
            <div className={`${contSide} ${pRight}`}></div>
            <div className={contSide}></div>
          </div>

          <br />
          <Button style="primaryOutline" text="+ DIRECCIÓN DE ENVÍO" />
        </div>
        <div className={contBottom}>
          <Button onClickButton={onClick} style="primarySolid" text="Cerrar" />
        </div>
      </div>
    </div>
  );
};
export default FormEditPalowan;
