import makeServer, {Response} from '__test__/mockServer';

export const mockApi = () => {
  const server = makeServer();
  server.get('/users/1', () => {
    return {id: 1, name: 'Test User'};
  });
  server.post('http://localhost:4000/', () => {
    return {
      data: {
        users: [{name: 'John Smith'}, {name: 'Mike Grey'}],
      },
    };
  });
  server.post('/api/logger/v1/logs', () => {
    return new Response(200, {}, {});
  });
};
