import {FormControl, Grid} from '@mui/material';
import Checkbox from 'components/Checkbox';
import HolidaysDatePickerController from 'controllers/HolidaysDatePickerController';
import {isEqual} from 'date-fns';
import {FC, useEffect, useState} from 'react';

export interface FormRangeDateWithHalfDay {
  startDate?: Date | null;
  startCheck?: boolean;
  endDate?: Date | null;
  endCheck?: boolean;
}

interface FormRangeDateLabels {
  startDateLabel?: string;
  endDateLabel?: string;
  startCheckLabel?: string;
  endCheckLabel?: string;
  optionalStartCheckLabel?: string;
  optionalEndCheckLabel?: string;
}

export interface LeaveDateRangePickerProps {
  onChange?: (e: FormRangeDateWithHalfDay) => void;
  labels?: FormRangeDateLabels;
  defaultValue?: FormRangeDateWithHalfDay;
  withChecks?: boolean;
}

const LeaveDateRangePickerController: FC<LeaveDateRangePickerProps> = props => {
  const startCheckName = 'startCheck';
  const endCheckName = 'endCheck';
  const {onChange, labels, defaultValue, withChecks} = props;

  const initialState: FormRangeDateWithHalfDay = {
    startDate: null,
    startCheck: false,
    endDate: null,
    endCheck: false,
  };
  const [form, setForm] = useState<FormRangeDateWithHalfDay>(initialState);
  const {startDate, startCheck, endDate, endCheck} = form;
  onChange &&
    onChange({
      ...form,
      ...(withChecks ? {} : {startCheck: false, endCheck: false}),
    });

  let labelStartCheck = labels?.startCheckLabel;
  let labelEndCheck = labels?.endCheckLabel;

  if (startDate && endDate && isEqual(startDate, endDate)) {
    labelStartCheck = labels?.optionalStartCheckLabel;
    labelEndCheck = labels?.optionalEndCheckLabel;
    if (startCheck && endCheck) {
      setForm(prevForm => ({
        ...prevForm,
        startCheck: false,
        endCheck: false,
      }));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCheckField = (e: any, checked: boolean) => {
    const {name} = e.target;

    if (startDate && endDate && isEqual(startDate, endDate)) {
      switch (name) {
        case startCheckName:
          setForm(prevForm => ({
            ...prevForm,
            startCheck: checked,
            endCheck: checked ? false : prevForm.endCheck,
          }));
          return;
        case endCheckName:
          setForm(prevForm => ({
            ...prevForm,
            startCheck: checked ? false : prevForm.startCheck,
            endCheck: checked,
          }));
          return;
      }
    } else {
      setForm(prevForm => ({
        ...prevForm,
        [name]: checked,
      }));
    }
  };

  const handleStartDateField = (date: Date | null) => {
    setForm(prevForm => ({...prevForm, startDate: date}));
  };

  const handleEndDateField = (date: Date | null) => {
    setForm(prevForm => ({...prevForm, endDate: date}));
  };

  useEffect(() => {
    defaultValue && setForm(prevForm => ({...prevForm, ...defaultValue}));
  }, [defaultValue]);

  return (
    <FormControl fullWidth>
      <Grid container>
        <Grid item className={withChecks ? '' : 'pr-2'}>
          <HolidaysDatePickerController
            label={labels?.startDateLabel || ''}
            onChangeDates={handleStartDateField}
            selectionType="single"
            maxDate={form.endDate || undefined}
            defaultValue={form.startDate || undefined}
          />
        </Grid>
        {withChecks && (
          <Grid item paddingLeft={2} paddingBottom={5}>
            <Checkbox
              className="mt-9"
              name={startCheckName}
              checked={startCheck}
              value={startCheck}
              control={<></>}
              label={labelStartCheck}
              onChange={handleCheckField}
            />
          </Grid>
        )}
        <Grid item className={withChecks ? '' : 'pl-2'}>
          <HolidaysDatePickerController
            label={labels?.endDateLabel || ''}
            onChangeDates={handleEndDateField}
            selectionType="single"
            minDate={form.startDate || undefined}
            defaultValue={form.endDate || undefined}
          />
        </Grid>
        {withChecks && (
          <Grid item paddingLeft={2} paddingBottom={2}>
            <Checkbox
              className="mt-9"
              name={endCheckName}
              checked={endCheck}
              value={endCheck}
              control={<></>}
              label={labelEndCheck}
              onChange={handleCheckField}
            />
          </Grid>
        )}
      </Grid>
    </FormControl>
  );
};

export default LeaveDateRangePickerController;
