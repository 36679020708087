import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
type ClientBuilder = (url: string) => ApolloClient<NormalizedCacheObject>;

export const client: ClientBuilder = url =>
  new ApolloClient({
    link: createUploadLink({
      uri: url,
      headers: {'Apollo-Require-Preflight': 'true'},
    }),
    cache: new InMemoryCache(),
  });
