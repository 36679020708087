interface Props {
  Banner: JSX.Element;
}

const Banner = ({Banner}: Props) => {
  return (
    <div className="Banner z-10 max-h-Banner w-full z-index-200">{Banner}</div>
  );
};

export default Banner;
