import {useMutation} from '@apollo/client';
import {COLORS} from 'commons/color/ColorConst';
import {NAME_ICONS} from 'commons/icons/IconConst';
import ButtonValidate from 'components/buttons/ButtonValidate';
import {VALIDATE_EVIDENCE} from 'graphql/admin/Mutation';
import {GET_DINAMYC} from 'graphql/admin/Querys';
import {ParticipantsI} from 'models/comments';
import {ReactComponent as ReturnWhite} from 'assets/IconSvgReturnWhite.svg';
import {useState} from 'react';
import Loading from 'components/loader/Loader';

export interface formEditPalowanProps {
  data: ParticipantsI;
  idDinamyc: string;
  maxDate?: number;
  onclickRechazo: (id: string) => void;
  oncloseModal: () => void;
}
export const ModalValidateImage: React.FC<formEditPalowanProps> = ({
  data,
  idDinamyc,
  maxDate,
  onclickRechazo,
  oncloseModal,
}) => {
  const [loading, setloading] = useState<boolean>(false);

  const [execParticipate] = useMutation(VALIDATE_EVIDENCE, {
    refetchQueries: [
      {
        query: GET_DINAMYC,
        variables: {id: parseInt(idDinamyc)},
      },
    ],
  });

  const onclickValidado = () => {
    setloading(true);
    const parse = parseInt(data.evidenceID);
    execParticipate({
      variables: {
        approved: true,
        evidenceId: parse,
        reason: '',
      },
    }).then(() => {
      oncloseModal();
      setloading(false);
    });
  };

  return (
    <div className="absolute z-50 w-full h-screen bg-black bg-opacity-30  flex flex-col">
      {loading ? <Loading loaderStyles="absolute bg-gray-300" /> : null}
      <div className=" bg-black w-full h-14 flex justify-between ">
        <div className=" flex justify-center items-center">
          <>
            <ReturnWhite className=" m-6" onClick={oncloseModal} />
            <div className=" mx-4">
              <p className=" font-monseratt text-white text-sm font-medium">
                {data.name}
              </p>
            </div>
          </>
        </div>
        {maxDate && maxDate >= new Date().getTime() ? (
          <div className=" flex justify-center items-center mr-9">
            {!data.validated && (
              <>
                <div>
                  <ButtonValidate
                    nameButton="Rechazar"
                    nameIcon={NAME_ICONS.CANCELED}
                    nameColor={COLORS.RED}
                    onclick={() => onclickRechazo(data.evidenceID)}
                  />
                </div>
                <div className=" m-4">
                  <ButtonValidate
                    nameButton="Aprobar"
                    nameIcon={NAME_ICONS.CHECK}
                    nameColor={COLORS.GREEN}
                    onclick={onclickValidado}
                  />
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
      <div className="flex justify-center items-center w-full h-148 py-6 px-10 bg-transparent">
        <img className=" h-full" src={data.evidenceUrl} alt="ImgDummy" />
      </div>
    </div>
  );
};
export default ModalValidateImage;
