import React from 'react';
import ReactDOM from 'react-dom';
import {withConfigurationProvider} from 'services/configurationService';
import ConfigMiddleware from './ConfigMiddleware';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {mockApi} from './index.mock';

if (process.env.REACT_APP_ENV === 'dev' && process.env.REACT_APP_MIRAGE_ON) {
  mockApi();
}

const Middleware = withConfigurationProvider(ConfigMiddleware);

ReactDOM.render(
  <React.StrictMode>
    <Middleware />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
