import {useState} from 'react';
import cx from 'classnames';
import ContainerModal from 'components/container/ContainerModal';
import FormEditPalowan from 'components/formEditPalowan/FormEditPalowan';
import Title from 'components/title/Title';

const AdminUser = () => {
  const contModalViewHidden = cx({'hidden w-full': true});

  const [modalView, setModalView] = useState(contModalViewHidden);

  const setDisplayNone = () => {
    setModalView(contModalViewHidden);
  };

  return (
    <>
      <ContainerModal>
        <>
          <FormEditPalowan
            viewModal={modalView}
            title="Detalle Palowan"
            subTitle="Información general."
            onClick={setDisplayNone}
          />
        </>
      </ContainerModal>
      <>
        <Title title="Administrar Usuarios" />
        {/* <TabView tabs={whoTab} /> */}
      </>
    </>
  );
};

export default AdminUser;
