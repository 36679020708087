/* eslint-disable @typescript-eslint/no-explicit-any */
import {Admin, AUTHCONST} from 'commons/auth/authConst';
import {
  clearLocalStorageUserState,
  persistLocalStorageUserState,
} from 'commons/localStorage.utilities';
import {PublicRoutes} from 'navigation/routes';
import {Navigate} from 'react-router-dom';

export const authReducer = (state: any, action: any) => {
  switch (action.type) {
    case AUTHCONST.LOGIN:
      persistLocalStorageUserState(
        AUTHCONST.TOKEN,
        action.payload.payload.accessToken
      );
      persistLocalStorageUserState(AUTHCONST.ROL, Admin(action.payload.rolId));
      persistLocalStorageUserState(AUTHCONST.NEW_USER, 'false');
      persistLocalStorageUserState(
        AUTHCONST.EMAIL,
        action.payload.payload.email
      );
      return {
        data: action.payload.payload,
      };

    case AUTHCONST.LOGOUT:
      clearLocalStorageUserState(AUTHCONST.TOKEN);
      clearLocalStorageUserState(AUTHCONST.ROL);
      clearLocalStorageUserState(AUTHCONST.ACC_POINTS);
      clearLocalStorageUserState(AUTHCONST.EMAIL);
      clearLocalStorageUserState(AUTHCONST.NAME_USER);
      clearLocalStorageUserState(AUTHCONST.USER_DETAILS);
      <Navigate replace to={`/${PublicRoutes.LOGIN}`} />;
      return {
        data: null,
      };
    case AUTHCONST.GET_USER: {
      const payload = {
        ...action.payload.payload,
      };
      persistLocalStorageUserState(AUTHCONST.USER_DETAILS, payload);
      const newState = {...state, userDetails: payload};
      return newState;
    }
    default:
      return state;
  }
};
