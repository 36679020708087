export const HOLIDAY = 'HOLIDAY';
export const INABILITY = 'INABILITY';
export const DAY_OFF = 'DAY_OFF';
export const ADDITIONAL_LEAVE = 'ADDITIONAL_LEAVE';

export type LeaveRequestType =
  | typeof HOLIDAY
  | typeof INABILITY
  | typeof DAY_OFF
  | typeof ADDITIONAL_LEAVE;

export const labelFromType = (type: string): string => {
  switch (type) {
    case 'HOLIDAY':
      return 'leaves.HOLIDAY';
    case 'INABILITY':
      return 'leaves.INABILITY';
    case 'DAY_OFF':
      return 'leaves.DAY_OFF';
    case 'ADDITIONAL_LEAVE':
      return 'leaves.ADDITIONAL_LEAVE';
    default:
      return '';
  }
};
