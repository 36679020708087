/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import StickyHeadTable, {
  ColumnProps,
} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import ModalController from 'controllers/ModalController';
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import UpdateVacationDayParamModal from 'viewComponents/UpdateVacationDayParamModal/UpdateVacationDayParamModal';
import TableController from 'controllers/TableController';
import './HealthcareParamsTable.scss';
import {adminContext} from '../../contextApi/context/admin';

const HealthcareParamsTable: React.FC = () => {
  const {t} = useTranslation();

  const {
    state: {configuration, currentCountry},
  } = useContext(adminContext);

  const [healthCareList, setHealthCareList] = useState<any>(undefined);
  useEffect(() => {
    if (configuration) {
      const countryConfig = configuration[currentCountry || ''];
      const list = [];
      const maternity = {
        type: 'Maternidad',
        days: countryConfig.vacationRules.maternity,
      };
      const paternity = {
        type: 'Paternidad',
        days: countryConfig.vacationRules.paternity,
      };
      list.push(maternity);
      list.push(paternity);
      setHealthCareList({data: list});
    }
  }, [currentCountry]);

  const renderEditController = (paramId: string, values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <EditIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <UpdateVacationDayParamModal
          defaultValue={values}
          paramId={paramId}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const columns: ColumnProps[] = [
    {
      id: 'type',
      label: t('leaves.HealthcareParamsTable.type'),
      align: 'right',
    },
    {
      id: 'days',
      label: t('leaves.HealthcareParamsTable.days'),
      align: 'right',
    },
    {
      id: 'id',
      label: t('leaves.HealthcareParamsTable.actions'),
      align: 'right',
      render: (id: string, values: any) => renderEditController(id, values),
    },
  ];

  return (
    <div className="HealthcareParamsTable w-full flex flex-col flex-wrap pt-12">
      <TableController
        isLoading={false}
        //eslint-disable-next-line
        dataProvider={(page, pageSize) =>
          Promise.resolve({data: healthCareList, totalElements: 0})
        }
      >
        {({tableData, onPageChange}) => (
          <StickyHeadTable
            columns={columns}
            placeholder={t('common.empty_list')}
            hidePagination
            isLoading={false}
            rows={tableData?.data}
            onPageChange={onPageChange}
            totalElements={tableData?.totalElements}
          />
        )}
      </TableController>
    </div>
  );
};

export default HealthcareParamsTable;
