import {gql} from '@apollo/client';

export const GET_QUATER_LIST = gql`
  query Query($year: Int!) {
    QuaterList(year: $year) {
      quarters {
        id
        number
        initialDate
        finalDate
        activities {
          id
          name
          description
          typeActivity
          palopoints
          initialDate
          finalDate
          urlImage
          participants
        }
      }
    }
  }
`;

export const GET_QUATER_ID = gql`
  query Query($year: Int!) {
    QuaterList(year: $year) {
      quarters {
        id
      }
    }
  }
`;

export const GET_USER = gql`
  mutation Mutation($email: String!) {
    login(email: $email) {
      user {
        palopoints
      }
    }
  }
`;

export const GET_AVATAR_ADMIN = gql`
  query Query($email: String!) {
    ProfilePalowan(email: $email) {
      userDataList {
        profilePic
      }
    }
  }
`;
export const GET_DINAMYCS = gql`
  query Query($year: Int!, $Id: Int!, $numberQuater: Int!) {
    QuaterListFilter(year: $year, id: $Id) {
      initialDate
      finalDate
      number
    }
    FindDynamic(numberQuater: $numberQuater) {
      upcomingActivities {
        id
        quarterId
        name
        description
        typeActivity
        palopoints
        initialDate
        finalDate
        urlImage
      }
      currentActivities {
        id
        quarterId
        name
        description
        typeActivity
        palopoints
        initialDate
        finalDate
        urlImage
      }
      completedActivities {
        id
        quarterId
        name
        description
        typeActivity
        palopoints
        initialDate
        finalDate
        urlImage
      }
    }
  }
`;
export const GET_DINAMYC = gql`
  query Query($id: Int!) {
    FindDynamicById(Id: $id) {
      activity {
        id
        quarterId
        name
        description
        typeActivity
        palopoints
        initialDate
        finalDate
        urlImage
      }
    }
    FindParticipanForDynamic(Id: $id) {
      participants {
        palowanID
        email
        name
        evidenceID
        validated
        approved
        evidenceUrl
      }
    }
  }
`;
export const GET_UPDATE_DINAMYC = gql`
  query Query($id: Int!) {
    FindDynamicById(Id: $id) {
      activity {
        id
        quarterId
        name
        description
        typeActivity
        palopoints
        initialDate
        finalDate
        urlImage
      }
    }
  }
`;
export const GET_LIST_COMMENTS = gql`
  query Query($activityId: Float!, $limit: Int!, $page: Int!) {
    FindShowListComments(activityId: $activityId, limit: $limit, page: $page) {
      comments {
        ID
        comment
        palowanID
        palowanName
        palowanPicture
      }
    }
  }
`;
export const GET_LISTWINNERS = gql`
  query Query(
    $quarterId: Float!
    $categoryId: Int!
    $limit: Int!
    $page: Int!
  ) {
    FindListWinners(
      quarterID: $quarterId
      categoryID: $categoryId
      limit: $limit
      page: $page
    ) {
      winners {
        address
        city
        country
        palowanID
        palowanName
        postalCode
        state
        totalPalopoints
        status
        awardStatusID
      }
      pagination {
        limit
        next
        page
        pages
        total
      }
    }
  }
`;
export const GET_CATEGORIES = gql`
  query Query {
    FindCategoriesAward {
      categoriesAward {
        id
        data: name
        palopoints
      }
    }
  }
`;

export const GET_QS = gql`
  query Query {
    QsList {
      quarters {
        year
        qs {
          id
          q
        }
      }
    }
  }
`;
