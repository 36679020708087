import {LeavesRoutes} from 'navigation/routes';
import {Navigate, Route, Routes} from 'react-router-dom';
import Dashboard from 'views/leaves/Dashboard/Dashboard';
import PalowansOnBreakTable from 'views/leaves/PalowansOnBreakTable';
import LeaveRequestDetail from 'views/leaves/LeaveRequestDetail/LeaveRequestDetail';
import MyLeaves from 'views/leaves/MyLeaves';
import MyLeavesTable from 'views/leaves/MyLeavesTable';
import PalowansWithDaysToExpireTable from 'views/leaves/PalowansWithDaysToExpireTable';

const LeavesStack = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={LeavesRoutes.DASHBOARD} />}
      ></Route>
      <Route path={LeavesRoutes.DASHBOARD} element={<Dashboard />} />
      <Route path={LeavesRoutes.ON_BREAK} element={<PalowansOnBreakTable />} />
      <Route
        path={`${LeavesRoutes.REQUEST_DETAIL}/:id`}
        element={<LeaveRequestDetail />}
      />
      <Route path={LeavesRoutes.MY_LEAVES} element={<MyLeaves />} />
      <Route path={LeavesRoutes.LIST} element={<MyLeavesTable />} />
      <Route
        path={LeavesRoutes.EXPIRATION}
        element={<PalowansWithDaysToExpireTable />}
      />
    </Routes>
  );
};

export default LeavesStack;
