/* eslint-disable @typescript-eslint/no-explicit-any */
import cx from 'classnames';
import Button from 'components/button/Button';

export interface RejectProps {
  title?: string;
  onClickClose: () => void;
}
export const ModalInfo: React.FC<RejectProps> = ({title, onClickClose}) => {
  const contTop = cx({
    'w-full bg-white px-8 py-8 rounded-lg': true,
  });

  return (
    <div className="absolute flex justify-center items-center z-50 w-full h-screen bg-black bg-opacity-30 ">
      <div className=" w-128 h-36  bg-white rounded-lg ">
        <div className={contTop}>
          <div className=" w-full h-full mt-5 overflow-auto ">
            <div className=" w-full flex justify-center items-center ">
              <p className=" font-monseratt text-base font-medium">{title}</p>
            </div>
          </div>
        </div>

        <div className=" w-full h-21 flex justify-end items-center bg-gray-modal-footer rounded-b-lg">
          <div className=" w-36 h-9 hover:opacity-30 mr-6">
            <Button
              onClickButton={onClickClose}
              style="primarySolid"
              text="Aceptar"
              data-testid="closeButton"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalInfo;
