import {FC, useContext, useEffect} from 'react';
import DateRangePicker from 'components/DateRangePicker';
import {DateRangePickerProps} from 'components/DateRangePicker/DateRangePicker';
import {useGraphqlQuery} from 'hooks/useGraphqlQuery';
import {GET_PUBLIC_HOLIDAYS} from 'graphql/absences/Querys';
import {authContext} from 'contextApi//context/auth';
import {parse} from 'date-fns';
import {Day} from 'models/day';

interface Holiday {
  name: string;
  date: string;
}

const HolidaysDatePickerController: FC<DateRangePickerProps> = ({...other}) => {
  const DATE_FORMAT = 'yyyy-MM-dd';
  const {userDetails} = useContext(authContext);
  const {data: publicHolidays, fetchData: fetchPublicHolidays} =
    useGraphqlQuery<Day[], {countryCode: string; year: number}>(
      [],
      GET_PUBLIC_HOLIDAYS,
      'publicHolidays'
    );

  const getHolidays = async (year: number) => {
    await fetchPublicHolidays(
      {
        countryCode: userDetails?.data?.country || '',
        year: year,
      },
      data => {
        return data.map((item: Holiday) => ({
          name: item.name,
          date: parse(item.date, DATE_FORMAT, new Date()),
        }));
      }
    );
  };

  const onChangeYear = async (year: number) => {
    const exists = publicHolidays.find(
      item => item.date.getFullYear() === year
    );
    if (!exists) {
      await getHolidays(year);
    }
  };

  useEffect(() => {
    getHolidays(new Date().getFullYear());
  }, []);

  return (
    <DateRangePicker
      {...other}
      weekendsDisabled
      holidays={publicHolidays.map(day => day.date)}
      onChangeYear={onChangeYear}
    />
  );
};

export default HolidaysDatePickerController;
