import './quarters.css';
import cx from 'classnames';
import QuarterCard from 'components/quarterCard/QuarterCard';
import {buildDate} from 'commons/formatDate';
import {Data} from 'models/quaters';

export interface Props {
  /**
   * Name in welcome
   */
  name?: string;
  /**
   * Name in welcome
   */
  data: Data;

  year: number;
  /**
   * Name in welcome
   */
  onclickInto: (typeQue: string) => void;
}
export interface myprops {
  id: number;
  title: string;
  content: string;
}
export const Quarters = ({data, year, onclickInto}: Props) => {
  const contSettings = cx({
    'w-full rounded-lg px-4 md:py-4 bg-transparent': true,
  });
  const formatDates = (dateini: string, datefin: string) => {
    const dayIni = buildDate(dateini);
    const dayFin = buildDate(datefin);
    return `${dayIni} - ${dayFin}`;
  };
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // const sendQuarter = (quarter: any) => {
  // };
  return (
    <div className={contSettings}>
      <div className="flex flex-wrap justify-center  md:h-96 overflow-y-auto">
        {data.QuaterList?.quarters.map((cardmap, index) => (
          <div key={index} className=" px-4 py-4">
            <QuarterCard
              year={year}
              quarter={cardmap.number}
              previewDimanycs={cardmap.activities}
              date={formatDates(cardmap.initialDate, cardmap.finalDate)}
              onclickInto={() => onclickInto(cardmap.id)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Quarters;
