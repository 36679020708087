/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import {GET_DAYS_TO_EXPIRE} from 'graphql/absences/Querys';
import {useTranslation} from 'react-i18next';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import {LeaveRequest} from 'models/leaveRequest';
import dayjs from 'dayjs';
import './PalowansWithDaysToExpireTable.scss';

const PalowansWithDaysToExpireTable: React.FC = () => {
  const {t} = useTranslation();
  const columns: ColumnProps[] = [
    {
      id: 'firstName',
      label: t('leaves.PalowansWithDaysToExpireTable.palowan_name'),
      render: (_: string, row: any) => `${row?.firstName} ${row?.lastName}`,
    },
    {
      id: 'daysToExpire',
      label: t('leaves.PalowansWithDaysToExpireTable.days_to_expire'),
    },
    {
      id: 'date',
      label: t('leaves.PalowansWithDaysToExpireTable.expire_date'),
      render: (value: string) => dayjs(value).format('DD/MM/YYYY'),
    },
  ];

  const mapData = (data: LeaveRequest) => {
    return {
      ...data,
      dates_of_leave: `${data.startDate} - ${data.endDate}`,
      request_date: data.createdAt,
      type_of_absence: data.typeOfLeave?.description,
      days_of_leave: data.daysOff,
    };
  };

  return (
    <div className="PalowansWithDaysToExpireTable w-full flex flex-col flex-wrap pt-12">
      <ViewTitle>{t('leaves.PalowansWithDaysToExpireTable.title')}</ViewTitle>
      <GqlTableController
        columns={columns}
        placeholder={t('common.empty_list')}
        mapData={mapData}
        queryConfiguration={{
          query: GET_DAYS_TO_EXPIRE,
          rootField: 'daysToExpireList',
          queryVariables: (page, size) => ({
            pageParams: {
              page,
              size,
            },
          }),
        }}
      />
    </div>
  );
};

export default PalowansWithDaysToExpireTable;
