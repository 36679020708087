import {FC} from 'react';
import {
  List as MuiList,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import {Link} from 'react-router-dom';
import './List.scss';

export interface ListItemProps {
  primaryText: string;
  secondaryText?: string;
  linkTo?: string;
  // eslint-disable-next-line
  value?: any;
  icon?: JSX.Element;
}

export interface ListProps {
  renderItem?: (children: JSX.Element, item: ListItemProps) => JSX.Element;
  items?: ListItemProps[];
  rightIcon?: JSX.Element;
  leftIcon?: JSX.Element;
  placeholder?: string | JSX.Element;
  style?: 'plain' | 'with-separator';
}

const List: FC<ListProps> = ({
  items,
  renderItem,
  rightIcon,
  leftIcon,
  placeholder,
  style = 'plain',
  ...other
}) => {
  const conditionalLinkWrapper = (
    children: JSX.Element,
    index: number,
    linkTo?: string
  ) =>
    linkTo ? (
      <Link key={index} to={linkTo}>
        <ListItemButton>{children}</ListItemButton>
      </Link>
    ) : (
      children
    );

  const icon = (item: ListItemProps) => {
    return item.icon || leftIcon;
  };

  const displayPlaceholder = () => {
    if (typeof placeholder === 'string') {
      return <Typography variant="subtitle2">{placeholder}</Typography>;
    } else {
      return placeholder;
    }
  };
  return (
    <MuiList className="List" {...other}>
      {(items || []).map((item, index) => {
        const listItem = (
          <ListItem key={index} divider={style === 'with-separator'}>
            {icon(item) && <ListItemIcon>{icon(item)}</ListItemIcon>}
            <ListItemText
              primary={item.primaryText}
              secondary={item.secondaryText}
            />
            {rightIcon && <ListItemIcon>{rightIcon}</ListItemIcon>}
          </ListItem>
        );

        return conditionalLinkWrapper(
          renderItem ? renderItem(listItem, item) : listItem,
          index,
          item.linkTo
        );
      })}
      {(items === null || items?.length === 0) && displayPlaceholder()}
    </MuiList>
  );
};

export default List;
