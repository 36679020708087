/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {ColumnProps} from 'components/StickyHeadTable/StickyHeadTable';
import GqlTableController from 'controllers/GqlTableController/GqlTableController';
import {GET_USERS_ON_LEAVE} from 'graphql/absences/Querys';
import {useTranslation} from 'react-i18next';
import './PalowansOnBreakTable.scss';
import EmptyState from 'components/EmptyState/EmptyState';
import Button from 'components/button/Button';
// eslint-disable-next-line
import {useNavigate} from 'react-router-dom';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';

const PalowansOnBreakTable: React.FC = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const columns: ColumnProps[] = [
    {
      id: 'name',
      label: t('palowansOnBreakTable.name'),
    },
    {
      id: 'dates',
      label: t('palowansOnBreakTable.dates'),
    },
    {
      id: 'daysOff',
      label: t('palowansOnBreakTable.numberOfDays'),
    },
    {
      id: 'assignationType',
      label: t('palowansOnBreakTable.asignation'),
    },
  ];

  const mapData = (data: any) => ({
    dates: `${data.startDate} - ${data.endDate}`,
    ...data,
  });

  const goBack = () => navigate(-1);

  const placeholder = (
    <EmptyState message="No hay palowans en descanso">
      <Button
        variant="text"
        onClick={goBack}
        text={t('palowansOnBreakTable.backToDashboard')}
      />
    </EmptyState>
  );

  return (
    <div className="PalowansOnBreakTable w-full flex flex-col flex-wrap pt-12">
      <ViewTitle>Palowans en descanso</ViewTitle>
      <GqlTableController
        columns={columns}
        placeholder={placeholder}
        mapData={mapData}
        queryConfiguration={{
          query: GET_USERS_ON_LEAVE,
          rootField: 'usersOnLeave',
          queryVariables: (page, size) => ({
            pageParams: {
              page,
              size,
            },
          }),
        }}
      />
    </div>
  );
};

export default PalowansOnBreakTable;
