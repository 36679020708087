import {AUTHCONST} from 'commons/auth/authConst';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import {PublicRoutes} from 'navigation/routes';
import {useAuth} from 'contextApi/context/auth';
import {useLocation, useNavigate} from 'react-router-dom';
import {FC, useEffect} from 'react';

interface RoleFlagProps {
  isAdmin: boolean;
  isSupervisor: boolean;
  isPalowan: boolean;
}

interface RuleGuardProps {
  useRedirect?: boolean;
  renderForAdmin?: boolean;
  renderForPalowan?: boolean;
  renderForSupervisor?: boolean;
  hideForPalowan?: boolean;
  hideForAdmin?: boolean;
  hideForSupervisor?: boolean;
  getRole?: boolean;
  render?: (props: RoleFlagProps) => JSX.Element;
}

export const RoleGuard: FC<RuleGuardProps> = ({
  children,
  renderForAdmin,
  renderForPalowan,
  renderForSupervisor,
  hideForPalowan,
  hideForAdmin,
  hideForSupervisor,
  useRedirect,
  getRole,
  render,
}) => {
  const {role, userDetails} = useAuth();
  const isUser = getLocalStorageUserState(AUTHCONST.ROL)
    ? getLocalStorageUserState(AUTHCONST.ROL)
    : role;
  const {isSupervisor} = userDetails?.data || {};
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const rol = isUser.data[0];
  const isAdmin = rol === AUTHCONST.ADMIN;
  const isPalowan = rol === AUTHCONST.USER;

  const validateRoutes = (rol: string) => {
    if (pathname !== '/private') {
      return;
    }
    switch (rol) {
      case AUTHCONST.ADMIN:
        navigate('/private/palowan/leaves/dashboard');
        break;
      case AUTHCONST.USER:
        navigate('/private/palowan/leaves/dashboard');
        break;
      default:
        navigate(`/${PublicRoutes.LOGIN}`);
    }
  };

  useEffect(() => {
    useRedirect && validateRoutes(rol);
  }, []);
  if (
    (hideForPalowan && isPalowan) ||
    (hideForSupervisor && isSupervisor) ||
    (hideForAdmin && isAdmin)
  ) {
    return <></>;
  }
  if (renderForSupervisor && isSupervisor) {
    return <>{children}</>;
  }
  if (getRole && render) {
    return <>{render({isAdmin, isSupervisor: !!isSupervisor, isPalowan})}</>;
  }
  return (
    <>
      {!renderForAdmin && !renderForPalowan && children}
      {renderForAdmin && isAdmin && children}
      {renderForPalowan && isPalowan && children}
    </>
  );
};

export default RoleGuard;
