interface Props {
  title: string;
}
const Title = ({title}: Props) => {
  return (
    <div className=" h-10">
      <a className=" text-black font-monseratt font-normal text-3xl">{title}</a>
    </div>
  );
};

export default Title;
