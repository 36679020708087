import {Box, BoxProps, Typography} from '@mui/material';
import Panels from 'components/Panels/Panels';
import BlankCard from 'components/BlankCard/BlankCard';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './AditionalLeavesBalance.scss';
import withLeavesViewModel from 'viewComponents/LeavesBalance/leavesBalanceViewModel';

export interface AditionalLeavesModel {
  additionalLeaveDays?: number;
  totalAdditionalLeaveDays?: number;
  usedAdditionalLeaves?: number;
}

type InfoHeaderProps = {
  onClick?: () => void;
};

const InfoHeader: FC<InfoHeaderProps> = ({onClick}) => {
  return (
    <div className="InfoHeader flex justify-end">
      <InfoOutlinedIcon className="cursor-pointer" onClick={onClick} />
    </div>
  );
};

export interface AditionalLeavesBalanceProps extends BoxProps {
  balance?: AditionalLeavesModel;
}

export const AditionalLeavesBalance: FC<AditionalLeavesBalanceProps> = ({
  balance,
  className,
}) => {
  const {t} = useTranslation();
  const {additionalLeaveDays, totalAdditionalLeaveDays, usedAdditionalLeaves} =
    balance || {};
  return (
    <Box
      className={`AditionalLeavesBalance ${className}`}
      sx={{
        flex: 'flex',
        flexFlow: 'row',
        justifyContent: 'stretch',
        alignItems: 'center',
      }}
    >
      <BlankCard className="p-0" title={<InfoHeader />}>
        <Panels
          panelProps={{
            className: 'h-full',
          }}
          items={[
            <div className="flex flex-col justify-center items-center">
              <Typography variant="h2">{additionalLeaveDays}</Typography>
              <Typography variant="caption">
                {t('leaves.AditionalLeavesBalance.available_days_label')}
              </Typography>
            </div>,
            <div className="flex h-full flex-col justify-between">
              <div className="flex items-center">
                <Typography variant="h5">{usedAdditionalLeaves}</Typography>
                <Typography variant="caption">
                  {t('leaves.AditionalLeavesBalance.used_days_label')}
                </Typography>
              </div>
              <div className="flex items-center">
                <Typography variant="h5">{totalAdditionalLeaveDays}</Typography>
                <Typography variant="caption">
                  {t('leaves.AditionalLeavesBalance.registered_days_label')}
                </Typography>
              </div>
            </div>,
          ]}
        />
      </BlankCard>
    </Box>
  );
};

export default withLeavesViewModel()(AditionalLeavesBalance);
