import {PickersDay, PickersDayProps} from '@mui/x-date-pickers';
import cx from 'classnames';
import {areEquals, isInList, isWeekendDay} from '../../commons/dates';

export interface DateDayPickerProps {
  highlightedDays?: Date[];
  startDate?: Date;
  endDate?: Date;
  disabledDays?: Date[];
  weekendDisabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export const DateDayPicker = (
  props: PickersDayProps<Date> & DateDayPickerProps
) => {
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    startDate,
    endDate,
    weekendDisabled = false,
    disabledDays,
    minDate,
    maxDate,
    ...other
  } = props;

  const isSelected =
    !outsideCurrentMonth &&
    ((startDate && areEquals(startDate, day)) ||
      (endDate && areEquals(endDate, day)));

  const isHighlighted = !outsideCurrentMonth && isInList(day, highlightedDays);
  const isWeekend = !outsideCurrentMonth && isWeekendDay(day);
  const isDisabled =
    !outsideCurrentMonth &&
    (isInList(day, disabledDays || []) ||
      (weekendDisabled && isWeekend) ||
      (minDate && day < minDate) ||
      (maxDate && day > maxDate));

  const styles = cx({
    'bg-primary-50': isHighlighted && !isDisabled,
    'text-white bg-primary rounded-full': isSelected && !isDisabled,
  });

  return (
    <div className={styles}>
      <PickersDay
        selected={isSelected}
        {...other}
        disabled={isDisabled}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </div>
  );
};

export default DateDayPicker;
