/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC} from 'react';
import Modal, {ModalProps} from 'components/Modal/Modal';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {UPDATE_LEAVE_REQUEST_DRAFT} from 'graphql/absences/Mutations';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import './UpdateVacationDayParamModal.scss';

interface UpdateVacationDayParamModalProps
  extends Omit<ModalProps, 'children' | 'title' | 'description'> {
  children?: Children;
  paramId: string;
}

const UpdateVacationDayParamModal: FC<UpdateVacationDayParamModalProps> = ({
  paramId,
  onClose,
  ...other
}) => {
  const {t} = useTranslation();

  return (
    <Modal
      small
      className="UpdateVacationDayParamModal"
      title={t('leaves.UpdateVacationDayParamModal.title')}
      onClose={onClose}
      {...other}
    >
      <GqlFormController
        mutation={UPDATE_LEAVE_REQUEST_DRAFT}
        variablesMapper={(values: any) => ({
          leaveRequest: {
            status,
            paramId,
            endDate: values.dates.endDate,
          },
        })}
        className="flex flex-col"
        itemProps={{xs: 6}}
        defaultValues={{}}
        controls={[
          {
            name: 'years',
            props: {
              label: t('leaves.UpdateVacationDayParamModal.years'),
            },
          },
          {
            name: 'days',
            props: {
              label: t('leaves.UpdateVacationDayParamModal.days'),
            },
          },
        ]}
      >
        {({onSubmit}: any) => (
          <div>
            <Button
              variant="outlined"
              onClick={event => onClose && onClose(event, 'backdropClick')}
            >
              {t('leaves.UpdateVacationDayParamModal.cancel')}
            </Button>
            <Button onClick={onSubmit()} variant="contained">
              {t('leaves.UpdateVacationDayParamModal.submit')}
            </Button>
          </div>
        )}
      </GqlFormController>
    </Modal>
  );
};

export default UpdateVacationDayParamModal;
