import {FC, useEffect, createContext, useReducer} from 'react';
import {get} from 'commons/clients/http';

import devConfigJson from 'config/dev.json';
import prodConfigJson from 'config/prod.json';

export const environments = {
  DEV: 'dev',
  PROD: 'prod',
};

export interface Config {
  reactAppEnv: string;
  services: {
    graphql: {
      serverUri: string;
    };
    powerbi: {
      url: string;
    };
  };
}

const devConfig: Config = devConfigJson;
const prodConfig: Config = prodConfigJson;

const getConfig = (env: string): Config => {
  switch (env) {
    case environments.DEV:
      return devConfig;
    case environments.PROD:
      return prodConfig;
    default:
      return devConfig;
  }
};

let instance: AppConfig | null = null;

class AppConfig {
  private config?: Config;

  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  setConfig(config: Config) {
    this.config = config;
  }

  getConfig(): Config | undefined {
    return this.config;
  }
}

export const configSingleton = new AppConfig();

interface State {
  isLoading: boolean;
  isConfigured: boolean;
  config: Config | null;
}

const initialState: State = {
  isLoading: false,
  isConfigured: false,
  config: null,
};

const ConfigContext = createContext(initialState);

interface DispatchProps {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any;
}

const reducer = (state: State, {type, payload}: DispatchProps) => {
  switch (type) {
    case 'config':
      return {
        ...state,
        config: payload,
        isLoading: false,
        isConfigured: true,
      };
    case 'loading':
      return {
        ...state,
        isLoading: payload,
      };
    case 'configured':
      return {
        ...state,
        isLoading: false,
        isConfigured: payload,
      };
    default:
      return state;
  }
};

interface EnvConfig {
  reactAppEnv: string;
}

export const withConfigurationProvider = (Component: FC) => () => {
  const [configContext, dispatch] = useReducer(reducer, initialState);

  const loadConfig = async () => {
    dispatch({type: 'loading', payload: true});
    try {
      const envConfig: EnvConfig = await get('/config/index.json');
      const appConfig = getConfig(envConfig.reactAppEnv);
      configSingleton.setConfig(appConfig);
      dispatch({type: 'config', payload: appConfig});
    } catch (error) {
      dispatch({type: 'configured', payload: false});
    }
  };

  useEffect(() => {
    if (process && process.env) {
      // The app is running in development mode locally using webpack
      const appConfig = getConfig(process.env.REACT_APP_ENV || 'prod');
      configSingleton.setConfig(appConfig);
      dispatch({type: 'config', payload: appConfig});
    } else {
      // The app is running in the Nginx container. Pulling config.
      loadConfig();
    }
  }, []);
  return (
    <ConfigContext.Provider value={configContext}>
      <Component />
    </ConfigContext.Provider>
  );
};

const withConfiguration =
  (condition: (configContext: State) => boolean) =>
  (Component: FC) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (props: any) => {
    return (
      <ConfigContext.Consumer>
        {configContext =>
          condition(configContext) ? (
            <Component
              {...props}
              config={configContext.config}
              apolloUrl={
                configContext.config?.services?.graphql?.serverUri || ''
              }
              powerBiUrl={configContext.config?.services?.powerbi.url}
            />
          ) : null
        }
      </ConfigContext.Consumer>
    );
  };

export default withConfiguration;

export const isConfigured = (configContext: State): boolean =>
  !!configContext?.isConfigured;
