import {AlertDuration, AlertLevel} from '../context/error';
import {Reducer} from 'react';

export interface AlertState {
  config: AlertConfig | null;
  showing: boolean;
}

export type AlertConfig = {
  level?: AlertLevel | null;
  duration?: AlertDuration | number | null;
  message: string | null;
  cancelable?: boolean | null;
  callback?: () => void;
};

export interface AlertAction {
  type: AlertActionType;
  payload: AlertConfig | null;
}

type AlertActionType = 'show' | 'dismiss';

export const alertReducer: Reducer<AlertState, AlertAction> = (
  state: AlertState,
  action: AlertAction
): AlertState => {
  switch (action.type) {
    case 'show': {
      const {payload} = action;
      const config: AlertConfig = {
        level: payload?.level || 'info',
        duration: payload?.duration || 'short',
        message: payload?.message || '',
        cancelable: payload?.cancelable || false,
        callback: payload?.callback || undefined,
      };
      return {config: config, showing: true};
    }
    case 'dismiss':
      return {
        config: null,
        showing: false,
      };
    default:
      return state;
  }
};
