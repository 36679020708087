import Loading from 'components/loader/Loader';
import {useState} from 'react';
interface Tab {
  id: number;
  data: string;
}
// interface TabPalo {
//   id: string;
//   name: string;
//   palopoints: string;
// }
interface Props {
  tabs: Tab[];
  onclickTab: (id: number) => void;
}

const TabView = ({tabs, onclickTab}: Props) => {
  const [tabState, setTab] = useState(0);
  const activeTab = (tab: number) => {
    onclickTab(tab);
    return setTab(tab);
  };
  const isActive = (tab: number): string => {
    return tab === tabState
      ? 'border-tab-color-green text-tab-color-green font-bold'
      : '';
  };
  return (
    <>
      <nav className=" overflow-x-auto overflow-y-hidden md:overflow-hidden flex border-b-2 mb-2 md:mb-24 border-gray-300 text-sm font-bold max-w-sm md:max-w-screen-2xl">
        {tabs ? (
          tabs.map((tab, index) => (
            <a
              key={index}
              data-testid={index}
              onClick={() => activeTab(index)}
              className={
                isActive(index) +
                '-mb-px border-b-2 cursor-pointer font-monseratt text-base font-bold hover:border-tab-color-green	 border-transparent p-4 hover:text-tab-color-green min-w-max'
              }
            >
              {tab.data}
            </a>
          ))
        ) : (
          <Loading loaderStyles="" />
        )}
      </nav>
      <div className="flex "></div>
      {/* <div>{tabs[tabState].content}</div>{' '} */}
    </>
  );
};

export default TabView;
