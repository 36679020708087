interface Props {
  title: string;
  onclick: () => void;
  disabled?: boolean;
}

const ButtonTabs = ({title, onclick, disabled}: Props) => {
  return (
    <>
      <button
        onClick={onclick}
        disabled={disabled}
        className="w-36 h-10 rounded-md bg-tab-color-green"
      >
        <a className="text-white font-monseratt font-normal">{title}</a>
      </button>
    </>
  );
};

export default ButtonTabs;
