import loader from 'assets/gif/loader.gif';
interface Props {
  loaderStyles: string;
}
export const Loading = ({loaderStyles}: Props) => {
  return (
    <div
      className={`${loaderStyles} w-full h-full flex justify-center items-center bg-opacity-50 `}
    >
      <img src={loader} alt="Loading.." />
    </div>
  );
};

export default Loading;
