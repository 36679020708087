/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC} from 'react';
import Modal from 'components/Modal/Modal';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import {Button, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {UPDATE_LEAVE_REQUEST_STATUS} from 'graphql/absences/Mutations';
import {GET_CATALOGS} from 'graphql/absences/Querys';
import {Catalog} from 'models/catalog';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {AssignationType} from 'models/assignationType';
import LeaveRequestEvidenceController from 'controllers/LeaveRequestEvidenceController';
import {LeaveRequest} from 'models/leaveRequest';
import './LeaveRequestCancellationModal.scss';
import withFormViewModel from 'viewComponents/CreateLeaveRequestForm/formViewModel';

interface LeaveRequestCancellationModalProps
  extends Omit<ModalProps, 'children' | 'onSubmit'> {
  leaveRequestId: string | undefined;
  leaveData: LeaveRequest;
  children?: Children;
  onSubmit: (mutation: any, data: any) => Promise<any>;
}

const LeaveRequestCancellationModal: FC<LeaveRequestCancellationModalProps> = ({
  onClose,
  leaveRequestId,
  leaveData,
  onSubmit: formViewModelOnSubmitHandler,
  ...other
}) => {
  const {t} = useTranslation();
  const handleOnSubmit = (mutationPromise: any, data: any) => {
    formViewModelOnSubmitHandler(mutationPromise, data).finally(() => {
      window.location.reload();
    });
  };
  return (
    <GqlQueryController
      QUERY={GET_CATALOGS}
      variables={{type: 'CHANGE_REASON'}}
      initialState={[] as Catalog[]}
      rootField="catalogs"
    >
      {({data: cancellationTypes}) => (
        <Modal
          title={t('leaves.LeaveRequestCancellationModal.title')}
          description={t('leaves.LeaveRequestCancellationModal.description')}
          onClose={onClose}
          {...other}
        >
          <LeaveRequestEvidenceController
            assignationType={AssignationType.PROJECT}
            leaveType={leaveData?.typeOfLeave?.name}
          >
            {({evidenceFormControls}) => (
              <GqlFormController
                mutation={UPDATE_LEAVE_REQUEST_STATUS}
                variablesMapper={(values: any) => ({
                  leaveRequest: {
                    id: leaveRequestId,
                    status: 'CANCELLED',
                    cancellation_details: values.cancellation_details,
                  },
                })}
                onSubmit={handleOnSubmit}
                className="flex flex-col"
                itemProps={{xs: 12}}
                controls={[
                  {
                    name: 'typeOfLeave',
                    type: 'label',
                    props: {
                      label: t('leaves.LeaveRequestUpdateModal.typeOfLeave'),
                      value: leaveData?.typeOfLeave?.description,
                    },
                  },
                  {
                    name: 'cancellation_details',
                    type: 'select',
                    options: {
                      required: true,
                    },
                    props: {
                      label: 'Motivo de cancelación',
                      placeholder: 'Selecciona una opción',
                      className: 'mt-2',
                      noSelection: t('common.choose_an_option'),
                      children: (cancellationTypes || []).map(
                        (option: any) => ({
                          key: option.description,
                          description: option.description,
                        })
                      ),
                    },
                  },
                  {
                    name: 'newEvidences',
                    type: 'label',
                    props: {
                      value: t('leaves.LeaveRequestUpdateModal.newEvidences'),
                    },
                  },
                  evidenceFormControls,
                ]}
              >
                {({onSubmit}: any) => (
                  <div>
                    <Button
                      variant="outlined"
                      onClick={event =>
                        onClose && onClose(event, 'backdropClick')
                      }
                    >
                      {t('leaves.LeaveRequestCancellationModal.exit')}
                    </Button>
                    <Button onClick={onSubmit()} variant="contained">
                      {t('leaves.LeaveRequestCancellationModal.submit')}
                    </Button>
                  </div>
                )}
              </GqlFormController>
            )}
          </LeaveRequestEvidenceController>
        </Modal>
      )}
    </GqlQueryController>
  );
};

export default withFormViewModel()(LeaveRequestCancellationModal);
