/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC, useContext} from 'react';
import Modal, {ModalProps} from 'components/Modal/Modal';
import {Button} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {UPDATE_APPROVAL_STATUS} from 'graphql/absences/Mutations';
import {
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_REJECTED,
  LeaveStatus,
} from 'models/leaveRequest';
import {alertContext} from 'contextApi/context/error';
import useUser from 'hooks/useUser';
import './LeaveUpdateApprovalStatusModal.scss';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';

interface LeaveUpdateApprovalStatusModalProps
  extends Omit<ModalProps, 'children'> {
  children?: Children;
  approvalId: string;
  leaveRequestId: string;
  status: LeaveStatus;
  title: string;
  description: string;
}

const LeaveUpdateApprovalStatusModal: FC<
  LeaveUpdateApprovalStatusModalProps
> = ({
  approvalId,
  leaveRequestId,
  status,
  title,
  description,
  onClose,
  ...other
}) => {
  const {t} = useTranslation();
  const {dispatch} = useContext(alertContext);
  const {fetchUser} = useUser();
  const handleOnSubmit = (mutationPromise: any) => {
    mutationPromise
      .then((data: any) => {
        const updateApprovalStatus = data?.data?.updateApprovalStatus?.id;
        if (updateApprovalStatus) {
          let message = '';
          switch (status) {
            case LEAVE_STATUS_APPROVED:
              message = t('leaves.LeaveUpdateApprovalStatusModal.aproved');
              break;
            case LEAVE_STATUS_REJECTED:
              message = t('leaves.LeaveUpdateApprovalStatusModal.rejected');
              break;
            default:
              break;
          }
          dispatch({
            type: 'show',
            payload: {level: 'success', message: message},
          });
          fetchUser().finally(() => {
            window.location.reload();
          });
        }
      })
      .catch((error: any) => {
        dispatch({
          type: 'show',
          payload: {level: 'error', message: error.message},
        });
      });
  };

  return (
    <Modal
      small
      className="LeaveUpdateApprovalStatusModal"
      title={title}
      description={description}
      onClose={onClose}
      {...other}
    >
      <GqlFormController
        mutation={UPDATE_APPROVAL_STATUS}
        variablesMapper={(values: any) => ({
          approvalId: approvalId,
          leaveRequestId: leaveRequestId,
          status: status,
          comment: values.comment,
        })}
        onSubmit={handleOnSubmit}
        className="flex flex-col"
        itemProps={{xs: 12}}
        controls={[
          {
            name: 'comment',
            props: {
              label: 'Comentarios',
              className: 'mt-2',
              multiline: true,
              maxRows: 4,
            },
          },
        ]}
      >
        {({onSubmit}: any) => (
          <div>
            <Button
              variant="outlined"
              onClick={event => onClose && onClose(event, 'backdropClick')}
            >
              {t('leaves.LeaveUpdateApprovalStatusModal.cancel')}
            </Button>
            <Button onClick={onSubmit()} variant="contained">
              {t('leaves.LeaveUpdateApprovalStatusModal.submit')}
            </Button>
          </div>
        )}
      </GqlFormController>
    </Modal>
  );
};

export default LeaveUpdateApprovalStatusModal;
