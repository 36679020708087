/* eslint-disable @typescript-eslint/no-explicit-any*/
import {useEffect, useState} from 'react';
import TableData from 'models/TableData';
import LoaderWithBackdrop from 'components/LoaderWithBackdrop';

interface TableControllerChildProps {
  page: number;
  pageSize: number;
  tableData: TableData | undefined;
  onPageChange: Function;
}

interface TableControllerProps {
  hideLoader?: boolean;
  isLoading?: boolean;
  pageSize?: number;
  updateTrigger?: string;
  dataProvider: (page: number, pageSize: number) => Promise<any>;
  children: (props: TableControllerChildProps) => JSX.Element;
}

const TableController = (props: TableControllerProps) => {
  const {
    hideLoader,
    dataProvider,
    children,
    isLoading,
    pageSize = 10,
    updateTrigger,
  } = props;
  const pageInfoInitialValues = {
    page: 0,
    pageSize,
    callback: () => null,
  };
  const [pageInfo, setPageInfo] = useState<any>(pageInfoInitialValues);
  const [data, setData] = useState<TableData>();

  const onPageChange = (values: any, callback: Function) => {
    setPageInfo({
      page: values.page + 1,
      pageSize: values.pageSize,
      callback,
    });
  };

  const fetchData = () => {
    return dataProvider(pageInfo.page, pageInfo.pageSize).then(({data}) => {
      setData(data);
    });
  };

  useEffect(() => {
    if (pageInfo.pageSize) {
      fetchData();
    }
  }, [pageInfo]);

  useEffect(() => {
    setPageInfo(pageInfoInitialValues);
  }, [updateTrigger]);

  return (
    <>
      {children({
        page: pageInfo.page,
        pageSize: pageInfo.pageSize,
        tableData: data,
        onPageChange: (values: any) => onPageChange(values, fetchData),
      })}
      {!hideLoader && <LoaderWithBackdrop isLoading={!!isLoading} />}
    </>
  );
};

export default TableController;
