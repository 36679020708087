import {FC, useContext} from 'react';
import Modal from 'components/Modal/Modal';
import {Button, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {DELETE_ADDITIONAL_LEAVE} from 'graphql/absences/Mutations';
import {alertContext} from 'contextApi/context/error';
import './AdditionalLeaveDeleteModal.scss';

interface AdditionalLeaveDeleteModalProps extends Omit<ModalProps, 'children'> {
  children?: Children;
  additionalLeaveId: string;
  userId: string;
}

const AdditionalLeaveDeleteModal: FC<AdditionalLeaveDeleteModalProps> = ({
  onClose,
  additionalLeaveId,
  userId,
  ...other
}) => {
  const {t} = useTranslation();
  const [mutation] = useMutation(DELETE_ADDITIONAL_LEAVE);
  const {dispatch} = useContext(alertContext);

  const onSubmit = () => {
    const data = {
      userId,
      additionalLeaveId,
    };
    mutation({variables: data})
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        dispatch({
          type: 'show',
          payload: {level: 'error', message: error.message},
        });
      });
  };

  return (
    <Modal
      small
      className="AdditionalLeaveDeleteModal"
      onClose={onClose}
      title={t('leaves.AdditionalLeaveDeleteModal.title')}
      description={t('leaves.AdditionalLeaveDeleteModal.description')}
      {...other}
    >
      <div className="flex justify-end">
        <Button
          variant="outlined"
          onClick={event => onClose && onClose(event, 'backdropClick')}
        >
          {t('leaves.AdditionalLeaveDeleteModal.cancel')}
        </Button>
        <Button onClick={onSubmit} variant="contained">
          {t('leaves.AdditionalLeaveDeleteModal.submit')}
        </Button>
      </div>
    </Modal>
  );
};

export default AdditionalLeaveDeleteModal;
