import {createTheme} from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#037A5B',
      contrastText: '#fff',
    },
    info: {
      main: '#426AEA',
    },
    warning: {
      main: '#EF8035',
    },
    error: {
      main: '#BC0A1C',
    },
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    subtitle2: {
      color: '#676676',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});
