/* eslint-disable @typescript-eslint/no-explicit-any */
import {DATE_FORMAT} from 'commons/dates';
import {intervalToDuration, parse} from 'date-fns';
import {useLogger} from 'hooks/useLogger';
import useUser from 'hooks/useUser';
import {labelFromType} from 'models/leaveRequestType';
import {FC, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface LeavesModel {
  availableDays?: number;
  vacationDays?: number;
  additionalLeaveDays?: number;
  totalAdditionalLeaveDays?: number;
  usedAdditionalLeaves?: number;
  accumulatedDays?: number;
  daysToExpire?: string[];
  usedDays?: number;
  yearsOfService?: number;
}

export const withLeavesViewModel =
  () => (Component: FC<any>) => (props: any) => {
    const {userDetails, fetchUser} = useUser();
    const [balance, setBalance] = useState<LeavesModel | null>();
    const logger = useLogger('AbsenceBalanceViewModel');
    const {t} = useTranslation();
    useEffect(() => {
      fetchUser();
    }, []);

    useEffect(() => {
      const balance = userDetails?.data?.leaveBalance;
      const yearsOfService = intervalToDuration({
        start: parse(
          userDetails?.data?.hiringDate || '',
          DATE_FORMAT,
          new Date()
        ),
        end: new Date(),
      });
      logger.debug('balance', balance);
      const result: LeavesModel = {
        availableDays: balance?.availableDays,
        vacationDays: balance?.vacationDays,
        additionalLeaveDays: balance?.additionalLeaveDays,
        totalAdditionalLeaveDays: balance?.totalAdditionalLeaveDays,
        usedAdditionalLeaves: balance?.usedAdditionalLeaves,
        accumulatedDays: balance?.accumulatedDays,
        usedDays: balance?.usedDays,
        yearsOfService: yearsOfService.years,
        daysToExpire: balance?.daysToExpire?.map(day =>
          t('leaves.notice_expire_message', {
            type: t(labelFromType(day.type)),
            days: day.days,
            date: day.date,
          })
        ),
      };
      setBalance(result);
    }, [userDetails]);
    return <Component {...{balance: balance}} {...props} />;
  };
export default withLeavesViewModel;
