import {AUTHCONST} from 'commons/auth/authConst';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import {PublicRoutes} from 'navigation/routes';
import {useAuth} from 'contextApi/context/auth';
import {Navigate, Outlet} from 'react-router-dom';

export const AuthGuard = () => {
  const {token} = useAuth();
  const tokenexist = getLocalStorageUserState(AUTHCONST.TOKEN)
    ? getLocalStorageUserState(AUTHCONST.TOKEN)
    : token;

  return tokenexist?.data?.length > 7 ? (
    <Outlet />
  ) : (
    <Navigate replace to={`/${PublicRoutes.LOGIN}`} />
  );
};
export default AuthGuard;
