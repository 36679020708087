export const PublicRoutes = {
  LOGIN: 'login',
};
export const PrivateRoutes = {
  PRIVATE: 'private',
};
export const AdminRoutes = {
  PRIVATE: 'admin',
  DASHBOARD: 'Dashboard',
  HOME: 'Home',
  LISTADOS: 'Listados',
  QUARTERS: 'Quarters',
  WINNERS: 'Winners',
  METRIC: 'Metric',
};
export const PalowanRoutes = {
  PRIVATE: 'palowan',
  INDEX: '/',
};
export const LeavesRoutes = {
  DASHBOARD: 'dashboard',
  ON_BREAK: 'palowans/on-break',
  REQUEST_DETAIL: 'request/detail',
  MY_LEAVES: 'my-leaves',
  LIST: 'list',
  EXPIRATION: 'expiration',
  CONFIGURATION: 'management',
  ADDITIONAL_LEAVES: 'additional-leave',
  REPORTS: 'reports',
  PENDING_APPROVALS: 'requests',
};
