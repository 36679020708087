import {Component, ReactNode, FC} from 'react';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch() {}

  public render() {
    if (this.state.hasError) {
      return <h1>Something went wrong, try refreshing the page.</h1>;
    }

    return <>{this.props.children}</>;
  }
}

export default (Component: FC) => {
  return (props: Props) => (
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  );
};
