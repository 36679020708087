export const NAME_ICONS = {
  HOME: 'Home',
  ADMIN: 'Admin',
  CHARTS: 'Chart',
  BOOK: 'Book',
  PAY: 'Payment',
  HELP: 'Help',
  LOGOUT: 'Logout',
  TICKET: 'Ticket',
  CALENDAR: 'Calendar',
  CAMPING: 'Camping',
  HEART: 'Heart',
  IMGSEARCH: 'ImgSearch',
  PALOPOINT: 'PaloPoint',
  CANCELED: 'Canceled',
  CHECK: 'Check',
  EDIT: 'Edit',
  PALOWANICON: 'Palowanicon',
  STATUSPENDING: 'Statuspending',
  FILTER: 'Filter',
  WINNERS: 'Winners',
  METRIC: 'Metric',
  EVENT: 'Event',
  SETTINGS: 'Settings',
  ENVELOPE: 'Envelope',
};
export const NAME_ICONS_STATUS = {
  TRACKING: 'Seguimiento',
  REJECTED: 'Rechazada',
  APPROVED: 'Aprovada',
};
