export enum AUTHCONST {
  TOKEN = 'token',
  ROL = 'roles',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  ADMIN = 'admin',
  USER = 'user',
  NEW_USER = 'newRegister',
  EMAIL = 'email',
  ACC_POINTS = 'PaloPoints',
  NAME_USER = 'name',
  USER_DETAILS = 'userDetails',
  GET_USER = 'getUser',
}
export const Admin = (action: string) => {
  switch (action) {
    case '1':
      return ['admin'];
    default:
      return ['user'];
  }
};
