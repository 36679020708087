import {FC} from 'react';
import {FormControl, TextFieldProps, TextField} from '@mui/material';
import './OutlinedInput.scss';

const OutlinedInput: FC<TextFieldProps> = props => {
  return (
    <FormControl
      data-testid="FormControl"
      variant="outlined"
      className={props.className}
    >
      <TextField
        data-testid="TextField"
        variant="outlined"
        id={props.id}
        {...props}
        className={''}
      />
    </FormControl>
  );
};

export default OutlinedInput;
