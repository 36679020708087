import {NAME_ICONS} from 'commons/icons/IconConst';
import {existImg} from 'commons/utils';
import Icons from 'components/icons/Icons';
import Loading from 'components/loader/Loader';
import {DinamicCard} from 'models/dinamics';

interface Props {
  cardDinamyc?: DinamicCard;
  yearQ: number;
  editeable?: boolean;
  viewDinamycOnclick: () => void;
  updateButtonOnclick: () => void;
}

const CardDinamic = ({
  cardDinamyc,
  yearQ,
  editeable = true,
  updateButtonOnclick,
  viewDinamycOnclick,
}: Props) => {
  const yearCurrent = new Date().getFullYear();
  const styleDinamic = (dinamica: string) => {
    return dinamica === 'Dinámica' ? 'bg-blue-300' : ' bg-yellow-500';
  };

  const typeDinamic = (data: string): string => {
    switch (data) {
      case '1':
        return 'Reto';
      case '2':
        return 'Dinámica';

      default:
        return 'Dinámica';
    }
  };

  return (
    <>
      <div className=" bg-white w-85 h-36 mb-4 rounded-md flex justify-center items-center shadow-md ">
        {cardDinamyc ? (
          <>
            <div
              onClick={viewDinamycOnclick}
              data-testid="viewDinamyc"
              className="w-44 h-28"
            >
              <div className=" h-14 overflow-auto  ">
                <p className="text-base mb-0.5 font-monseratt font-black">
                  {cardDinamyc.name}
                </p>
              </div>
              <p className=" text-gray-600	font-monserat text-xs font-normal">
                {`${cardDinamyc?.initialDate} - ${cardDinamyc?.finalDate}`}
              </p>
              <div
                className={
                  styleDinamic(typeDinamic(cardDinamyc.typeActivity)) +
                  ' w-20 h-5 rounded-full flex items-center justify-center mt-3 font-bold'
                }
              >
                <p className=" text-xs font-monseratt px-1 font-bold">
                  {typeDinamic(cardDinamyc.typeActivity)}
                </p>
              </div>
            </div>
            <div className="relative w-24 flex justify-center items-center h-24 ml-4 ">
              {editeable ? (
                <>
                  {yearQ >= yearCurrent && (
                    <div
                      onClick={updateButtonOnclick}
                      data-testid="updateButton"
                      className="w-8	h-8 absolute flex hover:bg-gray-200 justify-center cursor-pointer items-center left-14 top-2 rounded-md bg-white "
                    >
                      <Icons nameIcon={NAME_ICONS.EDIT} />
                    </div>
                  )}
                </>
              ) : null}
              <img
                className="w-full max-h-full"
                src={existImg(cardDinamyc!.urlImage!)}
                alt=""
              />
            </div>
          </>
        ) : (
          <Loading loaderStyles="" />
        )}
      </div>
    </>
  );
};

export default CardDinamic;
