import React, {FC} from 'react';
import countries, {Alpha3Code} from 'i18n-iso-countries';
import BlankCard from 'components/BlankCard/BlankCard';
import TabView from 'components/TabView/TabView';
import VacationDaysParamsTable from 'viewComponents/VacationDaysParamsTable';
import HealthcareParamsTable from 'viewComponents/HealthcareParamsTable/HealthcareParamsTable';
import HolidaysParamsTable from 'viewComponents/HolidaysParamsTable/HolidaysParamsTable';
import {useTranslation} from 'react-i18next';

export interface CountryConfigurationProps {
  country: Alpha3Code;
}

const CountryConfiguration: FC<CountryConfigurationProps> = ({country}) => {
  const {t} = useTranslation();

  const items = [
    {
      label: t('leaves.CountryConfiguration.holidays'),
      view: <HolidaysParamsTable />,
    },
    {
      label: t('leaves.CountryConfiguration.healthcare'),
      view: <HealthcareParamsTable />,
    },
    {
      label: t('leaves.CountryConfiguration.vacationDays'),
      view: <VacationDaysParamsTable />,
    },
  ];
  return (
    <div className="mt-4">
      <BlankCard title={`Parámetros para ${countries.getName(country, 'es')}`}>
        <TabView tabs={items} />
      </BlankCard>
    </div>
  );
};

export default CountryConfiguration;
