export const persistLocalStorageUserState = <T>(Key: string, data: T) => {
  localStorage.setItem(Key, JSON.stringify({data}));
};

export const clearLocalStorageUserState = (Key: string) => {
  localStorage.removeItem(Key);
};

export const getLocalStorageUserState = (Key: string) => {
  return JSON.parse(localStorage.getItem(Key) as string);
};
