/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {FC, useState} from 'react';
import {GraphqlCollection} from 'models/graphqlCollection';
import {useGraphqlQuery} from 'hooks/useGraphqlQuery';
import {StickyHeadTableProps} from 'components/StickyHeadTable/StickyHeadTable';
import TableController from '../TableController';
import StickyHeadTable from 'components/StickyHeadTable';
import {DocumentNode} from '@apollo/client';

export type GraphqlQuery = DocumentNode;

type QueryConfiguration = {
  query: GraphqlQuery;
  rootField: string;
  queryVariables: (page: number, size: number) => any;
};

export interface GqlTableControllerProps
  extends Omit<StickyHeadTableProps, 'rows' | 'onPageChange'> {
  queryConfiguration: QueryConfiguration;
  mapData?: (data: any) => any;
  placeholder?: string | JSX.Element;
  pageSize?: number;
  updateTrigger?: string;
}

const GqlTableController: FC<GqlTableControllerProps> = ({
  columns,
  queryConfiguration: {query, rootField, queryVariables},
  mapData,
  placeholder,
  pageSize = 10,
  updateTrigger,
  ...other
}) => {
  const initialState: GraphqlCollection<any> = {
    currentPage: 0,
    hasNextPage: false,
    pageSize,
    rows: [],
    total: 0,
  };

  const [isLoading, setIsLoading] = useState(false);

  const {fetchData} = useGraphqlQuery(initialState, query, rootField);

  const fetchTableData = async (page: number, pageSize: number) => {
    setIsLoading(true);
    return new Promise(resolve => {
      fetchData(queryVariables(page, pageSize)).then((data: any) => {
        const result = data.rows.map((row: any) => {
          row = row || {};
          return mapData ? mapData(row) : row;
        });
        const collection = {
          // leave request history requires this shape of data.
          data: {
            data: result,
            totalElements: data.total,
          },
        };
        resolve(collection);
      });
    }).finally(() => setIsLoading(false));
  };
  return (
    <div className="w-full flex flex-col flex-wrap pt-4">
      <TableController
        hideLoader
        updateTrigger={updateTrigger}
        pageSize={pageSize}
        dataProvider={(page, pageSize) => fetchTableData(page, pageSize)}
      >
        {({tableData, onPageChange}) => (
          <>
            <StickyHeadTable
              {...other}
              isLoading={isLoading}
              rows={tableData?.data}
              columns={columns}
              onPageChange={onPageChange}
              totalElements={tableData?.totalElements}
              placeholder={placeholder}
            />
          </>
        )}
      </TableController>
    </div>
  );
};
export default GqlTableController;
