import {useLazyQuery} from '@apollo/client';
import {AUTHCONST} from 'commons/auth/authConst';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import ExpirationDay from 'components/alerts/ExpirationDay';
import CardDinamicPalowan from 'components/card/CardDinamicasPalowan';
import ContainerModal from 'components/container/ContainerModal';
import Loading from 'components/loader/Loader';
import ModalDetailParticipate from 'components/modals/ModalDetailParticipate';
import ModalParticipatePalowan from 'components/modals/ModalParticipate';
import TabView from 'components/tabs/TabsTable';
import {GET_CURRENT_DINAMYCS, GET_EXPIRATION} from 'graphql/palowan/Querys';
import {DinamicCard} from 'models/dinamics';
import {ExpiratioI} from 'models/login';
import {useEffect, useState} from 'react';

const HomePalowan = () => {
  const [execCurrentDinamycs, {data}] = useLazyQuery(GET_CURRENT_DINAMYCS);
  const [execGetExpiration] = useLazyQuery(GET_EXPIRATION);
  const email = getLocalStorageUserState(AUTHCONST.EMAIL)
    ? getLocalStorageUserState(AUTHCONST.EMAIL)
    : {data: ''};
  const [viewModalParticipate, setviewModalParticipate] = useState(false);
  const [viewModalDetailParticipate, setviewModalDetailParticipate] =
    useState(false);
  const [idDinamyc, setidDinamyc] = useState<string>('');
  const [expirationDay, setexpirationDay] = useState<ExpiratioI>({
    finalDate: '',
    number: '',
  });
  const [indexView, setindexView] = useState<number>(0);

  useEffect(() => {
    execCurrentDinamycs({
      variables: {limit: 100, page: 1, email: email.data},
    }).then(data =>
      execGetExpiration({
        variables: {
          year: new Date().getFullYear(),
          FilterId: parseInt(
            data.data.DinamycPalowanCurrent.activities[0].quarterId
          ),
        },
      })
        .then(date => date.data?.QuaterListFilter[0])
        .then(data => setexpirationDay(data))
    );
  }, []);
  const closeModal = () => {
    setviewModalParticipate(false);
    setviewModalDetailParticipate(false);
  };
  const handleClickParticipate = (id: string, participate: boolean) => {
    setidDinamyc(id);
    participate
      ? setviewModalDetailParticipate(true)
      : setviewModalParticipate(true);
  };
  const viewComponent = (id: number) => {
    setindexView(id);
  };
  const whoTab = [
    {
      id: 1,
      data: 'En curso',
    },
    {
      id: 2,
      data: 'Participadas',
    },
  ];
  const modalParticipate = (
    <ContainerModal>
      <>
        <ModalParticipatePalowan
          idDinamyc={idDinamyc}
          CloseModalClick={closeModal}
        />
      </>
    </ContainerModal>
  );
  const modalDetailParticipate = (
    <ContainerModal>
      <>
        <ModalDetailParticipate
          idDinamyc={idDinamyc}
          CloseModalClick={closeModal}
        />
      </>
    </ContainerModal>
  );

  const componentParticipate = (
    <div className="flex justify-center flex-wrap">
      {data &&
        data.DinamycPalowanRegistered.activities.map(
          (item: DinamicCard, index: number) => (
            <div key={index} className="mb-5 mx-2">
              <CardDinamicPalowan
                nameButton="DETALLE"
                onclickParticipate={() =>
                  handleClickParticipate(item.id!, true)
                }
                key={index}
                cardDinamyc={item}
              />
            </div>
          )
        )}
    </div>
  );
  const componentCurrent = (
    <div className=" w-full flex justify-center flex-wrap">
      {data &&
        data.DinamycPalowanCurrent.activities.map(
          (item: DinamicCard, index: number) => (
            <div key={index} className="mb-5 mx-2">
              <CardDinamicPalowan
                nameButton="PARTICIPAR"
                onclickParticipate={() =>
                  handleClickParticipate(item.id!, false)
                }
                onclickSeeMore={() => handleClickParticipate(item.id!, false)}
                key={index}
                cardDinamyc={item}
              />
            </div>
          )
        )}
    </div>
  );

  return (
    <>
      {viewModalParticipate ? modalParticipate : null}
      {viewModalDetailParticipate ? modalDetailParticipate : null}
      {data ? (
        <>
          {!!expirationDay?.finalDate && <ExpirationDay data={expirationDay} />}
          <TabView onclickTab={viewComponent} tabs={whoTab} />
          {indexView === 0 && componentCurrent}
          {indexView === 1 && componentParticipate}
        </>
      ) : (
        <Loading loaderStyles="" />
      )}
    </>
  );
};

export default HomePalowan;
