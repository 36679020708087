/* eslint-disable @typescript-eslint/no-explicit-any */
import {FC} from 'react';
import List from 'components/List/List';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {GET_LEAVE_REQUEST_BY_APPROVAL} from 'graphql/absences/Querys';
import {useTranslation} from 'react-i18next';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import './PendingApprovalsList.scss';
import {Link} from 'react-router-dom';
import {Button} from '@mui/material';
import {
  LEAVE_STATUS_IN_PROCESS,
  LEAVE_STATUS_PENDING,
} from 'models/leaveRequest';

interface PendingApprovalsListProps {
  userId: string | undefined;
}

const PendingApprovalsList: FC<PendingApprovalsListProps> = ({userId}) => {
  const {t} = useTranslation();
  const initialState = {
    total: 0,
    pageSize: 0,
    hasNextPage: false,
    currentPage: 0,
    rows: [],
  };
  return (
    <GqlQueryController
      initialState={initialState}
      QUERY={GET_LEAVE_REQUEST_BY_APPROVAL}
      variables={{
        approvalId: userId,
        pageParams: {page: 1, size: 20},
        statusLeave: [LEAVE_STATUS_PENDING, LEAVE_STATUS_IN_PROCESS],
        statusApproval: 'PENDING',
        onlyInOrder: true,
        isAdmin: false,
      }}
      rootField="leaveRequestsByApproval"
    >
      {({data: pendingApprovals}) => (
        <div className="flex flex-col justify-between h-100">
          <List
            data-testid="PendingApprovalsList"
            rightIcon={<ArrowForwardIosIcon />}
            placeholder={t('common.empty_list')}
            items={pendingApprovals.rows.map((item: any) => ({
              primaryText: item.userName,
              secondaryText: `${item.startDate} - ${item.endDate}`,
              linkTo: `/private/palowan/leaves/request/detail/${item.id}`,
            }))}
          />
          <div className="actions flex justify-end">
            <Link to={`/private/management/requests/${userId}`}>
              <Button role="button" variant="text">
                Ver todos
              </Button>
            </Link>
          </div>
        </div>
      )}
    </GqlQueryController>
  );
};

export default PendingApprovalsList;
