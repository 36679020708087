/* eslint-disable @typescript-eslint/no-explicit-any */
import {useLazyQuery} from '@apollo/client';
import {useEffect} from 'react';
import {existImg} from 'components/card/CardDinamicasPalowan';
import {GET_DINAMYC_ID} from 'graphql/palowan/Querys';
import Detail from 'components/detail/Detail';
import {Comments} from 'components/comments/Comments';
import Loading from 'components/loader/Loader';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import {AUTHCONST} from 'commons/auth/authConst';
import './ModalDetailParticipate.css';

export interface formEditPalowanProps {
  idDinamyc: string;
  CloseModalClick: () => void;
}
export const ModalDetailParticipate: React.FC<formEditPalowanProps> = ({
  idDinamyc,
  CloseModalClick,
}) => {
  const email = getLocalStorageUserState(AUTHCONST.EMAIL)
    ? getLocalStorageUserState(AUTHCONST.EMAIL)
    : {data: ''};
  const [execCurrentDinamycs, {data}] = useLazyQuery(GET_DINAMYC_ID);

  useEffect(() => {
    const parseId = parseInt(idDinamyc);

    execCurrentDinamycs({variables: {idDinamyc: parseId, email: email.data}});
  }, []);

  return (
    <div className="ModalDetailParticipate absolute z-50 w-full h-screen bg-black bg-opacity-30  flex justify-end">
      <div
        className=" w-full md:w-3/5 h-full z-100 bg-black bg-opacity-30"
        onClick={CloseModalClick}
      ></div>
      <div className=" w-full md:w-2/5 h-full py-6 px-10 bg-white">
        {data ? (
          <div className=" w-full h-full overflow-auto  pt-11">
            <Detail CloseClick={CloseModalClick} data={data} />
            <div className=" w-full flex justify-center items-center">
              <div className=" mt-5 w-120  flex justify-center items-center bg-transparent">
                <img
                  className=" w-full "
                  src={existImg(data.FindDinamycByIdPalowan.activity.urlImage)}
                  alt="ImgDummy"
                />
              </div>
            </div>

            <div className=" w-full mt-9 h-10  flex justify-between items-center  ">
              <div className=" ml-7 w-1/2 h-10">
                <p className=" text-base text-gray-400 font-monseratt font-semibold"></p>
              </div>
              <div className="w-1/2 h-10 flex justify-end">
                {data.FindDinamycByIdPalowan.activity.isValidated && (
                  <p className=" font-monseratt font-semibold flex justify-center items-center text-tab-color-green text-xs">
                    <span className="ml-2 approved">Aprobado</span>
                  </p>
                )}
              </div>
            </div>

            <div className=" w-full font-monseratt text-black font-medium text-base  mt-4 px-10">
              <p>Descripción</p>
            </div>
            <div className=" w-full font-monseratt text-black font-light text-base mt-3 w-120 h-40 pl-10">
              <p>{data.FindDinamycByIdPalowan.activity.description}</p>
            </div>
            <div>
              <Comments idDinamycUser={idDinamyc} onClick={CloseModalClick} />
            </div>
          </div>
        ) : (
          <Loading loaderStyles="bg-gray-300" />
        )}
      </div>
    </div>
  );
};
export default ModalDetailParticipate;
