/* eslint-disable @typescript-eslint/no-explicit-any */
import {useNavigate} from 'react-router-dom';
import img from 'commons/img/iconMicrosoft.png';
import logofooter from 'commons/img/footerLogin.png';
import {gql, useMutation} from '@apollo/client';
import {useContext, useEffect, useState} from 'react';
import Loading from 'components/loader/Loader';
import {persistLocalStorageUserState} from 'commons/localStorage.utilities';
import {AUTHCONST} from 'commons/auth/authConst';
import {LoginI} from 'models/login';
import {OAuthProvider, signInWithPopup} from 'firebase/auth';
import {auth} from 'commons/clients/firebaseClient';
import useUser from 'hooks/useUser';
import {useLogger} from 'hooks/useLogger';
import {authContext} from 'contextApi/context/auth';

const providerAuthMicrosoft = new OAuthProvider('microsoft.com');
const LOGIN = gql`
  mutation Mutation($email: String!) {
    login(email: $email) {
      user {
        palowanID
        name
        email
        roleID
        palopoints
        isRegistered
      }
    }
  }
`;

function Login() {
  const [user, setUser] = useState<any>();
  const [data, setData] = useState<any>();
  const [failAuth, setfailAuth] = useState<boolean>(false);
  const [execLogin] = useMutation(LOGIN);
  const [loading, setloading] = useState<boolean>(false);
  const {dispatch} = useContext(authContext);
  const {fetchUser} = useUser();
  const logger = useLogger('Login');

  const navigate = useNavigate();

  const signInGoogle = async (e: any) => {
    e.preventDefault();
    setloading(true);
    await singUpMicrosoft();
  };

  useEffect(() => {
    if (data) {
      if (data.data.login.user) {
        validateAuthorized(data.data.login.user);
      }
    }
  }, [data]);

  const onLogin = (data: any) => {
    dispatch({
      type: AUTHCONST.LOGIN,
      payload: {
        payload: user,
        rolId: data?.data?.login?.user?.roleID,
      },
    });
    return data;
  };

  const fetchData = async (loginData: any) => {
    const _data = loginData.data.login.user;
    const response = await fetchUser(_data.email || '');
    if (response) {
      dispatch({
        type: AUTHCONST.GET_USER,
        payload: {
          payload: response,
        },
      });
      return loginData;
    } else {
      setloading(false);
    }
  };

  useEffect(() => {
    if (user) {
      execLogin({variables: {email: user.email}})
        .then(onLogin)
        .then(fetchData)
        .then(resp => {
          logger.debug('setData', resp);
          setData(resp);
        });
    }
  }, [user]);

  const singUpMicrosoft = async () => {
    signInWithPopup(auth, providerAuthMicrosoft)
      .then(result => {
        if (result.user.email) {
          if (result.user.email.length > 0) {
            setUser(result.user);
          }
        }
      })
      .catch(error => {
        return error;
      });
  };

  const logOut = () => {
    dispatch({type: AUTHCONST.LOGOUT});
  };

  const unAuthorized = () => {
    logOut();
    setfailAuth(true);
    setTimeout(() => {
      return setfailAuth(false);
    }, 10000);
  };
  const Authorized = (paloPoint: string, name: string) => {
    persistLocalStorageUserState(AUTHCONST.ACC_POINTS, paloPoint);
    persistLocalStorageUserState(AUTHCONST.NAME_USER, name);
    navigate('/');
  };
  const validateAuthorized = (data: LoginI) => {
    setloading(false);
    return data.isRegistered
      ? Authorized(data.palopoints!, data.name!)
      : unAuthorized();
  };

  return (
    <>
      <div className=" bg-white">
        {failAuth ? (
          <div className=" flex justify-end w-full">
            <div className=" absolute p-2 mt-20 mr-16  shadow-3-89 bg-pink-Error px-3 py-4 m-4 rounded w-96 text-center">
              <div className=" w-56 mx-12">
                <p className="text-black font-monseratt text-sm font-medium">
                  Cuenta inválida{' '}
                </p>
                <p className="text-black font-monseratt mt-1 text-xs font-light">
                  Esta cuenta tiene acceso restringido, intenta con otra cuenta
                </p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="h-screen grid grid-rows-6  ">
          {loading ? <Loading loaderStyles="absolute bg-gray-300" /> : null}
          <div className="row-span-5 flex flex-col items-center justify-center ">
            <div className="py-4">
              <p className=" text-7xl font-extralight text-black ml-4">
                ¡Bienvenidx!
              </p>
            </div>
            <div className=" mb-11">
              <p className=" text-black font-extralight  ml-4 text-lg">
                El inicio de sesión es a través de su cuenta de PALO IT.
              </p>
            </div>
            <div className=" bg-gray-800 rounded-md w-80 hover:bg-gray-600">
              <button
                className="w-full py-2 shadow-lg flex items-center space-x-4 "
                onClick={signInGoogle}
              >
                <img src={img} alt="" className=" w-6 h-6 m-1 ml-4" />
                <p className=" text-white ml-4 text-lg">
                  Iniciar sesión con Microsoft
                </p>
              </button>
            </div>
          </div>
          <div className="w-full flex justify-center ">
            <img src={logofooter} alt="" className=" w-full" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
