/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC, useContext} from 'react';
import Modal, {ModalProps} from 'components/Modal/Modal';
import {Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {UPDATE_APPROVAL_STATUS} from 'graphql/absences/Mutations';
import {LeaveStatus} from 'models/leaveRequest';
import {alertContext} from 'contextApi/context/error';
import './DeleteParamModal.scss';

interface ParamItem {
  label: string;
  value: string | unknown;
}

interface DeleteParamModalProps
  extends Omit<ModalProps, 'children' | 'title' | 'description'> {
  children?: Children;
  status: LeaveStatus;
  paramId: string;
  values: ParamItem[];
}

const DeleteParamModal: FC<DeleteParamModalProps> = ({
  status,
  paramId,
  onClose,
  values,
  ...other
}) => {
  const {t} = useTranslation();
  const [mutation] = useMutation(UPDATE_APPROVAL_STATUS);
  const {dispatch} = useContext(alertContext);

  const onSubmit = () => {
    const data = {
      paramId,
      status: status,
    };
    mutation({variables: data})
      .then(data => {
        const updateApprovalStatus = data?.data?.updateApprovalStatus;
        if (updateApprovalStatus.id) {
          let message = '';
          switch (status) {
            case 'APPROVED':
              message = t('leaves.DeleteParamModal.aproved');
              break;
            case 'REJECTED':
              message = t('leaves.DeleteParamModal.rejected');
              break;
            default:
              break;
          }
          dispatch({
            type: 'show',
            payload: {level: 'success', message: message},
          });
          window.location.reload();
        }
      })
      .catch(error => {
        dispatch({
          type: 'show',
          payload: {level: 'error', message: error.message},
        });
      });
  };

  return (
    <Modal
      small
      className="DeleteParamModal"
      title={t('leaves.DeleteParamModal.title')}
      description={t('leaves.DeleteParamModal.description')}
      onClose={onClose}
      {...other}
    >
      <>
        {values.map((item, index) => (
          <div key={index} className="flex flex-row items-center mb-1">
            <Typography variant="body2">{item.label}:</Typography>
            <Typography variant="body1">{item.value}</Typography>
          </div>
        ))}
        <div className="flex justify-end">
          <Button
            variant="outlined"
            onClick={event => onClose && onClose(event, 'backdropClick')}
          >
            {t('leaves.DeleteParamModal.cancel')}
          </Button>
          <Button onClick={onSubmit} variant="contained">
            {t('leaves.DeleteParamModal.submit')}
          </Button>
        </div>
      </>
    </Modal>
  );
};

export default DeleteParamModal;
