/* eslint-disable @typescript-eslint/no-explicit-any */
import {useMutation} from '@apollo/client';
import cx from 'classnames';
import Button from 'components/button/Button';
import Loading from 'components/loader/Loader';
import {VALIDATE_EVIDENCE} from 'graphql/admin/Mutation';
import {GET_DINAMYC} from 'graphql/admin/Querys';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

export interface RejectProps {
  /**
   * title form
   */
  title?: string;
  /*
  sub title form
  */
  evidenceId: number;
  /*
  sub title form
  */
  idDinamyc?: string;

  onClickClose: () => void;
}
export const ModalRejected: React.FC<RejectProps> = ({
  title,
  evidenceId,
  idDinamyc,
  onClickClose,
}) => {
  const [execParticipate] = useMutation(VALIDATE_EVIDENCE, {
    refetchQueries: [
      {
        query: GET_DINAMYC,
        variables: {id: parseInt(idDinamyc!)},
      },
    ],
  });

  const [loading, setloading] = useState<boolean>(false);

  const contTop = cx({
    'w-full bg-white px-8 py-8 rounded-lg': true,
  });

  const contTitle = cx({
    'w-full flex items-start justify-between mb-2': true,
  });

  const btnClose = cx({
    'bg-transparent': true,
  });
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm();

  const onSubmit = async (data: any) => {
    setloading(true);
    execParticipate({
      variables: {
        approved: false,
        evidenceId: evidenceId,
        reason: data.Comment,
      },
    }).then(() => {
      onClickClose();
      setloading(false);
    });
  };
  return (
    <div className="absolute flex justify-center items-center z-50 w-full h-screen bg-black bg-opacity-30 ">
      {loading ? <Loading loaderStyles="absolute bg-gray-300" /> : null}
      <div className=" w-128 h-96  bg-white rounded-lg ">
        <div className={contTop}>
          <div className={contTitle}>
            <button onClick={onClickClose} className={btnClose}>
              X
            </button>
          </div>
          <div className=" w-full h-full mt-5 overflow-auto ">
            <div className=" w-full flex justify-center items-center ">
              <p className=" font-monseratt text-base font-medium">{title}</p>
            </div>
            <div className=" w-full mt-7">
              <label className="block mb-2 text-sm font-semibold font-monseratt text-gray-500 dark:text-white">
                Motivo
              </label>
              <textarea
                id="message"
                {...register('Comment', {required: true})}
                className="block p-2.5 w-full h-28 text-base font-monseratt font-normal text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 "
                placeholder="Escribe aquí el motivo"
                data-testid="comment-input"
              ></textarea>
              {errors.Comment && (
                <span className=" text-red-400 text-sm font-monseratt ">
                  El motivo es requerido
                </span>
              )}
            </div>
          </div>
        </div>

        <div className=" w-full h-21 flex justify-end items-center bg-gray-modal-footer rounded-b-lg">
          <div className=" w-36 h-9 hover:opacity-30 ">
            <Button
              onClickButton={onClickClose}
              style="primaryTransparent"
              text="Cancelar"
            />
          </div>
          <div className=" w-36 h-9 mx-4 hover:opacity-75">
            <Button
              onClickButton={handleSubmit(onSubmit)}
              style="primarySolid"
              text="Enviar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalRejected;
