import {IMG_DEFAULT} from './img/imgBase64';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const compose = (...fns: any[]) =>
  fns.reduce(
    (a, b) =>
      (...args: any) =>
        a(b(...args)),
    (arg: any) => arg
  );
export const existImg = (img: string) => {
  return img.length > 0 ? img : IMG_DEFAULT;
};
export const Options = [
  {id: 1, name: 'Reto'},
  {id: 2, name: 'Dinámica'},
];
export const OptionDinamica = [{id: 2, name: 'Dinamica'}];
