/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';

interface HandleSuccessProps {
  data: any;
}

const handleSuccess = ({data}: HandleSuccessProps) => {
  return data;
};

const handleErrors = (error: any) => {
  throw Error(error.statusText);
};

export const get = (url: string) => {
  return axios.get(url).then(handleSuccess).catch(handleErrors);
};
