import React, {ForwardRefRenderFunction} from 'react';
import {Card, CardContent, Typography} from '@mui/material';
import './BlankCard.scss';

export interface BlankCardProps {
  title?: string | JSX.Element;
  description?: string | JSX.Element;
  className?: string;
  action?: JSX.Element[] | JSX.Element;
  // eslint-disable-next-line
  wrapperProps?: any;
  children?: JSX.Element | JSX.Element[] | React.ReactNode;
}

const BlankCard: ForwardRefRenderFunction<HTMLDivElement, BlankCardProps> = (
  {title, description, children, action, className, wrapperProps, ...other},
  ref
) => {
  return (
    <Card {...other} className={`BlankCard ${className}`} ref={ref}>
      <CardContent>
        {title && <Typography variant="h5">{title}</Typography>}
        {description && <Typography variant="body1">{description}</Typography>}
        <div {...wrapperProps}>{children}</div>
        {action && <div className="actions flex justify-end">{action}</div>}
      </CardContent>
    </Card>
  );
};

export default React.forwardRef(BlankCard);
