import {gql} from '@apollo/client';

export const GET_CURRENT_DINAMYCS = gql`
  query Query($limit: Int!, $page: Int!, $email: String!) {
    DinamycPalowanCurrent(limit: $limit, page: $page, email: $email) {
      activities {
        id
        quarterId
        name
        description
        typeActivity
        palopoints
        initialDate
        finalDate
        urlImage
      }
    }
    DinamycPalowanRegistered(limit: $limit, page: $page, email: $email) {
      activities {
        id
        quarterId
        name
        description
        palopoints
        typeActivity
        initialDate
        finalDate
        urlImage
      }
    }
  }
`;
export const GET_EXPIRATION = gql`
  query Query($year: Int!, $FilterId: Int!) {
    QuaterListFilter(year: $year, id: $FilterId) {
      finalDate
      number
    }
  }
`;
export const GET_DINAMYC_ID = gql`
  query Query($idDinamyc: Int!, $email: String!) {
    FindDinamycByIdPalowan(idDinamyc: $idDinamyc, email: $email) {
      activity {
        id
        quarterId
        name
        description
        palopoints
        typeActivity
        initialDate
        cantParticipants
        finalDate
        urlImage
        isParticipant
        isValidated
      }
    }
  }
`;
export const GET_SHOW_COMMENT = gql`
  query Query($activityId: Int!, $email: String!) {
    ShowOneIdComment(activityId: $activityId, email: $email) {
      comments {
        palowanPicture
        palowanName
        palowanID
        comment
        ID
      }
    }
  }
`;
