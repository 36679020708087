/* eslint-disable node/no-unpublished-import */
import {createServer, Response as MirageResponse} from 'miragejs';

/**
 * Create a MirageJS server to mock REST api. See https://miragejs.com/ for documentation.
 * @returns MirageJS server reference.
 */

const mockServer = () => {
  return createServer({
    routes() {
      // Any route not defined should go to the server
      this.passthrough();
      // Pass external domains
      this.passthrough('https://app.launchdarkly.com/**');
    },
  });
};

export default mockServer;

export const Response = MirageResponse;
