import React, {FC} from 'react';
import defaultImage from 'assets/empty_img.svg';
import {Typography} from '@mui/material';
import cx from 'classnames';
export type ImageSrc = string;
export type ImageComponent = JSX.Element;

export interface EmptyStateProps {
  message: string;
  Image?: ImageComponent | ImageSrc;
  childrenAlignment?: 'start' | 'end' | 'center';
  children?: JSX.Element | JSX.Element[];
}

const EmptyState: FC<EmptyStateProps> = ({
  message,
  Image = defaultImage,
  childrenAlignment = 'center',
  children,
}) => {
  const imageClasses = cx('flex mt-6', {
    'justify-center': childrenAlignment === 'center',
    'justify-end': childrenAlignment === 'end',
    'justify-start': childrenAlignment === 'start',
  });
  const getImageComponent = (): JSX.Element => {
    if (typeof Image === 'string') {
      return <img src={Image} alt="Empty state" />;
    }
    return Image;
  };

  return (
    <div className="flex flex-col p-4">
      <div className="flex justify-center mb-6">{getImageComponent()}</div>
      <Typography variant="body2" className="text-center">
        {message}
      </Typography>
      {children && <div className={imageClasses}>{children}</div>}
    </div>
  );
};

export default EmptyState;
