import {Reducer} from 'react';
import Configuration, {
  PublicHoliday,
  VacationRanges,
} from 'models/configuration';
import {Alpha3Code} from 'i18n-iso-countries';
import {parseDate} from 'commons/dates';

export interface CountryConfiguration {
  [key: string]: Configuration;
}

export type AdminTag = 'Holidays' | 'Vacations' | 'Incapacity';

export interface AdminState {
  configuration: CountryConfiguration;
  isLoading: boolean;
  currentCountry?: Alpha3Code;
  currentTag?: AdminTag; // This is the key of the configuration object ej: vacations, holidays, incapacity
}

export enum AdminActionType {
  SetConfiguration = 'setConfiguration',
  SetCurrentCountry = 'setCurrentCountry',
  AddHoliday = 'addHoliday',
  UpdateHoliday = 'updateHoliday',
  DeleteHoliday = 'deleteHoliday',
  UpdateMaternity = 'updateMaternity',
  UpdatePaternity = 'updatePaternity',
  AddVacationRule = 'addVacationRule',
  UpdateVacationRule = 'updateVacationRule',
  DeleteVacationRule = 'deleteVacationRule',
}

type Payload =
  | VacationRanges
  | CountryConfiguration
  | Alpha3Code
  | PublicHoliday
  | number;

export interface AdminAction {
  type: AdminActionType;
  payload: Payload;
}

export const adminReducer: Reducer<AdminState, AdminAction> = (
  state: AdminState,
  action: AdminAction
): AdminState => {
  switch (action.type) {
    case AdminActionType.SetConfiguration: {
      const payload = action.payload as CountryConfiguration;
      return {...state, configuration: payload};
    }
    case AdminActionType.SetCurrentCountry: {
      const payload = action.payload as Alpha3Code;
      return {...state, currentCountry: payload};
    }
    case AdminActionType.AddHoliday:
    case AdminActionType.UpdateHoliday:
    case AdminActionType.DeleteHoliday: {
      return {
        ...state,
        ...manageHolidays(state, action),
      };
    }
    case AdminActionType.UpdateMaternity:
    case AdminActionType.UpdatePaternity: {
      return {
        ...state,
        ...manageInability(state, action),
      };
    }
    case AdminActionType.AddVacationRule:
    case AdminActionType.UpdateVacationRule:
    case AdminActionType.DeleteVacationRule:
      return {
        ...state,
        ...manageVacations(state, action),
      };
    default:
      return state;
  }
};

/**
 * This function manages the holidays in the admin state based on the action type.
 * It can add, update, or delete a holiday.
 *
 * @param {AdminState} state - The current state of the admin.
 * @param {AdminAction} action - The action to be performed.
 * @returns {AdminState} - The new state of the admin after performing the action.
 */
const manageHolidays = (state: AdminState, action: AdminAction): AdminState => {
  const {date, name} = action.payload as PublicHoliday;
  const _date = parseDate(date);
  const year = _date.getFullYear();
  const country = state.configuration[state.currentCountry!];
  const holidays = country.publicHolidays[year] || [];
  if (action.type === AdminActionType.AddHoliday) {
    holidays.push({date, name});
  } else {
    const index = holidays.findIndex(
      (holiday: PublicHoliday) => holiday.name !== name
    );
    if (action.type === AdminActionType.DeleteHoliday) {
      holidays.splice(index, 1);
    } else if (action.type === AdminActionType.UpdateHoliday) {
      holidays[index] = {date, name};
    }
  }
  country.publicHolidays[year] = holidays;
  return {
    ...state,
    configuration: {
      ...state.configuration,
      [state.currentCountry!]: country,
    },
  };
};

/**
 * This function manages the inability days (maternity or paternity) in the admin state based on the action type.
 * It can update the maternity or paternity days.
 *
 * @param {AdminState} state - The current state of the admin.
 * @param {AdminAction} action - The action to be performed.
 * @returns {AdminState} - The new state of the admin after performing the action.
 */
const manageInability = (
  state: AdminState,
  action: AdminAction
): AdminState => {
  const numberDays = action.payload as number;
  const country = state.configuration[state.currentCountry!];

  if (action.type === AdminActionType.UpdateMaternity) {
    country.vacationRules.maternity = numberDays;
  } else {
    country.vacationRules.paternity = numberDays;
  }

  return {
    ...state,
    configuration: {
      ...state.configuration,
      [state.currentCountry!]: country,
    },
  };
};

/**
 * This function manages the vacation rules in the admin state based on the action type.
 * It can add, update, or delete a vacation rule.
 *
 * @param {AdminState} state - The current state of the admin.
 * @param {AdminAction} action - The action to be performed.
 * @returns {AdminState} - The new state of the admin after performing the action.
 */
const manageVacations = (
  state: AdminState,
  action: AdminAction
): AdminState => {
  const {vacationDays, yearThreshold} = action.payload as VacationRanges;
  const country = state.configuration[state.currentCountry!];
  if (action.type === AdminActionType.AddVacationRule) {
    country.vacationRules.vacationRanges.push({vacationDays, yearThreshold});
  } else {
    const index = country.vacationRules.vacationRanges.findIndex(
      (range: VacationRanges) => range.vacationDays !== vacationDays
    );
    if (action.type === AdminActionType.DeleteVacationRule) {
      country.vacationRules.vacationRanges.splice(index, 1);
    } else if (action.type === AdminActionType.UpdateVacationRule) {
      country.vacationRules.vacationRanges[index] = {
        vacationDays,
        yearThreshold,
      };
    }
  }
  return {
    ...state,
    configuration: {
      ...state.configuration,
      [state.currentCountry!]: country,
    },
  };
};
