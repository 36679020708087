import {useLocation, Link} from 'react-router-dom';
import {ReactComponent as Logo} from 'assets/logo.svg';
import {Tab, Tabs} from '@mui/material';
import RoleGuard from 'commons/guards/roleGuard';
import {useTranslation} from 'react-i18next';
import './Menu.scss';

interface DataI {
  ItemMenu: JSX.Element;
}

interface Props {
  Menu: DataI[];
}

const Menu = ({Menu}: Props) => {
  const {t} = useTranslation();
  const location = useLocation();
  const {pathname} = location;
  const lastSegment = location.pathname.split('/').pop();
  const menuItems = [
    {
      label: t('Menu.dashboard'),
      path: '/private/palowan/leaves/dashboard',
      keyword: 'dashboard',
    },
    {
      label: t('Menu.leaves'),
      path: '/private/palowan/leaves/my-leaves',
      keyword: 'leaves',
    },
    {
      label: t('Menu.leavesManagement'),
      path: '/private/management/additional-leave',
      keyword: 'management',
      roles: {
        hideForPalowan: true,
      },
    },
    {
      label: t('Menu.paloPoints'),
      path: '/private/palowan/palopoints',
      keyword: 'palopoints',
    },
  ];

  const getSelectedTabIndex = () => {
    const selectedIndex = menuItems.findIndex(
      item => pathname.indexOf(item.keyword) > -1
    );
    return selectedIndex === -1 ? 0 : selectedIndex;
  };

  return (
    <nav className="Menu flex flex-wrap items-center justify-between px-4 py-3 bg-white">
      <div className="px-4 flex flex-1 items-center justify-between">
        <>
          <div className="Logo w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Logo className=" w-44 h-9 leading-relaxed inline-block mr-4 whitespace-nowrap" />
          </div>
          <div className="pathname w-full relative flex-row justify-between lg:w-auto lg:static lg:block lg:justify-start">
            {lastSegment}
          </div>
        </>
        <div className={'lg:flex flex-grow items-center'}>
          <Tabs value={getSelectedTabIndex()}>
            {menuItems.map((item, index) => (
              <RoleGuard key={index} {...item.roles}>
                <Link to={item.path} className="nav-link">
                  <Tab label={<div>{item.label}</div>} />
                </Link>
              </RoleGuard>
            ))}
          </Tabs>
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            {Menu.map((menuItem, index) => (
              <li key={index} className="nav-item">
                {menuItem.ItemMenu}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
