import React from 'react';
import './quarterCard.css';
import cx from 'classnames';
import downlike from 'assets/downlike.svg';
import Button from 'components/button/Button';
import ItemListWithImage from 'components/itemListWithImage/ItemListWithImage';
import {Activity} from 'models/quaters';

export interface QuarterCardProps {
  /**
   * Quarter number
   */
  quarter?: string;
  /*
   *Quarter date
   */
  date?: string;
  year: number;
  /*
   *Quarter date
   */
  previewDimanycs?: Activity[];
  /*
   *Quarter date
   */
  onclickInto: () => void;
}
export const QuarterCard: React.FC<QuarterCardProps> = ({
  quarter,
  date,
  previewDimanycs,
  year,
  onclickInto,
}) => {
  const contTop = cx({
    'w-full py-4 flex items-center justify-between border-gray-300 border-b':
      true,
  });

  const contImg = cx({
    'w-10 h-10 rounded-full bg-green-400 flex items-center justify-center mr-4':
      true,
  });
  const txtImage = cx({
    'm-0 text-lg text-white': true,
  });
  const txtImageNumber = cx({
    'text-xs': true,
  });
  const contTitle = cx({
    'w-auto': true,
  });
  const txtOrange = cx({
    'text-orange-dinamicas text-base font-monseratt font-medium': true,
  });
  const conBottom = cx({
    'w-full py-4 h-52 overflow-auto  ': true,
  });
  return (
    <div className=" w-full md:w-128 h-80 md:h-72  px-4 rounded-lg  border-gray-300 border bg-white">
      <div className={contTop}>
        <div className=" w-4/5 md:w-3/5 flex items-center justify-left">
          <div className={contImg}>
            <p className={txtImage}>
              Q<span className={txtImageNumber}>{quarter}</span>
            </p>
          </div>
          <div className={contTitle}>
            <p className="w-auto m-0 text-base font-monseratt font-medium">
              Quarter {quarter} · <span className={txtOrange}>Dinámicas</span>
            </p>
            <p className=" mt-1 font-monseratt font-light text-xs "> {date} </p>
          </div>
        </div>
        <div className="w-1/5 md:w-2/5 flex items-center justify-end">
          <div className=" w-24 h-9">
            {year! > 2021 && (
              <Button
                style="primarySolid"
                text="Entrar"
                onClickButton={onclickInto}
              />
            )}
          </div>
        </div>
      </div>
      {previewDimanycs && previewDimanycs.length > 0 ? (
        <div className={conBottom}>
          {previewDimanycs.map((item, index) => {
            return (
              <ItemListWithImage
                key={index}
                urlImage={item.urlImage}
                name={item.name}
                description={item.description}
                info={item.palopoints}
                participants={item.participants}
              />
            );
          })}
        </div>
      ) : (
        <div className="w-full">
          <div className="flex justify-center pt-10">
            <img src={downlike} alt="downlike" />
          </div>
          <div className="flex justify-center pt-8 font-monseratt font-light">
            <p>Todavía no has añadido ninguna dinámica</p>
          </div>
        </div>
      )}
    </div>
  );
};
export default QuarterCard;
