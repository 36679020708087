import {ReactComponent as Tracking} from 'assets/IconSvgEnCurso.svg';
import {ReactComponent as Reject} from 'assets/IconSvgReject.svg';
import {ReactComponent as Approved} from 'assets/IconSvgApproved.svg';

import {NAME_ICONS_STATUS} from 'commons/icons/IconConst';

interface Props {
  status: string;
}
const IconsStatus = ({status}: Props) => {
  switch (status) {
    case NAME_ICONS_STATUS.TRACKING:
      return <Tracking className=" w-3 h-3" />;
    case NAME_ICONS_STATUS.REJECTED:
      return <Reject className="w-3 h-3" />;
    case NAME_ICONS_STATUS.APPROVED:
      return <Approved className="w-3 h-3" />;

    default:
      return <Tracking className="w-3 h-3" />;
  }
};

export default IconsStatus;
