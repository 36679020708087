/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Dispatch, createContext, useContext, useReducer} from 'react';
import {AUTHCONST} from 'commons/auth/authConst';
import {authReducer} from 'contextApi/reducers/auth';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import {UserDetail} from 'models/user-detail';

interface DataModel {
  data: string;
}

interface UserDetails {
  data: UserDetail;
}
export interface SessionModel {
  token: DataModel;
  PaloPoints: DataModel;
  name: DataModel;
  logOut: () => void;
  role: DataModel;
  state: any;
  email: DataModel;
  userDetails?: UserDetails;
  dispatch: Dispatch<any>;
}

export const authContext = createContext<SessionModel>({
  token: {data: 'notData'},
  PaloPoints: {data: ''},
  name: {data: ''},
  logOut: () => {},
  role: {data: 'notRoute'},
  state: {},
  email: {data: ''},
  userDetails: {data: {}},
  dispatch: action => {},
});

interface Props {
  children: JSX.Element;
}

export const initialSate: SessionModel = {
  token: {data: 'notData'},
  PaloPoints: {data: ''},
  name: {data: ''},
  logOut: () => {},
  role: {data: 'notRoute'},
  state: {},
  email: {data: ''},
  userDetails: {data: {}},
  dispatch: action => {},
};

export function AuthProvider({children}: Props) {
  const tokenProvider = getLocalStorageUserState(AUTHCONST.TOKEN)
    ? getLocalStorageUserState(AUTHCONST.TOKEN)
    : {data: 'notData'};
  const roleProvider = getLocalStorageUserState(AUTHCONST.ROL)
    ? getLocalStorageUserState(AUTHCONST.ROL)
    : {data: 'notData'};
  const correoProvider = getLocalStorageUserState(AUTHCONST.EMAIL)
    ? getLocalStorageUserState(AUTHCONST.EMAIL)
    : {data: ''};
  const PaloPoints = getLocalStorageUserState(AUTHCONST.ACC_POINTS)
    ? getLocalStorageUserState(AUTHCONST.ACC_POINTS)
    : {data: ''};
  const Name = getLocalStorageUserState(AUTHCONST.NAME_USER)
    ? getLocalStorageUserState(AUTHCONST.NAME_USER)
    : {data: ''};
  const UserDetails = getLocalStorageUserState(AUTHCONST.USER_DETAILS)
    ? getLocalStorageUserState(AUTHCONST.USER_DETAILS)
    : {data: null};
  const currentState = {
    ...initialSate,
    token: tokenProvider,
    PaloPoints: PaloPoints,
    name: Name,
    role: roleProvider,
    email: correoProvider,
    userDetails: UserDetails,
  };
  const [state, dispatch] = useReducer(authReducer, currentState);

  const logOut = () => {
    dispatch({type: AUTHCONST.LOGOUT});
  };

  return (
    <authContext.Provider
      value={{
        token: tokenProvider,
        PaloPoints: PaloPoints,
        name: Name,
        logOut,
        role: roleProvider,
        state,
        email: correoProvider,
        userDetails: UserDetails,
        dispatch: dispatch,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(authContext);
  return context;
};
