import {Alert, Box, Grid, Typography} from '@mui/material';
import Panels from 'components/Panels/Panels';
import BlankCard from 'components/BlankCard/BlankCard';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import InfoIcon from '@mui/icons-material/Info';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './LeavesBalance.scss';
import withLeavesViewModel, {
  LeavesModel,
} from 'viewComponents/LeavesBalance/leavesBalanceViewModel';
type InfoHeaderProps = {
  onClick?: () => void;
};

const InfoHeader: FC<InfoHeaderProps> = ({onClick}) => {
  return (
    <div className="InfoHeader flex justify-end">
      <InfoOutlinedIcon className="cursor-pointer" onClick={onClick} />
    </div>
  );
};
interface DetailCardProps {
  title: string;
  description: string;
}
const DetailCard: FC<DetailCardProps> = ({title, description}) => {
  return (
    <BlankCard
      wrapperProps={{
        className: 'flex flex-col text-center justify-center items-center',
      }}
      className="h-full"
      title={<InfoHeader />}
    >
      <Typography variant="h4">{title}</Typography>
      <Typography variant="caption">{description}</Typography>
    </BlankCard>
  );
};

export interface LeavesBalanceProps {
  balance?: LeavesModel;
}

export const LeavesBalance: FC<LeavesBalanceProps> = ({balance}) => {
  const {t} = useTranslation();
  const {
    availableDays,
    vacationDays,
    additionalLeaveDays,
    daysToExpire,
    yearsOfService,
    accumulatedDays,
    usedDays,
  } = balance || {};

  return (
    <Box
      className="LeavesBalance"
      sx={{
        flex: 'flex',
        flexFlow: 'row',
        justifyContent: 'stretch',
        alignItems: 'center',
      }}
    >
      <BlankCard className="p-0" title={<InfoHeader />}>
        <Panels
          panelProps={{
            className: 'h-full',
          }}
          items={[
            <div className="flex flex-col justify-center items-center">
              <Typography variant="h2">{availableDays}</Typography>
              <Typography variant="caption">
                {t('leaves.available_days_label')}
              </Typography>
            </div>,
            <div className="flex h-full flex-col justify-between">
              <div className="flex items-center">
                <Typography variant="h5">{vacationDays}</Typography>
                <Typography variant="caption">
                  {t('leaves.vacation_days_label')}
                </Typography>
              </div>
              <div className="flex items-center">
                <Typography variant="h5">{additionalLeaveDays}</Typography>
                <Typography variant="caption">
                  {t('leaves.additional_days_label')}
                </Typography>
              </div>
            </div>,
          ]}
        />
        <hr className="my-2" />
        {(daysToExpire || []).map((message, index) => (
          <Alert
            key={index}
            severity="warning"
            icon={<InfoIcon />}
            className="mt-2"
            sx={{alignItems: 'center'}}
          >
            {message}
          </Alert>
        ))}
      </BlankCard>
      <Grid
        className="mt-4"
        container
        columns={13}
        sx={{justifyContent: 'space-between', alignItems: 'stretch'}}
      >
        <Grid item xs={4}>
          <DetailCard
            title={yearsOfService?.toString() || '0'}
            description={t('leaves.years_of_service_label')}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailCard
            title={accumulatedDays?.toString() || '0'}
            description={t('leaves.accumulated_days_label')}
          />
        </Grid>
        <Grid item xs={4}>
          <DetailCard
            title={usedDays?.toString() || '0'}
            description={t('leaves.used_days_label')}
          />
        </Grid>
      </Grid>
      <div className="help-text mt-6 px-4">
        <Typography variant="body1">{t('leaves.help_title')}</Typography>
        <Typography variant="body2">{t('leaves.help_description')}</Typography>
      </div>
    </Box>
  );
};

export default withLeavesViewModel()(LeavesBalance);
