import {UpperCaseFirstLetter} from './upperCaseFirstLetter';

/*format : 31 MARZO*/
export const buildDate = (date: string) => {
  const parse = parseInt(date);
  const newFormat = new Date(parse);

  const moth = new Intl.DateTimeFormat('es', {
    month: 'long',
  }).format(newFormat);

  const day = new Intl.DateTimeFormat('es', {
    day: 'numeric',
  }).format(newFormat);

  return `${day} ${moth.toUpperCase()}`;
};
/*format : Jueves, 31 de Marzo*/
export const buildDateLarge = (date: number) => {
  const newFormat = new Date(date);

  const anio = new Intl.DateTimeFormat('es-MX', {
    year: 'numeric',
  }).format(newFormat);

  const moth = new Intl.DateTimeFormat('es-MX', {
    month: 'long',
  }).format(newFormat);

  const day = new Intl.DateTimeFormat('es-MX', {
    day: 'numeric',
  }).format(newFormat);

  const dayName = new Intl.DateTimeFormat('es-MX', {
    weekday: 'long',
  }).format(newFormat);

  return `${UpperCaseFirstLetter(dayName)}, ${day} de ${UpperCaseFirstLetter(
    moth
  )} del ${anio}`;
};
/*format : 1 ENERO - 31 MARZO*/
export const formatDates = (dateini: string, datefin: string) => {
  const dayIni = buildDate(dateini);
  const dayFin = buildDate(datefin);
  return `${dayIni} - ${dayFin}`;
};
/*format : dd/mm/yyyy*/
export const buildDateFormat = (date: string) => {
  const parse = parseInt(date);
  const newFormat = new Date(parse);

  const moth = new Intl.DateTimeFormat('es-MX').format(newFormat);

  return `${moth.toUpperCase()}`;
};
/*returns difference of days between two dates */
export const expirationDays = (date: string) => {
  const dayLimit = parseInt(date);

  const dayNow = new Date().getTime();

  const diff = dayLimit - dayNow;

  const formatt = Math.round(diff / (1000 * 60 * 60 * 24));

  return formatt;
};

export const formatGetTime = (date: string) => {
  return new Date(date).getTime();
};
/*return function getTime() without hours */
export const formatGetTimeCeroDay = () => {
  const datef = new Date();
  datef.setHours(0, 0, 0, 0);
  return datef.getTime();
};
