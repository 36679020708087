import {createContext, Dispatch, FC, useReducer} from 'react';
import {
  AlertAction,
  alertReducer,
  AlertState,
} from 'contextApi/reducers/error.reducer';

export type AlertLevel = 'error' | 'info' | 'success' | 'warning';
export type AlertDuration = 'short' | 'long';

export const initialSate: AlertState = {
  config: null,
  showing: false,
};

export type AlertContextType = {
  state: AlertState;
  dispatch: Dispatch<AlertAction>;
};

export const alertContext = createContext<AlertContextType>({
  state: initialSate,
  dispatch: () => {
    return initialSate;
  },
});

const AlertProvider: FC = ({children}) => {
  const [state, dispatch] = useReducer(alertReducer, initialSate);
  return (
    <alertContext.Provider value={{state, dispatch}}>
      {children}
    </alertContext.Provider>
  );
};

export default AlertProvider;
