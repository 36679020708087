export const seeMore = (str: string, max: number) => {
  const size = str.length;
  if (size > max) {
    const newStr = str.split('').slice(0, max).join('');
    return `${newStr}... `;
  }
  return str;
};

export const maxSize = (str: string, max: number) => {
  const size = str.length;
  return size > max;
};
