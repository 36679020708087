import {FC, useEffect, useState} from 'react';
import {AssignationType} from 'models/assignationType';
import {EvidenceType, getEvidenceDescription} from 'models/evidence';
import {useTranslation} from 'react-i18next';
import {FormControllerControlProps} from 'controllers/FormController/FormController';
import {
  ADDITIONAL_LEAVE,
  DAY_OFF,
  HOLIDAY,
  INABILITY,
  LeaveRequestType,
} from 'models/leaveRequestType';

interface Rules {
  [key: string]: {
    [key: string]: EvidenceType[];
  };
}

interface LeaveRequestEvidenceControllerChildProps {
  evidenceFormControls: FormControllerControlProps[];
}

export interface LeaveRequestEvidenceControllerProps {
  assignationType: string;
  leaveType: LeaveRequestType | undefined;
  children: (props: LeaveRequestEvidenceControllerChildProps) => JSX.Element;
}

const LeaveRequestEvidenceController: FC<
  LeaveRequestEvidenceControllerProps
> = ({assignationType, leaveType, children}) => {
  const {t} = useTranslation();
  const rules: Rules = {
    [AssignationType.STAFFING]: {
      [HOLIDAY]: [EvidenceType.CUSTOMER_EVIDENCE],
      [INABILITY]: [EvidenceType.MEDICAL_PRESCRIPTION],
      [ADDITIONAL_LEAVE]: [EvidenceType.MANAGER_EVIDENCE],
      [DAY_OFF]: [EvidenceType.MANAGER_EVIDENCE],
    },
    [AssignationType.PROJECT]: {
      [HOLIDAY]: [EvidenceType.MANAGER_EVIDENCE],
      [INABILITY]: [EvidenceType.MEDICAL_PRESCRIPTION],
      [ADDITIONAL_LEAVE]: [EvidenceType.MANAGER_EVIDENCE],
      [DAY_OFF]: [EvidenceType.MANAGER_EVIDENCE],
    },
  };
  const [evidenceFormControls, setEvidenceFormControls] = useState<
    FormControllerControlProps[]
  >([]);
  const handleLeaveChange = () => {
    const controls: FormControllerControlProps[] = [];
    const assignationTypeRules = rules[assignationType] || {};
    const leaveTypeRules = assignationTypeRules[leaveType || ''] || [];
    leaveTypeRules.forEach((evidence: EvidenceType) =>
      controls.push({
        name: evidence,
        type: 'file',
        options: {
          required: true,
        },
        props: {
          label: t(getEvidenceDescription(evidence)),
          className: 'mt-2',
        },
      })
    );
    setEvidenceFormControls(controls);
  };
  useEffect(() => {
    handleLeaveChange();
  }, [assignationType, leaveType]);
  return children({evidenceFormControls});
};

export default LeaveRequestEvidenceController;
