/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import StickyHeadTable, {
  ColumnProps,
} from 'components/StickyHeadTable/StickyHeadTable';
import {useTranslation} from 'react-i18next';
import {LEAVE_STATUS_APPROVED} from 'models/leaveRequest';
import ModalController from 'controllers/ModalController';
import {IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteParamModal from 'viewComponents/DeleteParamModal/DeleteParamModal';
import UpdateVacationDayParamModal from 'viewComponents/UpdateVacationDayParamModal/UpdateVacationDayParamModal';
import './VacationDaysParamsTable.scss';
import TableController from 'controllers/TableController';
import {adminContext} from 'contextApi/context/admin';

const VacationDaysParamsTable: React.FC = () => {
  const {t} = useTranslation();
  const {
    state: {configuration, currentCountry},
  } = useContext(adminContext);

  const [vacationRules, setVacationRules] = useState<any>(undefined);

  useEffect(() => {
    if (configuration) {
      const countryConfig = configuration[currentCountry || ''];
      if (
        countryConfig.vacationRules &&
        countryConfig.vacationRules.vacationRanges
      ) {
        setVacationRules({data: countryConfig.vacationRules.vacationRanges});
      }
    }
  }, [currentCountry]);

  const renderEditController = (paramId: string, values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <EditIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <UpdateVacationDayParamModal
          defaultValue={values}
          paramId={paramId}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const renderDeleteController = (paramId: string, values: any) => (
    <ModalController
      trigger={({onToggleModal}) => (
        <IconButton onClick={onToggleModal}>
          <DeleteIcon />
        </IconButton>
      )}
      modal={({isModalOpen, onToggleModal}) => (
        <DeleteParamModal
          values={Object.entries({
            status: values.status,
          }).map(([label, value]) => ({
            label: t(`leaves.DeleteParamModal.${label}`),
            value,
          }))}
          paramId={paramId}
          status={LEAVE_STATUS_APPROVED}
          onClose={onToggleModal}
          open={isModalOpen}
        />
      )}
    />
  );

  const columns: ColumnProps[] = [
    {
      id: 'yearThreshold',
      label: t('leaves.VacationDaysParamsTable.years'),
      align: 'right',
    },
    {
      id: 'vacationDays',
      label: t('leaves.VacationDaysParamsTable.days'),
      align: 'right',
    },
    {
      id: 'id',
      label: t('leaves.VacationDaysParamsTable.actions'),
      align: 'right',
      render: (id: string, values: any) => (
        <>
          {renderEditController(id, values)}
          {renderDeleteController(id, values)}
        </>
      ),
    },
  ];

  return (
    <div className="VacationDaysParamsTable w-full flex flex-col flex-wrap pt-12">
      <TableController
        isLoading={false}
        // eslint-disable-next-line
        dataProvider={(page, pageSize) =>
          Promise.resolve({data: vacationRules, totalElements: 0})
        }
      >
        {({tableData, onPageChange}) => (
          <StickyHeadTable
            columns={columns}
            placeholder={t('common.empty_list')}
            hidePagination
            isLoading={false}
            rows={tableData?.data}
            onPageChange={onPageChange}
            totalElements={tableData?.totalElements}
          />
        )}
      </TableController>
    </div>
  );
};

export default VacationDaysParamsTable;
