import {FC, useContext, useEffect} from 'react';
import CountryConfiguration from './CountryConfiguration';
import {Alpha3Code, getName} from 'i18n-iso-countries';
import AdminProvider, {adminContext} from 'contextApi/context/admin';
import {AdminActionType} from 'contextApi/reducers/admin.reducer';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import TabView, {TabProps} from 'components/TabView/TabView';

const ParamsConfiguration: FC = () => {
  const {
    state: {configuration},
    dispatch,
    fetch,
  } = useContext(adminContext);

  useEffect(() => {
    fetch().then(response => {
      dispatch({type: AdminActionType.SetConfiguration, payload: response});

      dispatch({
        type: AdminActionType.SetCurrentCountry,
        payload: Object.keys(response)[0] as Alpha3Code,
      });
    });
  }, []);

  const tabs: TabProps[] = Object.keys(configuration).map(key => ({
    label: getName(key, navigator.language || 'es') || key,
    view: <CountryConfiguration country={key as Alpha3Code} />,
    onChange: () => {
      dispatch({
        type: AdminActionType.SetCurrentCountry,
        payload: key as Alpha3Code,
      });
    },
  }));

  return (
    <div className="Dashboard w-full flex flex-col flex-wrap pt-12">
      <div className="flex mb-6">
        <ViewTitle disableBackButton>Gestión de parámetros</ViewTitle>
      </div>
      <TabView tabs={tabs} />
    </div>
  );
};

const Wrapper = () => {
  return (
    <AdminProvider>
      <ParamsConfiguration />
    </AdminProvider>
  );
};

export default Wrapper;
