/* eslint-disable @typescript-eslint/no-explicit-any*/
import {FC, useContext} from 'react';
import {Button, InputAdornment, ModalProps} from '@mui/material';
import Modal from 'components/Modal/Modal';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {GET_CATALOGS, GET_USER_BY_EMAIL} from 'graphql/absences/Querys';
import GqlFormController from 'controllers/GqlFormController/GqlFormController';
import {CREATE_ADDITIONAL_LEAVE} from 'graphql/absences/Mutations';
import {Catalog} from 'models/catalog';
import {FormControllerControlProps} from 'controllers/FormController/FormController';
import {useLogger} from 'hooks/useLogger';
import {alertContext} from 'contextApi/context/error';
import {formatDate} from 'commons/dates';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';

interface AdditionalLeaveCreateModalProps extends Omit<ModalProps, 'children'> {
  children?: Children;
}

const AdditionalLeaveCreateModal: FC<AdditionalLeaveCreateModalProps> = ({
  onClose,
  ...props
}) => {
  const logger = useLogger(AdditionalLeaveCreateModal.name);
  const {dispatch} = useContext(alertContext);
  const {t} = useTranslation();

  return (
    <Modal
      title={t('leaves.AdditionalLeaveCreateModal.title')}
      description={t('leaves.AdditionalLeaveCreateModal.description')}
      {...props}
    >
      <GqlQueryController
        initialState={undefined}
        QUERY={GET_USER_BY_EMAIL}
        rootField={'user'}
      >
        {({data: userData, setQueryVars: setUserByEmailVariables}) => (
          <GqlQueryController
            initialState={[] as Catalog[]}
            QUERY={GET_CATALOGS}
            variables={{type: 'ADDITIONAL_LEAVE_TYPE'}}
            rootField={'catalogs'}
          >
            {({data: additionalLeaveTypes}) => {
              const handleOnSearch = (email: string) => {
                if (email) {
                  setUserByEmailVariables({email});
                }
              };

              const controls: FormControllerControlProps[] = [
                {
                  name: 'email',
                  type: 'search',
                  props: {
                    placeholder: t(
                      'leaves.AdditionalLeaveCreateModal.searchByEmail'
                    ),
                    size: 'full',
                    applyShadow: false,
                    onSearch: handleOnSearch,
                  },
                },

                {
                  name: 'palowanName',
                  type: 'label',
                  props: {
                    label: t('leaves.AdditionalLeaveCreateModal.palowanName'),
                    value:
                      userData?.firstName &&
                      `${userData?.firstName} ${userData?.lastName}`,
                  },
                },
                {
                  name: 'additionalLeaveType',
                  type: 'select',
                  props: {
                    label: t('leaves.AdditionalLeaveCreateModal.selectLabel'),
                    placeholder: t(
                      'leaves.AdditionalLeaveCreateModal.selectLabel'
                    ),
                    children: (additionalLeaveTypes || []).map(
                      (type: Catalog) => ({
                        key: type.name,
                        description: type.description,
                      })
                    ),
                  },
                },
                {
                  name: 'days',
                  props: {
                    label: t('leaves.AdditionalLeaveCreateModal.days'),
                    type: 'number',
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          {t('leaves.AdditionalLeaveCreateModal.daysSuffix')}
                        </InputAdornment>
                      ),
                    },
                    'data-testid': 'days-input',
                  },
                },
                {
                  name: 'date',
                  type: 'date',
                  props: {
                    label: t('leaves.AdditionalLeaveCreateModal.startDate'),
                    selectionType: 'single',
                  },
                },
                {
                  name: 'expirationDate',
                  type: 'date',
                  props: {
                    label: t(
                      'leaves.AdditionalLeaveCreateModal.expirationDate'
                    ),
                    selectionType: 'single',
                  },
                },
                {
                  name: 'comments',
                  props: {
                    placeholder: t(
                      'leaves.AdditionalLeaveCreateModal.commentsLabel'
                    ),
                    label: t('leaves.AdditionalLeaveCreateModal.commentsLabel'),
                  },
                },
              ];

              const handleOnSubmit = (mutationPromise: any, data: any) => {
                logger.debug('on submit', data);
                mutationPromise
                  .then(() => {
                    location.reload();
                  })
                  .catch(() => {
                    dispatch({
                      type: 'show',
                      payload: {
                        message: t(
                          'leaves.AdditionalLeaveCreateModal.errorMessage'
                        ),
                        level: 'error',
                        duration: 'short',
                        cancelable: true,
                      },
                    });
                    onClose && onClose({}, 'backdropClick');
                  });
              };

              const mapper = (values: any) => {
                const data = {
                  userId: userData.id,
                  type: values.additionalLeaveType,
                  initialDate: formatDate(values.date.startDate),
                  expirationDate: formatDate(values.expirationDate.startDate),
                  days: Number(values.days),
                  description: values.comments,
                };
                logger.debug('mapped data', data);

                return data;
              };

              return (
                <GqlFormController
                  className="flex flex-col"
                  itemProps={{xs: 12}}
                  mutation={CREATE_ADDITIONAL_LEAVE}
                  variablesMapper={mapper}
                  defaultValues={{
                    date: dayjs().toDate(),
                    expirationDate: dayjs().add(2, 'months').toDate(),
                    days: 1,
                  }}
                  controls={controls}
                  onSubmit={handleOnSubmit}
                >
                  {({onSubmit}: any) => (
                    <div>
                      <Button
                        onClick={() => onClose && onClose({}, 'backdropClick')}
                        variant="outlined"
                      >
                        {t('leaves.AdditionalLeaveCreateModal.exit')}
                      </Button>
                      <Button variant="contained" onClick={onSubmit()}>
                        {t('leaves.AdditionalLeaveCreateModal.submit')}
                      </Button>
                    </div>
                  )}
                </GqlFormController>
              );
            }}
          </GqlQueryController>
        )}
      </GqlQueryController>
    </Modal>
  );
};

export default AdditionalLeaveCreateModal;
