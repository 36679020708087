/* eslint-disable  @typescript-eslint/no-explicit-any */
import {useLazyQuery, useMutation} from '@apollo/client';
import {AUTHCONST} from 'commons/auth/authConst';
import {getLocalStorageUserState} from 'commons/localStorage.utilities';
import Button from 'components/button/Button';
import Loading from 'components/loader/Loader';
import {CREATE_COMMENT, UPDATE_COMMENT} from 'graphql/palowan/Mutation';
import {GET_SHOW_COMMENT} from 'graphql/palowan/Querys';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';

export interface formEditPalowanProps {
  idDinamycUser: string;
  onClick: () => void;
}
export const Comments: React.FC<formEditPalowanProps> = ({
  onClick,
  idDinamycUser,
}) => {
  const email = getLocalStorageUserState(AUTHCONST.EMAIL)
    ? getLocalStorageUserState(AUTHCONST.EMAIL)
    : {data: ''};
  const [execComment] = useMutation(CREATE_COMMENT, {
    refetchQueries: [
      {
        query: GET_SHOW_COMMENT,
        variables: {activityId: parseInt(idDinamycUser), email: email.data},
      },
    ],
  });
  const [exeShowComment, {data: dataComment}] = useLazyQuery(GET_SHOW_COMMENT);
  const [UpdateComment] = useMutation(UPDATE_COMMENT, {
    refetchQueries: [
      {
        query: GET_SHOW_COMMENT,
        variables: {activityId: parseInt(idDinamycUser), email: email.data},
      },
    ],
  });
  const [edit, setEdit] = useState<boolean>(false);
  const [showUpdate, setShowUpdate] = useState<string>('');
  const {register, handleSubmit, setValue} = useForm();

  const finishSubmit = () => {
    setEdit(false);
  };
  const finishComment = () => {
    exeShowComment();
    onClick();
  };
  useEffect(() => {
    exeShowComment({
      variables: {activityId: parseInt(idDinamycUser), email: email.data},
    }).then(() => {
      const idComment = dataComment?.data.ShowOneIdComment.comments.ID;
      setValue('dataComment', idComment?.comment);
    });
  }, []);
  const onclickSendComment = async (data: any) => {
    execComment({
      variables: {
        activityId: parseInt(idDinamycUser),
        comment: data.comment,
        email: email.data,
      },
    }).then(() => finishComment());
  };
  const onClickMock = async (data: any) => {
    const myrespuesta = data;
    const mycomentario = myrespuesta.ShowOneIdComment.comments.comment;
    setEdit(true);
    setShowUpdate(mycomentario);
  };
  const handleChange = (event: any) => {
    setShowUpdate(event.target.value);
  };
  const btnUpdateComment = (data: any) => {
    const miID = data.ShowOneIdComment.comments.ID;
    UpdateComment({
      variables: {commentId: parseFloat(miID), comment: showUpdate},
    }).then(() => finishSubmit());
  };
  return (
    <div className="w-full font-monseratt text-black font-medium text-base  mt-4 px-10">
      {dataComment?.ShowOneIdComment.comments.comment !== '' && !edit ? (
        <>
          <p>Tu comentario</p>
          <div className=" font-monseratt text-black font-light text-base mt-3 w-full h-40 ">
            <div className="overflow-y-scroll ">
              <p>{dataComment?.ShowOneIdComment.comments.comment}</p>
            </div>
            <div className=" my-4 w-24 h-9">
              <Button
                style="yellowSolidShawdow"
                onClickButton={() => onClickMock(dataComment)}
                text="EDITAR"
              />
            </div>
          </div>
        </>
      ) : dataComment?.ShowOneIdComment.comments.comment === '' && !edit ? (
        <div className="w-full font-monseratt text-black font-medium text-base">
          <label className="block mb-2 text-sm font-semibold font-monseratt text-gray-500 dark:text-white">
            Agrega un comentario
          </label>
          <textarea
            {...register('comment')}
            id="message"
            className="block p-2.5 w-full h-28 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Comentarios ..."
            maxLength={250}
          ></textarea>
          <div className="my-4 w-24 h-9">
            <Button
              style="primarySolidShawdow"
              onClickButton={handleSubmit(onclickSendComment)}
              text="ENVIAR"
            />
          </div>
        </div>
      ) : edit ? (
        <div className="w-full font-monseratt text-black font-light text-base mt-3 pl-1">
          <>
            <label className="block mb-2 text-sm font-semibold font-monseratt text-gray-500 dark:text-white">
              Editar comentario
            </label>
            <textarea
              {...register('dataCommentInput')}
              defaultValue={showUpdate}
              onChange={handleChange}
              className="block p-2.5 w-full h-28 text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Comentarios ..."
              maxLength={250}
            ></textarea>
            <div className=" my-3 w-24 h-9">
              <Button
                style="yellowSolidShawdow"
                onClickButton={() => btnUpdateComment(dataComment)}
                text="GUARDAR"
              />
            </div>
          </>
        </div>
      ) : (
        <Loading loaderStyles="" />
      )}
    </div>
  );
};
export default Comments;
