import {LeavesRoutes} from 'navigation/routes';
import {Route, Routes} from 'react-router-dom';
import ParamsConfiguration from 'views/leaves/ParamsConfiguration/ParamsConfiguration';
import AdditionalLeaves from 'views/leaves/AdditionalLeaves/AdditionalLeaves';
import Reports from 'views/leaves/Reports/Reports';
import {PendingApprovalsTable} from 'views/leaves/PendingApprovalsTable/PendingApprovalsTable';

const ManagementStack = () => {
  return (
    <Routes>
      <Route
        path={LeavesRoutes.ADDITIONAL_LEAVES}
        element={<AdditionalLeaves />}
      />
      <Route
        path={`${LeavesRoutes.PENDING_APPROVALS}/:id`}
        element={<PendingApprovalsTable />}
      />
      <Route
        path={LeavesRoutes.CONFIGURATION}
        element={<ParamsConfiguration />}
      />
      <Route path={LeavesRoutes.REPORTS} element={<Reports />} />
    </Routes>
  );
};

export default ManagementStack;
