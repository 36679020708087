interface Props {
  children: JSX.Element;
  onclick: () => void;
}

const ButtonIcon = ({children, onclick}: Props) => {
  return (
    <>
      <button
        onClick={onclick}
        className=" w-9 h-9 flex justify-center items-center rounded-md bg-tab-color-green"
      >
        {children}
      </button>
    </>
  );
};

export default ButtonIcon;
