import {FC} from 'react';
import {
  FormControlLabel,
  FormControlLabelProps,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from '@mui/material';
import './Checkbox.scss';

export interface CheckboxProps extends FormControlLabelProps {
  CheckboxProps?: MuiCheckboxProps;
}

const Checkbox: FC<CheckboxProps> = props => {
  return (
    <FormControlLabel
      {...props}
      data-testid="FormControl"
      control={<MuiCheckbox {...props.CheckboxProps} />}
    ></FormControlLabel>
  );
};

export default Checkbox;
