import {useLazyQuery} from '@apollo/client';
import {NAME_ICONS, NAME_ICONS_STATUS} from 'commons/icons/IconConst';
import ButtonImg from 'components/buttons/ButtonImg';
import {existImg} from 'components/card/CardDinamicasPalowan';
import Icons from 'components/icons/Icons';
import IconsStatus from 'components/icons/IconsStatus';
import {GET_DINAMYC, GET_LIST_COMMENTS} from 'graphql/admin/Querys';
import {ComentariosI, ParticipantsI} from 'models/comments';
import {useEffect, useState} from 'react';
import {ReactComponent as Return} from 'assets/IconSvgReturn.svg';
import photo from 'assets/Avatar_Palo.png';
import Loading from 'components/loader/Loader';

export interface formEditPalowanProps {
  idDinamyc: string;
  CloseModalClick: () => void;
  onclickModalImg: (info: ParticipantsI) => void;
}
export const ModalLateral: React.FC<formEditPalowanProps> = ({
  idDinamyc,
  CloseModalClick,
  onclickModalImg,
}) => {
  const [execListData, {data}] = useLazyQuery(GET_DINAMYC, {
    fetchPolicy: 'network-only',
  });
  const [exeListComment] = useLazyQuery(GET_LIST_COMMENTS);
  const [listComment, setListComment] = useState<Array<ComentariosI>>([
    {
      ID: '',
      palowanID: '',
      palowanName: '',
      palowanPicture: '',
      comment: '',
    },
  ]);

  useEffect(() => {
    if (idDinamyc) {
      execListData({
        variables: {id: parseInt(idDinamyc)},
      }).then(() => {
        exeListComment({
          variables: {activityId: parseInt(idDinamyc), limit: 100, page: 1},
        }).then(dataComment => {
          console.log('AQUI =========== ', dataComment);
          const mydata = dataComment.data?.FindShowListComments?.comments;
          if (mydata) {
            setListComment(mydata);
          }
        });
      });
    }
  }, []);
  const handleClickImg = (info: ParticipantsI) => {
    onclickModalImg(info);
  };
  const typeDinamic = (data: string): string => {
    switch (data) {
      case '1':
        return 'Reto';
      case '2':
        return 'Dinámica';

      default:
        return 'Dinámica';
    }
  };
  const statusParticipate = (approved: boolean, validated: boolean) => {
    if (approved && validated) return NAME_ICONS_STATUS.APPROVED;
    if (validated) return NAME_ICONS_STATUS.REJECTED;

    return NAME_ICONS_STATUS.TRACKING;
  };
  return (
    <div className="absolute z-50 w-full h-screen bg-black bg-opacity-30  flex justify-end">
      <div
        className=" w-full z-100 md:w-3/5 h-full bg-black bg-opacity-30"
        onClick={CloseModalClick}
      ></div>
      <div className=" w-full md:w-2/5 h-full py-6 px-10 bg-white">
        {data ? (
          <div className=" w-full h-full overflow-auto   pt-11">
            <div className="flex justify-start h-auto items-center">
              <div onClick={CloseModalClick} className=" cursor-pointer px-2">
                <Return />
              </div>
              <a className=" px-3 text-black font-monseratt  font-normal text-3xl">
                {data.FindDynamicById.activity.name}
              </a>
            </div>
            <div className="  w-full md:w-128 h-24 mt-5 flex px-10 flex-wrap ">
              <div className=" w-52 h-6  flex">
                <Icons nameIcon={NAME_ICONS.CALENDAR} />
                <div className=" w-20 ml-1 mr-3">
                  <p className="font-extralight m	 text-gray-500	font-monseratt text-xs mb-0.5">
                    Lanzamiento
                  </p>
                </div>
                <p className="font-extralight	 text-gray-500	font-monseratt text-xs mb-0.5">
                  {data.FindDynamicById.activity.initialDate}
                </p>
              </div>
              <div className=" w-52 h-6  flex">
                <Icons nameIcon={NAME_ICONS.PALOPOINT} />
                <div className=" w-20 ml-1 mr-3">
                  <p className="font-extralight m	 text-gray-500	font-monseratt text-xs mb-0.5">
                    PALOpoints
                  </p>
                </div>
                <p className="font-extralight	 text-gray-500	font-monseratt text-xs mb-0.5">
                  {data.FindDynamicById.activity.palopoints}
                </p>
              </div>
              <div className=" w-52 h-6  flex">
                <Icons nameIcon={NAME_ICONS.CALENDAR} />
                <div className=" w-20 ml-1 mr-3">
                  <p className="font-extralight m	 text-gray-500	font-monseratt text-xs mb-0.5">
                    Cierre
                  </p>
                </div>
                <p className="font-extralight	 text-gray-500	font-monseratt text-xs mb-0.5">
                  {data.FindDynamicById.activity?.finalDate}
                </p>
              </div>
              <div className=" w-52 h-6  flex">
                <Icons nameIcon={NAME_ICONS.CAMPING} />
                <div className=" w-20 ml-1 mr-3">
                  <p className="font-extralight m	 text-gray-500	font-monseratt text-xs mb-0.5">
                    Tipo
                  </p>
                </div>
                <p className="font-extralight	 text-gray-500	font-monseratt text-xs mb-0.5">
                  {typeDinamic(data.FindDynamicById.activity.typeActivity)}
                </p>
              </div>
            </div>
            <div className=" font-monseratt text-black font-medium text-base px-10">
              <p>Imagen</p>
            </div>
            <div className=" w-full md:w-120  mt-5 ml-7flex justify-center items-center bg-gray-100">
              <img
                className=" w-full"
                src={existImg(data.FindDynamicById.activity.urlImage)}
                alt="ImgDummy"
              />
            </div>
            <div className="  w-full font-monseratt text-black font-medium text-base  mt-4 px-10">
              <p>Descripción</p>
            </div>
            <div className=" w-full md:w-120 font-monseratt text-black font-light text-base mt-3 h-40 pl-10">
              <p>{data.FindDynamicById.activity.description} </p>
            </div>
            <div className=" w-full font-monseratt text-black font-medium text-base mt-4 px-10">
              <p>Participantes</p>
            </div>
            <div className=" ml-11 flex flex-col">
              {data.FindParticipanForDynamic.participants.map(
                (item: ParticipantsI) => (
                  <div
                    key={item.evidenceID}
                    className=" mt-3  flex items-center"
                  >
                    {/* {statusParticipate(false, true)} */}
                    <IconsStatus
                      status={statusParticipate(item.approved, item.validated)}
                    />
                    <div className=" w-72 h-8 flex mr-4 justify-center items-center">
                      <label>
                        <p className=" font-monseratt text-black text-xs font-normal">
                          {item.name}
                        </p>
                      </label>
                    </div>
                    <div>
                      {/* {!item.approved ? ( */}
                      <ButtonImg onclick={() => handleClickImg(item)}>
                        <Icons nameIcon={NAME_ICONS.IMGSEARCH} />
                      </ButtonImg>
                      {/* ) : (
                        <div></div>
                      )} */}
                    </div>
                  </div>
                )
              )}
            </div>
            <div className=" mt-8 font-monseratt text-black font-medium text-base px-10">
              <p>Comentarios</p>
            </div>
            <div className=" ml-11 flex flex-col">
              {listComment.map((item: ComentariosI) => (
                <div className=" mt-4" key={item.palowanID}>
                  <div className=" w-full h-8 flex justify-start">
                    {/* <Icons nameIcon={NAME_ICONS.PALOPOINT} /> */}
                    <img src={photo} alt="" />
                    <label>
                      <p className="px-3 font-monseratt text-black text-xs font-medium pt-2">
                        {item.palowanName}
                      </p>
                    </label>
                  </div>
                  <div className={'w-full md:w-90'}>
                    <label>
                      <p className="font-monseratt px-3 text-black text-xs font-normal pt-3">
                        {item.comment}
                      </p>
                    </label>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Loading loaderStyles="bg-gray-300" />
        )}
      </div>
    </div>
  );
};
export default ModalLateral;
