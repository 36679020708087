import {isEqual, format as dateFormat, parse} from 'date-fns';

export const areEquals = (date1: Date, date2: Date): boolean =>
  isEqual(date1, date2);

export const isInList = (date: Date, list: Date[]): boolean => {
  return list.some(item => areEquals(item, date));
};

export const isWeekendDay = (date: Date): boolean => {
  return date.getDay() === 0 || date.getDay() === 6;
};

export const DATE_FORMAT = 'yyyy-MM-dd';

export const formatDate = (
  date?: Date | undefined,
  format = 'yyyy-MM-dd'
): string => {
  if (date) {
    return dateFormat(date, format);
  } else {
    return '';
  }
};

export const parseDate = (
  stringDate: string,
  format: string = DATE_FORMAT
): Date => {
  if (!stringDate || stringDate === '') {
    return new Date();
  }
  return parse(stringDate, format, new Date());
};
