import {useLazyQuery} from '@apollo/client';
import Quarters from 'components/Quarters/Quarters';
import {GET_QUATER_LIST} from 'graphql/admin/Querys';
import {useEffect, ChangeEvent, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {buildDateLarge} from 'commons/formatDate';
import {useQuater} from 'contextApi/context/quaters';
import Loading from 'components/loader/Loader';
const HomeQuarters = () => {
  const navigate = useNavigate();
  const {numQuater, setNumQuater} = useQuater();
  const [year, setyear] = useState(new Date().getFullYear());
  const [execListPalowan, {data}] = useLazyQuery(GET_QUATER_LIST);
  const [anioFilter, setanioFilter] = useState<number[]>();
  const onclickIntoCards = (typeQue: string) => {
    const opcionYear = numQuater ? parseInt(numQuater) : year;
    navigate(`/private/palowan/palopoints/admin/${opcionYear}/${typeQue}`, {
      replace: true,
    });
  };
  const mockQuarter = {
    QuaterList: {
      quarters: [
        {
          id: '0',
          number: '1',
          initialDate: '1704067200000',
          finalDate: '1711929599999',
          activities: [],
        },
        {
          id: '0',
          number: '2',
          initialDate: '1711929600000',
          finalDate: '1719791999999',
          activities: [],
        },
        {
          id: '0',
          number: '3',
          initialDate: '1719792000000',
          finalDate: '1727740799999',
          activities: [],
        },
        {
          id: '0',
          number: '4',
          initialDate: '1727740800000',
          finalDate: '1735689599999',
          activities: [],
        },
      ],
    },
  };
  const hanldleAño = (e: ChangeEvent<HTMLSelectElement>) => {
    setNumQuater(e.target.value);
    const opcion = parseInt(e.target.value);
    setyear(opcion);
    execListPalowan({variables: {year: opcion}});
  };
  const filterDate = (date: number) => {
    const joinarray = [];
    let newDate = date + 1;
    const actualDate = date + 1;
    const minDate = newDate - 6;

    for (let i = 0; i < 7; i++) {
      if (newDate >= minDate) {
        newDate = actualDate - i;
        joinarray.push(newDate);
      }
    }

    return joinarray.reverse();
  };

  useEffect(() => {
    setanioFilter(filterDate(new Date().getFullYear()));
    const opcionYear = numQuater ? parseInt(numQuater) : year;
    execListPalowan({variables: {year: opcionYear}});
  }, []);
  return (
    <>
      <div className="w-full">
        <div className={'w-full md:flex '}>
          <div className=" w-full md:w-1/2 flex flex-col  justify-center items-end">
            <div className=" w-full md:w-128 ">
              <p
                className={
                  'ml-8 md:m-0 text-sm font-monseratt font-normal text-black'
                }
              >
                {buildDateLarge(new Date().getTime())}
              </p>
              <p
                className={
                  'ml-8 md:m-0 text-4xl  font-monseratt font-normal text-black md:date'
                }
              >
                Buenas tardes
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/2  flex  items-end md:date">
            <div className=" w-full md:w-128 flex justify-end  pr-8 md:pr-0">
              <p className="text-gray-700 pt-2 font-monseratt font-bold text-base mr-4">
                Mostrar año
              </p>
              {anioFilter && (
                <select
                  name="content"
                  className="block w-auto cursor-pointer py-2.5 px-0 text-lg text-black font-monseratt font-normal bg-transparent border-0 focus:outline-none focus:ring-0 focus:border-gray-200 "
                  defaultValue={
                    numQuater ? numQuater : new Date().getFullYear()
                  }
                  id="selContent"
                  data-testid="select-year"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                    hanldleAño(e)
                  }
                >
                  {anioFilter.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </div>
        </div>
        {data ? (
          <Quarters
            name="Usuario"
            year={year}
            data={year < 2022 ? mockQuarter : data}
            onclickInto={onclickIntoCards}
          />
        ) : (
          <div className="w-full items-center justify-center">
            <Loading loaderStyles="" />
          </div>
        )}
      </div>
    </>
  );
};

export default HomeQuarters;
