import {Suspense, useEffect} from 'react';
import withErrorBoundary from 'commons/withErrorBoundary';
import {compose} from 'commons/utils';
import Logger from 'commons/logger';
import 'commons/i18n';
import {AuthProvider} from 'contextApi/context/auth';
import Loading from 'components/loader/Loader';
import Navigation from 'navigation';
import {ThemeProvider} from '@mui/material';
import {theme} from './theme/theme.provider';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import AlertProvider from './contextApi/context/error';
import {registerLocale} from 'i18n-iso-countries';
import es from 'i18n-iso-countries/langs/es.json';
import './App.css';

const App = () => {
  useEffect(() => {
    registerLocale(es);
    Logger.init();
  }, []);

  return (
    <Suspense
      fallback={
        <>
          <Loading loaderStyles="" />
        </>
      }
    >
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AuthProvider>
            <AlertProvider>
              <div className="Navigation">
                <Navigation />
              </div>
            </AlertProvider>
          </AuthProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Suspense>
  );
};

export default compose(withErrorBoundary)(App);
