import {FC, useContext} from 'react';
import BlankCard from 'components/BlankCard/BlankCard';
import HolidaysImg from 'assets/holidays-icon.png';
import {Button, Typography} from '@mui/material';
import {authContext} from 'contextApi//context/auth';
import HOLIDAYS_MEX from 'assets/holidays-mex.png';
import HOLIDAYS_COL from 'assets/holidays-col.png';
import HOLIDAYS_BRA from 'assets/holidays-bra.png';
import ModalController from 'controllers/ModalController';
import ImagePreviewModal from 'viewComponents/ImagePreviewModal/ImagePreviewModal';
import {useTranslation} from 'react-i18next';
import './HolidaysCard.scss';

const HolidaysCard: FC = () => {
  const {t} = useTranslation();
  const {userDetails} = useContext(authContext);
  const countriesHolidays: {[key: string]: string} = {
    MEX: HOLIDAYS_MEX,
    COL: HOLIDAYS_COL,
    BRA: HOLIDAYS_BRA,
  };

  const country = userDetails?.data?.country || 'MEX';

  return (
    <>
      <BlankCard
        title={t('leaves.HolidaysCard.title')}
        action={
          <ModalController
            trigger={({onToggleModal}) => (
              <Button role="button" onClick={onToggleModal} variant="text">
                Ver días
              </Button>
            )}
            modal={({isModalOpen, onToggleModal}) => (
              <ImagePreviewModal
                title={t('leaves.HolidaysCard.title')}
                open={isModalOpen}
                onClose={onToggleModal}
                src={countriesHolidays[country]}
                alt={t('leaves.HolidaysCard.title')}
              />
            )}
          />
        }
      >
        <div className="flex flex-col justify-center items-center">
          <img src={HolidaysImg} className="mb-4 max-h-24" />
          <Typography variant="caption">
            {t('leaves.HolidaysCard.cardCaption')}
          </Typography>
        </div>
      </BlankCard>
    </>
  );
};

export default HolidaysCard;
