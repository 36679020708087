import {FC, useState} from 'react';

interface ModalProps {
  isModalOpen: boolean;
  onToggleModal: () => void;
}

export interface ModalControllerProps {
  trigger: (props: ModalProps) => JSX.Element;
  modal: (props: ModalProps) => JSX.Element;
}

const ModalController: FC<ModalControllerProps> = ({trigger, modal}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const childParams = {isModalOpen, onToggleModal};

  return (
    <>
      {trigger(childParams)}
      {modal(childParams)}
    </>
  );
};

export default ModalController;
