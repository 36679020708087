import {FC} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import MainStack from './MainStack';
import {PrivateRoutes} from './routes';
import PrivateStack from 'navigation/PrivateStack';

const Navigation: FC = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<MainStack />} />
      <Route path={`${PrivateRoutes.PRIVATE}/*`} element={<PrivateStack />} />
    </Routes>
  </BrowserRouter>
);

export default Navigation;
