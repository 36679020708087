import withConfiguration, {isConfigured} from 'services/configurationService';
import App from './App';
import React from 'react';
import {ApolloProvider} from '@apollo/client';
import {client} from 'commons/clients/apolloClient';

// eslint-disable-next-line
const ConfigMiddleware: React.FC<any> = ({apolloUrl, ...props}) => {
  return (
    <ApolloProvider client={client(apolloUrl)} {...props}>
      <App />
    </ApolloProvider>
  );
};

export default withConfiguration(isConfigured)(ConfigMiddleware);
