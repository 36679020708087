import {FC} from 'react';
import List, {ListItemProps} from 'components/List/List';
import './StatusList.scss';

interface StatusListProps {
  items: ListItemProps[];
}
const StatusList: FC<StatusListProps> = ({items}) => {
  return <List items={items} />;
};

export default StatusList;
