/* eslint-disable @typescript-eslint/no-explicit-any */

interface AppLogger {
  debug: (message?: string, ...args: any[]) => void;
  warn: (message?: string, ...args: any[]) => void;
  error: (message?: string, ...args: any[]) => void;
  info: (message?: string, ...args: any[]) => void;
}

export const defaultLogger: (className: string) => AppLogger = className => {
  return {
    debug: (message?: string, ...args: any[]) =>
      console.log(`DEBUG [${className}]: ${message}`, args),
    warn: (message?: string, ...args: any[]) =>
      console.log(`WARN [${className}]: ${message}`, args),
    error: (message?: string, ...args: any[]) =>
      console.log(`ERROR [${className}]: ${message}`, args),
    info: (message?: string, ...args: any[]) =>
      console.log(`INFO [${className}]: ${message}`, args),
  };
};

export const useLogger = (
  className: string,
  logger: AppLogger = defaultLogger(className)
) => {
  return logger;
};
