import {FC} from 'react';
import BlankCard, {BlankCardProps} from 'components/BlankCard/BlankCard';
import TabView, {TabViewProps} from 'components/TabView/TabView';

import './BlankCardWithTabs.scss';

interface BlankCardWithTabsProps extends BlankCardProps, TabViewProps {}

const BlankCardWithTabs: FC<BlankCardWithTabsProps> = ({tabs, ...other}) => {
  return (
    <BlankCard {...other}>
      <TabView tabs={tabs} />
    </BlankCard>
  );
};

export default BlankCardWithTabs;
