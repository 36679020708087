import {FC} from 'react';

const Container: FC = ({children}) => {
  return (
    <div className="Container w-full flex">
      <div className="relative w-full max-h-screen overflow-auto flex justify-center">
        <div className="w-full max-w-screen-xl pb-14 table">{children}</div>
      </div>
    </div>
  );
};

export default Container;
