import {FC} from 'react';
import BlankCard from 'components/BlankCard/BlankCard';
import {ReactComponent as RewardsIcon} from 'assets/rewards-icon.svg';
import {Button, Typography} from '@mui/material';
import './PaloPointsShortCutCard.scss';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

const PaloPointsShortCutCard: FC = () => {
  const {t} = useTranslation();
  return (
    <BlankCard
      title={t('leaves.PaloPointsShortCutCard.title')}
      action={
        <Link to="/private/palowan/palopoints">
          <Button variant="text">
            {t('leaves.PaloPointsShortCutCard.action')}
          </Button>
        </Link>
      }
    >
      <div className="flex flex-col justify-center items-center">
        <RewardsIcon className="mb-4 max-h-24" />
        <Typography variant="caption">
          {t('leaves.PaloPointsShortCutCard.cardCaption')}
        </Typography>
      </div>
    </BlankCard>
  );
};

export default PaloPointsShortCutCard;
