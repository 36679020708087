import {ReactComponent as Home} from 'assets/IconSvgHome.svg';
import {ReactComponent as HomeGreen} from 'assets/IconSvgHomeGreen.svg';
import {ReactComponent as ProfileGreen} from 'assets/IconSvgProfileGreen.svg';
import {ReactComponent as Profile} from 'assets/IconSvgProfile.svg';
import {ReactComponent as PaymentGreen} from 'assets/IconSvgPayGreen.svg';
import {ReactComponent as Payment} from 'assets/IconSvgPay.svg';
import {ReactComponent as HelpGreen} from 'assets/IconSvgHelpGreen.svg';
import {ReactComponent as Help} from 'assets/IconSvgHelp.svg';
import {ReactComponent as ChartGreen} from 'assets/IconSvgChartGreen.svg';
import {ReactComponent as Chart} from 'assets/IconSvgChart.svg';
import {ReactComponent as BookGreen} from 'assets/IconSvgBookGreen.svg';
import {ReactComponent as Book} from 'assets/IconSvgBook.svg';
import {ReactComponent as Logout} from 'assets/IconSvgLogout.svg';
import {ReactComponent as LogoutGreen} from 'assets/IconSvgLogoutGreen.svg';
import {ReactComponent as Ticket} from 'assets/IconSvgTicked.svg';
import {ReactComponent as TicketGreen} from 'assets/IconSvgTickedGreen.svg';
import {ReactComponent as PaloPoint} from 'assets/IconSvgPaloPoint.svg';
import {ReactComponent as PaloPointOrange} from 'assets/IconSVGPaloPointOrange.svg';
import {ReactComponent as ImgSearch} from 'assets/IconSvgImgSearch.svg';
import {ReactComponent as Heart} from 'assets/IconSvgHeart.svg';
import {ReactComponent as Camping} from 'assets/IconSvgCamping.svg';
import {ReactComponent as Calendar} from 'assets/IconSvgCalendar.svg';
import {ReactComponent as Canceled} from 'assets/IconSvgCanceled.svg';
import {ReactComponent as Check} from 'assets/IconSvgCheck.svg';
import {ReactComponent as Edit} from 'assets/IconSVGEdit.svg';
import {ReactComponent as PalowanIcon} from 'assets/IconSVGPalowan.svg';
import {ReactComponent as StatusPending} from 'assets/IconSVGStatusPending.svg';
import {ReactComponent as FilterIcon} from 'assets/FilterIconSvg.svg';
import {ReactComponent as WinnersGreen} from 'assets/IconWinGreen.svg';
import {ReactComponent as Winners} from 'assets/IconGrisWin.svg';
import {ReactComponent as Metric} from 'assets/IconSvgMetric.svg';
import {ReactComponent as MetricGreen} from 'assets/IconSvgMetricGreen.svg';
import {ReactComponent as Event} from 'assets/IconEvent.svg';
import {ReactComponent as Settings} from 'assets/IconSettings.svg';
import {ReactComponent as Envelope} from 'assets/IconSvgEnvelope.svg';
import {NAME_ICONS} from 'commons/icons/IconConst';

interface Props {
  nameIcon: string;
  active?: boolean;
  size?: number;
  color?: string;
}
const Icons = ({
  nameIcon,
  active = false,
  size = 5,
  color = '#353349',
}: Props) => {
  const iconSize = `w-${size} h-${size - 1}`;
  switch (nameIcon) {
    case NAME_ICONS.HOME:
      return active ? (
        <HomeGreen className={iconSize} />
      ) : (
        <Home className={iconSize} />
      );

    case NAME_ICONS.ADMIN:
      return active ? (
        <ProfileGreen className={iconSize} />
      ) : (
        <Profile className={iconSize} />
      );
    case NAME_ICONS.CHARTS:
      return active ? (
        <ChartGreen className={iconSize} />
      ) : (
        <Chart className={iconSize} />
      );
    case NAME_ICONS.BOOK:
      return active ? (
        <BookGreen className={iconSize} />
      ) : (
        <Book className={iconSize} />
      );
    case NAME_ICONS.PAY:
      return active ? (
        <PaymentGreen className={iconSize} />
      ) : (
        <Payment className={iconSize} />
      );
    case NAME_ICONS.HELP:
      return active ? (
        <HelpGreen className={iconSize} />
      ) : (
        <Help className={iconSize} />
      );
    case NAME_ICONS.LOGOUT:
      return active ? (
        <LogoutGreen className={iconSize} />
      ) : (
        <Logout className={iconSize} />
      );
    case NAME_ICONS.TICKET:
      return active ? (
        <TicketGreen className={iconSize} />
      ) : (
        <Ticket className={iconSize} />
      );
    case NAME_ICONS.PALOPOINT:
      return active ? (
        <PaloPointOrange className="w-7 h-7" />
      ) : (
        <PaloPoint className={iconSize} />
      );
    case NAME_ICONS.WINNERS:
      return active ? (
        <WinnersGreen className={iconSize} />
      ) : (
        <Winners className={iconSize} />
      );
    case NAME_ICONS.METRIC:
      return active ? (
        <MetricGreen className={iconSize} />
      ) : (
        <Metric className={iconSize} />
      );
    case NAME_ICONS.CANCELED:
      return <Canceled className={iconSize} />;
    case NAME_ICONS.CHECK:
      return <Check className={iconSize} />;
    case NAME_ICONS.EDIT:
      return <Edit className={iconSize} />;
    case NAME_ICONS.PALOWANICON:
      return <PalowanIcon className=" w-8 h-8" />;
    case NAME_ICONS.STATUSPENDING:
      return <StatusPending className=" w-6 h-6" />;
    case NAME_ICONS.FILTER:
      return <FilterIcon className=" w-3 h-2" />;
    case NAME_ICONS.CALENDAR:
      return <Calendar className={iconSize} />;
    case NAME_ICONS.CAMPING:
      return <Camping className={iconSize} />;
    case NAME_ICONS.HEART:
      return <Heart className={iconSize} />;
    case NAME_ICONS.IMGSEARCH:
      return <ImgSearch className={iconSize} />;
    case NAME_ICONS.EVENT:
      return <Event className={iconSize} style={{fill: color}} />;
    case NAME_ICONS.SETTINGS:
      return <Settings className={iconSize} style={{fill: color}} />;
    case NAME_ICONS.ENVELOPE:
      return <Envelope className={iconSize} style={{fill: color}} />;
    default:
      return <Home className={iconSize} />;
  }
};

export default Icons;
