import {FC, useContext} from 'react';
import Modal from 'components/Modal/Modal';
import {Button, ModalProps} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import {UPDATE_LEAVE_REQUEST_STATUS} from 'graphql/absences/Mutations';
import {alertContext} from 'contextApi/context/error';
import './LeaveUpdateStatusModal.scss';

interface LeaveUpdateStatusModalProps extends Omit<ModalProps, 'children'> {
  children?: Children;
  leaveRequestId: string;
  leaveRequestStatus: string;
  title: string;
  description: string;
}

const LeaveUpdateStatusModal: FC<LeaveUpdateStatusModalProps> = ({
  onClose,
  leaveRequestId,
  leaveRequestStatus,
  ...other
}) => {
  const {t} = useTranslation();
  const [mutation] = useMutation(UPDATE_LEAVE_REQUEST_STATUS);
  const {dispatch} = useContext(alertContext);

  const onSubmit = () => {
    const data = {
      leaveRequest: {
        id: leaveRequestId,
        status: leaveRequestStatus,
      },
    };
    mutation({variables: data})
      .then(data => {
        const updateLeaveRequestStatus = data?.data?.updateLeaveRequestStatus;
        if (updateLeaveRequestStatus.id) {
          onClose && onClose({}, 'backdropClick');
          dispatch({
            type: 'show',
            payload: {
              level: 'success',
              message: t('leaves.LeaveUpdateStatusModal.inProcess'),
            },
          });
          window.location.reload();
        }
      })
      .catch(error => {
        dispatch({
          type: 'show',
          payload: {level: 'error', message: error.message},
        });
      });
  };

  return (
    <Modal
      small
      className="LeaveUpdateStatusModal"
      onClose={onClose}
      {...other}
    >
      <div className="flex justify-end">
        <Button
          variant="outlined"
          onClick={event => onClose && onClose(event, 'backdropClick')}
        >
          {t('leaves.LeaveUpdateStatusModal.cancel')}
        </Button>
        <Button onClick={onSubmit} variant="contained">
          {t('leaves.LeaveUpdateStatusModal.submit')}
        </Button>
      </div>
    </Modal>
  );
};

export default LeaveUpdateStatusModal;
