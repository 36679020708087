import React, {ChangeEvent, useRef, useState} from 'react';
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from '@mui/material';
import thumbnail from 'assets/thumbnail.svg';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

type Extension = 'image/*' | 'application/pdf' | 'application/msword';
export interface UploadPickerProps {
  title: string;
  label: string;
  allowedExtensions?: Extension[];
  onSelectFile?: (file: File) => void;
  onDeleteFile?: () => void;
}

const UploadPicker: React.FC<UploadPickerProps> = ({
  title,
  label,
  onDeleteFile,
  onSelectFile,
  allowedExtensions = ['image/*'],
}) => {
  const [fileInfo, setFileInfo] = useState<{name: string; size: number} | null>(
    null
  );
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
  const [fileSize, setFileSize] = useState<string | null>(null);
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFileInfo({name: file.name, size: file.size});
      setFileSize(formatUnits(file.size, 0));
      onSelectFile && onSelectFile(file);
    }
  };
  const inputFileEl = useRef<HTMLInputElement | null>(null);
  const handleClick = () => {
    inputFileEl?.current?.click();
  };

  const dropFile = () => {
    if (inputFileEl.current) {
      inputFileEl.current.value = '';
    }
    setFileInfo(null);
    setFileSize(null);
    onDeleteFile && onDeleteFile();
  };

  const formatUnits = (size: number, unit: number): string => {
    if (size < 1024) {
      return `${size.toFixed(2)} ${units[unit]}`;
    } else {
      return formatUnits(size / 1024, unit + 1);
    }
  };

  return (
    <Stack className="UploadPicker" spacing={1}>
      <Typography sx={{marginLeft: '8px !important'}} variant="subtitle2">
        {title}
      </Typography>
      <Card sx={{padding: '16px'}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box
            onClick={handleClick}
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              flexGrow: 1,
              cursor: 'pointer',
            }}
          >
            <CardMedia
              sx={{maxWidth: 48, maxHeight: 48}}
              component="img"
              image={thumbnail}
              alt="Imagen"
            />
            <CardContent>
              <Stack>
                {fileInfo && (
                  <>
                    <Typography variant="subtitle1">{fileInfo.name}</Typography>
                    <Typography variant="body2">{fileSize}</Typography>
                  </>
                )}
                {fileInfo === null && (
                  <Typography color="neutralMedium" variant="subtitle1">
                    {label}
                  </Typography>
                )}
              </Stack>
            </CardContent>
          </Box>
          <CardContent>
            {(fileInfo && (
              <DeleteIcon
                data-testid="delete-btn"
                sx={{cursor: 'pointer'}}
                onClick={dropFile}
                color="primary"
              />
            )) || (
              <CloudUploadIcon
                sx={{cursor: 'pointer'}}
                onClick={handleClick}
                color="primary"
              />
            )}
          </CardContent>
        </Box>
        <input
          data-testid="file-input"
          ref={inputFileEl}
          style={{display: 'none'}}
          type="file"
          accept={allowedExtensions.join(',')}
          onChange={handleFileChange}
        />
      </Card>
    </Stack>
  );
};

export default UploadPicker;
