/* eslint-disable @typescript-eslint/no-explicit-any */
import Icon from 'assets/Iconchevron-left.svg';
import IconRight from 'assets/Iconchevron-right.svg';
import IconPendiente from 'assets/pendiente.svg';
import IconEntregado from 'assets/entregado.svg';
import IconNoEntregado from 'assets/no_entregado.svg';
import IconEntregadoNull from 'assets/entrega_null.svg';
import {useMutation} from '@apollo/client';
import {UPDATE_STATUS_AWARD} from 'graphql/palowan/Mutation';
import {GET_LISTWINNERS} from 'graphql/admin/Querys';

interface Props {
  handlePaginations: (page: string) => void;
  onclickProfile?: () => void;
  onclickDelete?: () => void;
  onclickAdd?: () => void;
  onclickSearchTbale?: (data: string) => void;
  onClick?: () => void;
  list: any[];
  page: {
    limit: string;
    next: boolean;
    page: string;
    pages: string;
    total: string;
  };
  selectedQuarterId: any;
  categoryId: any;
  pageNumber: any;
}

const TableRH = ({
  // onclickProfile,
  // onclickDelete,
  // onclickAdd,
  // onclickSearchTbale,
  list,
  page,
  handlePaginations,
  selectedQuarterId,
  categoryId,
  pageNumber,
}: Props) => {
  const onPrevClicked = () => {
    const nextPage = +page.page - 1;
    handlePaginations(nextPage.toString());
  };
  const onNextClicked = () => {
    const nextPage = +page.page + 1;
    handlePaginations(nextPage.toString());
  };

  const [UpdateStatusAward] = useMutation(UPDATE_STATUS_AWARD, {
    refetchQueries: [
      {
        query: GET_LISTWINNERS,
        variables: {
          quarterId: selectedQuarterId,
          categoryId: categoryId,
          limit: 10,
          page: pageNumber,
        },
      },
    ],
  });

  const onChangeSelect = (e: any, id: string) => {
    UpdateStatusAward({
      variables: {awardStatusId: parseFloat(id), status: e.target.value},
    });
  };

  return (
    <>
      <div className="w-10/12 md:w-full mx-auto">
        <div className="overflow-scroll relative shadow-md sm:rounded-lg max-w-sm md:max-w-screen-2xl">
          <table className="w-full text-sm text-left text-gray-500 ">
            <thead className="text-sm	 text-gray-700 font-monseratt font-medium	 bg-tablehd-bg-gray ">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Palowan
                </th>
                <th scope="col" className="py-3 px-6">
                  Dirección de entrega
                </th>
                <th scope="col" className="py-3 px-6">
                  No. de PALOpoints
                </th>
                <th scope="col" className="py-3 pl-6">
                  Estatus
                </th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 ? (
                list.map((listData, index) => {
                  return (
                    <tr
                      key={index}
                      className="bg-white border-b font-monseratt text-xs	"
                    >
                      <td className="py-3 px-6 font-black text-black">
                        {listData.palowanName}
                      </td>
                      <td className="py-3 px-6 font-black text-black">
                        {listData.address}
                      </td>
                      <td className="py-3 px-6 font-black text-black">
                        {listData.totalPalopoints}
                      </td>
                      <td className="py-3 px-6 font-black text-black">
                        <div className="flex items-center justify-start">
                          {listData.status !== '' ? (
                            <img
                              className="w-[16px] mr-1"
                              src={
                                listData.status === 'Pendiente'
                                  ? IconPendiente
                                  : listData.status === 'Entregado'
                                  ? IconEntregado
                                  : listData.status === 'No entregado'
                                  ? IconNoEntregado
                                  : IconEntregadoNull
                              }
                              alt="Icono de status"
                            />
                          ) : null}
                          {
                            <>
                              <select
                                onChange={e =>
                                  onChangeSelect(e, listData.awardStatusID)
                                }
                                defaultValue={listData.status}
                                test-id="sel-status"
                              >
                                <option disabled>Seleccionar</option>
                                <option>Pendiente</option>
                                <option>No entregado</option>
                                <option>Entregado</option>
                              </select>
                            </>
                          }
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td className="py-3 px-6">No hay palowans ganadores</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {page.pages !== '0' ? (
          <div className="flex items-start pt-5">
            <div className="mt-1 items-center mx-3">
              <span className="text-sm text-gray-700 dark:text-gray-400">
                <span className="font-semibold text-gray-900 dark:text-white">
                  {parseInt(page.total) <= 10
                    ? page.page
                    : 10 * (parseInt(page.page) - 1) + 1}
                  -
                </span>
                <span className="font-semibold text-gray-900 dark:text-white">
                  {parseInt(page.total) <= 10
                    ? page.total
                    : 10 * parseInt(page.page)}
                  &nbsp;
                </span>
                de &nbsp;
                <span className="font-semibold text-gray-900 dark:text-white">
                  {page.total}
                </span>
              </span>
            </div>
            <div className="inline-flex -mt-1 xs:mt-0">
              {parseInt(page.page) > 1 ? (
                <button onClick={() => onPrevClicked()} test-id="prev-button">
                  <img
                    src={IconRight}
                    alt="chevron-right"
                    className="items-center"
                  />
                </button>
              ) : null}
              {page.next ? (
                <button onClick={() => onNextClicked()} test-id="next-button">
                  <img
                    src={Icon}
                    alt="chevron-right"
                    className="items-center"
                  />
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default TableRH;
